<div class="navbar navbar-expand navbar-light d-flex justify-content-between">
    <navbar-app-feature></navbar-app-feature>

    <h3 class="m-0 d-flex align-items-center font-weight-semibold">App Distributions</h3>

    <navbar-user-menu *ngIf="workspace"></navbar-user-menu>
    <!-- Empty element just so that the title is centered using justify-content-between -->
    <span *ngIf="!workspace"></span>
</div>

<div class="page-content">

    <div class="content-wrapper" #pageContent>

        <!-- Content area -->
        <div class="content justify-content-center">
            <div class="row">
                <div class="col-md-12">
                    <alert></alert>
                </div>
            </div>

            <div class="row d-flex ml-auto mr-auto" style="max-width: 760px" *ngIf="applicationDistributions">
                <div class="col-md-12">
                    <div class="card mb-1">
                        <div class="page-header border-bottom-light">
                            <div class="page-header-content header-elements-md-inline">
                                <div class="page-title media d-flex align-items-center w-100">
                                    <div class="mr-3" style="width: 64px; height: 64px;">
                                        <app-icon [application]="applicationDistributions"></app-icon>
                                    </div>

                                    <div class="mr-auto">
                                        <h1 class="font-weight-light">
                                            {{applicationDistributions.name}}
                                            <span class="text-muted font-weight-thin">{{platformFormatter.platformName(applicationDistributions.platform)}}</span>
                                        </h1>
                                    </div>

                                    <button type="button" data-toggle="modal" data-target="#qr_modal" class="btn btn-outline bg-primary text-primary-800 btn-icon" *ngIf="!isOnMobile()"><i class="icon-qrcode"></i></button>
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="builds && builds.length > 0">
                            <div style="border-bottom: 1px solid #ddd;" *ngFor="let build of builds">
                                <div class="card-header header-elements-inline">
                                    <div class="card-title m-0">
                                        <h4 class="m-0"><a [routerLink]="['builds', build.serialNumber]" class="text-default">Version {{build.shortVersion}} ({{build.version}})</a></h4>
                                        <ul class="list-inline list-inline-dotted list-inline-condensed font-weight-light mb-0">
                                            <li class="list-inline-item">{{dateFormatter.dateTime(build.updateDate)}}</li>
                                            <li class="list-inline-item">{{build.bundleIdentifier}}</li>
                                        </ul>
                                    </div>

                                    <a [href]="buildInstallUrl(build)" class="btn btn-outline-primary rounded-round btn-sm" *ngIf="isOnMobile()">Install</a>
                                    <a href="javascript:void(0)" (click)="onHowToInstall()" class="btn btn-outline-secondary rounded-round btn-sm" *ngIf="!isOnMobile()">How to Install</a>
                                </div>

                                <!-- <div class="card-body" *ngIf="build.releaseNotes">
                                    <div class="font-size-xs text-uppercase text-muted mb-1">Release notes</div>
                                    <pre class="border-0 breakall m-0 p-0">{{build.releaseNotes}}</pre>
                                </div> -->
                            </div>
                        </ng-container>
                    </div>

                    <div class="card-body text-center text-muted" *ngIf="builds && builds.length == 0">
                        No builds available for testing yet.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="qr_modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-sm">
        <div class="modal-content" *ngIf="applicationDistributions">
            <div class="modal-header">
                <h5 class="modal-title">
                    {{applicationDistributions.name}}
                    <span class="text-muted font-weight-thin">{{platformFormatter.platformName(applicationDistributions.platform)}}</span>
                </h5>
                <button type="button" class="close" data-dismiss="modal">×</button>
            </div>

            <div class="modal-body">
                <p class="mb-3">
                    Scan the following QR code in order to open the download page on your phone:
                </p>
                <div class="text-center">
                    <qrcode [qrCodeData]="distributionUrl" [scale]="1.5"></qrcode>
                </div>
            </div>
        </div>
    </div>
</div>
