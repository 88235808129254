import { Component, OnInit, OnDestroy } from '@angular/core';
import { Application } from 'app/_models';
import { ApplicationService } from 'app/_services';
import { ApplicationFormatter } from 'app/_helpers';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
    templateUrl: 'application.crashes.component.html',
})
export class ApplicationCrashesComponent implements OnInit, OnDestroy {

    application: Application
    private currentApplicationSubscription: Subscription

    constructor(
        private applicationService: ApplicationService,
        private applicationFormatter: ApplicationFormatter,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
            if (this.application != null) {
                this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | Crashes`)
            }
        })
    }

    ngOnDestroy() {
        this.currentApplicationSubscription?.unsubscribe()
    }

}
