<div class="navbar desktop-only navbar-expand navbar-light d-flex justify-content-between">
    <navbar-app-feature appFeatureName="Home"></navbar-app-feature>

    <div class="d-flex justify-content-center align-items-center" *ngIf="workspace">
        <h3 class="m-0">
            <span class="font-weight-semibold">{{workspace.name}}</span> - Dashboard
        </h3>
    </div>

    <navbar-user-menu></navbar-user-menu>
</div>

<mobile-warning class="mobile-only"></mobile-warning>

<div class="page-content desktop-only">

    <div class="content-wrapper" id="pageContent">

        <div class="content">
            <div class="row">
                <div class="col-md-12">
                    <alert></alert>
                </div>
            </div>

            <ng-container *ngIf="userName == 'Miroslav Kovac' && false">
                <h3 class="font-weight-semibold">
                    Overview
                </h3>
                <div class="row">
                    <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                        <div class="card">
                            <a style="border: none; background-color: transparent;" [routerLink]="['crashes']" class="card-footer py-2 align-items-center justify-content-between d-flex text-default">
                                <div class="font-size-sm text-uppercase font-weight-semibold">Crashes</div>
                                <i class="text-grey-400 icon-chevron-right"></i>
                            </a>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                        <div class="card">
                            <a style="border: none; background-color: transparent;" [routerLink]="['errors']" class="card-footer py-2 align-items-center justify-content-between d-flex text-default">
                                <div class="font-size-sm text-uppercase font-weight-semibold">Errors</div>
                                <i class="text-grey-400 icon-chevron-right"></i>
                            </a>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                        <div class="card">
                            <a style="border: none; background-color: transparent;" [routerLink]="['network']" class="card-footer py-2 align-items-center justify-content-between d-flex text-default">
                                <div class="font-size-sm text-uppercase font-weight-semibold">Network</div>
                                <i class="text-grey-400 icon-chevron-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="card app-wizard w-100 h-100 m-0 bg-transparent" *ngIf="allApplications && allApplications.length == 0">
                <div class="card-body text-center d-flex justify-content-center align-items-center">
                    <div class="app-wizard-content">
                        <div class="phone-icon">📱</div>
                        <h5 class="mt-2 mb-0">Create your first application</h5>
                        <p class="mb-3 text-muted">Follow the link below and create your first application</p>

                        <a [routerLink]="['/settings/applications']" class="btn btn-labeled bg-primary-400 btn-labeled-left mt-2"><b><i class="icon-plus2"></i></b> New Application</a>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="allApplications && allApplications.length > 0">
                <div class="mb-3 pt-2 d-flex align-items-baseline">
                    <h3 class="mb-0 font-weight-semibold">
                        Applications
                    </h3>
                    <!-- <div class="text-muted ml-auto font-size-xs"><span class="badge badge-mark bg-success-400 border-success-400 mr-1"></span>Live status updates</div> -->

                    <div class="d-flex ml-auto align-items-baseline justify-content-between">
                        <!-- App name select -->
                        <form [formGroup]="searchForm">
                            <div class="form-group mb-0 form-group-feedback form-group-feedback-right">
                                <input style="width: 300px;" type="text" class="form-control" formControlName="searchQuery" placeholder="Search apps, envs, platforms">
                                <div class="form-control-feedback">
                                    <i class="icon-search4 font-size-base text-muted"></i>
                                </div>
                            </div>
                        </form>

                        <!-- View -->
                        <ul class="nav nav-pills nav-pills-bordered ml-2 mb-0">
                            <li class="nav-item ml-0">
                                <a href="javascript:void(0)" (click)="showListView()" class="nav-link px-2 py-1" [ngClass]="{ 'active' : showApplicationsInListView }" style="border-top-right-radius: 0; border-bottom-right-radius: 0;">
                                    <span><i class="icon-list mr-2"></i>List view</span>
                                </a>
                            </li>

                            <li class="nav-item ml-0">
                                <a href="javascript:void(0)" (click)="showGridView()" class="nav-link border-left-0 px-2 py-1" [ngClass]="{ 'active' : !showApplicationsInListView }" style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
                                    <span><i class="icon-grid mr-2"></i>Grid view</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="card w-100" *ngIf="showApplicationsInListView && filteredApplications.length > 0">
                    <table class="table">
                        <thead>
                            <tr role="row">
                                <th class="border-bottom-0 w-100">Name</th>
                                <th class="border-bottom-0 nowrap text-center">Platform</th>
                                <th class="border-bottom-0 nowrap text-center">Network appdex</th>
                                <th class="border-bottom-0 nowrap text-right">Tests appdex</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let application of filteredApplications" (click)="navigateToApplication(application)">
                                <td>
                                    <div class="d-flex align-items-center">
                                        <app-icon class="mr-2" [application]="application" [enableAppearanceAnimations]="true" style="width: 40px; height: 40px;;"></app-icon>
                                        <div>
                                            <a [routerLink]="['apps', application.slug]" class="font-weight-semibold button-muted">{{application.name}}</a>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <span class="text-muted">{{platformFormatter.platformName(application.platform)}}</span>
                                </td>
                                <td class="text-center">
                                    <a href="javascript:void(0)" (click)="navigateToApplicationNetwork(application)" class="button-muted d-block">
                                        <span class="badge badge-mark mr-1" [class]="networkAppdexBadgeClassForApplication(application)" *ngIf="application.latestNetworkAppdex != undefined"></span>
                                        <appdex class="font-weight-semibold" [value]="application.latestNetworkAppdex" [enableTextColoring]="false"></appdex>
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a href="javascript:void(0)" (click)="navigateToApplicationTests(application)" class="button-muted d-block">
                                        <span class="badge badge-mark mr-1" [class]="testsAppdexBadgeClassForApplication(application)" *ngIf="application.latestTestsAppdex != undefined"></span>
                                        <appdex class="font-weight-semibold" [value]="application.latestTestsAppdex" [enableTextColoring]="false"></appdex>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row" *ngIf="!showApplicationsInListView">
                    <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12" *ngFor="let application of filteredApplications">
                        <div class="card">
                            <a href="javascript:void(0)" (click)="navigateToApplication(application)" class="text-default">
                                <div class="card-header d-flex">
                                    <div class="d-flex align-items-center">
                                        <app-icon class="mr-2" [application]="application" [enableAppearanceAnimations]="true" style="width: 64px; height: 64px;;"></app-icon>
                                        <div class="ml-2">
                                            <div class="d-flex">
                                                <h3 class="mb-0">{{application.name}}</h3>
                                                <h3 class="ml-1 mb-0 text-muted font-weight-thin">{{platformFormatter.platformName(application.platform)}}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ml-auto text-left">
                                        <a href="javascript:void(0)" (click)="navigateToApplicationNetwork(application)" class="button-muted d-block">
                                            <span class="badge badge-mark mr-1" [class]="networkAppdexBadgeClassForApplication(application)"></span>
                                            <span>
                                                Network: <appdex class="font-weight-semibold" [value]="application.latestNetworkAppdex" [enableTextColoring]="false"></appdex>
                                            </span>
                                        </a>
                                        <a href="javascript:void(0)" (click)="navigateToApplicationTests(application)" class="button-muted d-block">
                                            <span class="badge badge-mark mr-1" [class]="testsAppdexBadgeClassForApplication(application)"></span>
                                            <span>
                                                Tests: <appdex class="font-weight-semibold" [value]="application.latestTestsAppdex" [enableTextColoring]="false"></appdex>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </a>

                            <div class="card-footer d-flex border-0 justify-content-between align-items-sm-center">
                                <ul class="list-inline mb-0 mt-2 ml-auto mt-sm-0">
                                    <li class="list-inline-item dropdown">
                                        <a [routerLink]="['apps', application.slug, 'settings']" class="text-default"><i class="icon-cog3 mr-2"></i>Settings</a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="mb-3 pt-2 d-flex align-items-baseline" *ngIf="filteredBackendEnvironments && filteredBackendEnvironments.length > 0">
                    <h3 class="mb-0 font-weight-semibold">
                        Environments
                    </h3>
                    <!-- <div class="text-muted ml-auto font-size-xs"><span class="badge badge-mark bg-success-400 border-success-400 mr-1"></span>Live status updates</div> -->
                </div>

                <div class="card w-100" *ngIf="showApplicationsInListView && filteredBackendEnvironments.length > 0">
                    <table class="table">
                        <thead>
                            <tr role="row">
                                <th class="border-bottom-0 w-100">Name</th>
                                <th class="border-bottom-0 nowrap text-center">Version</th>
                                <th class="border-bottom-0 nowrap text-center">Latest activity</th>
                                <th class="border-bottom-0 nowrap text-center">Network appdex</th>
                                <th class="border-bottom-0 nowrap text-right">Tests appdex</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let environment of filteredBackendEnvironments" (click)="navigateToBackendEnvironmentNetwork(environment)">
                                <td>
                                    <div>
                                        <a [routerLink]="['envs', environment.slug, 'network']" class="font-weight-semibold button-muted">{{environment.name}}</a>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <span class="text-muted ml-1" *ngIf="environment.latestVersion">{{environment.latestVersion.version}}</span>
                                </td>
                                <td class="text-center">
                                    <div class="text-muted" *ngIf="environment.latestActivityDate">{{dateFormatter.timeAgoShort(environment.latestActivityDate)}}</div>
                                    <div class="text-muted" *ngIf="environment.latestVersion == null">-</div>
                                </td>
                                <td class="text-center">
                                    <a href="javascript:void(0)" (click)="navigateToBackendEnvironmentNetwork(environment)" class="button-muted d-block">
                                        <span class="badge badge-mark mr-1" [class]="networkAppdexBadgeClassForEnvironment(environment)" *ngIf="environment.latestNetworkAppdex != undefined"></span>
                                        <appdex class="font-weight-semibold" [value]="environment.latestNetworkAppdex" [enableTextColoring]="false"></appdex>
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a href="javascript:void(0)" (click)="navigateToBackendEnvironmentTests(environment)" class="button-muted d-block">
                                        <span class="badge badge-mark mr-1" [class]="testsAppdexBadgeClassForEnvironment(environment)" *ngIf="environment.latestTestsAppdex != undefined"></span>
                                        <appdex class="font-weight-semibold" [value]="environment.latestTestsAppdex" [enableTextColoring]="false"></appdex>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row" *ngIf="!showApplicationsInListView">
                    <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12" *ngFor="let environment of filteredBackendEnvironments">
                        <div class="card">
                            <a href="javascript:void(0)" (click)="navigateToBackendEnvironmentNetwork(environment)" class="text-default">
                                <div class="card-header d-flex">
                                    <div>
                                        <h3 class="mb-0">{{environment.name}} <span class="text-muted font-weight-thin" *ngIf="environment.latestVersion">{{environment.latestVersion.version}}</span></h3>
                                        <div class="text-muted font-sm" *ngIf="environment.latestActivityDate">Latest activity: {{dateFormatter.timeAgoShort(environment.latestActivityDate)}}</div>
                                        <div class="text-muted text-sm" *ngIf="environment.latestVersion == null">No version information</div>
                                    </div>

                                    <div class="ml-auto text-left">
                                        <a href="javascript:void(0)" (click)="navigateToBackendEnvironmentNetwork(environment)" class="button-muted d-block">
                                            <span class="badge badge-mark mr-1" [class]="networkAppdexBadgeClassForEnvironment(environment)"></span>
                                            <span>
                                                Network: <appdex class="font-weight-semibold" [value]="environment.latestNetworkAppdex" [enableTextColoring]="false"></appdex>
                                            </span>
                                        </a>
                                        <a href="javascript:void(0)" (click)="navigateToBackendEnvironmentTests(environment)"class="button-muted d-block">
                                            <span class="badge badge-mark mr-1" [class]="testsAppdexBadgeClassForEnvironment(environment)"></span>
                                            <span>
                                                Tests: <appdex class="font-weight-semibold" [value]="environment.latestTestsAppdex" [enableTextColoring]="false"></appdex>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </a>

                            <div class="card-footer d-flex border-0 justify-content-between align-items-sm-center">
                                <div class="d-flex align-items-center">
                                </div>

                                <ul class="list-inline mb-0 mt-2 mt-sm-0">
                                    <li class="list-inline-item dropdown">
                                        <a [routerLink]="['envs', environment.slug, 'settings']" class="text-default"><i class="icon-cog3 mr-2"></i>Settings</a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>

            </ng-container>
        </div>
    </div>
</div>

<quick-jump-modal></quick-jump-modal>
