<div class="justify-content-center text-muted d-flex w-100 align-items-center" style="height: 90vh" *ngIf="!didFinishInitialLoading">
    <div class="align-self-center">
        <i class="icon-spinner4 spinner mr-2"></i> Loading...
    </div>
</div>

<div class="row" *ngIf="didFinishInitialLoading">
    <div class="col-md-12">
        <div class="card mb-1">
            <div class="page-header border-bottom-light">
                <div class="page-header-content header-elements-md-inline">
                    <div class="page-title">
                        <h1 class="font-weight-thin">
                            <a [routerLink]="['../../']" class="breadcrumb-item" *ngIf="application">{{application.name}}</a>
                            <a [routerLink]="['../']" class="breadcrumb-item">Crash Groups</a>
                            <span class="breadcrumb-item active">#{{crashGroup.serialNumber}}</span>
                        </h1>
                        <div class="mb-0" *ngIf="crashTrendsSummaryResponse">
                            <!-- <span class="badge badge-light badge-pill mr-1"><count [count]="crashTrendsSummaryResponse.totalCrashesCount"></count> reports</span>
                            <span class="badge badge-light badge-pill mr-1"><count [count]="crashTrendsSummaryResponse.totalDevicesCount"></count> users</span> -->
                            <!-- <span class="badge badge-light badge-pill mr-1">App versions: {{crashGroup.affectedVersions}}</span> -->
                            <span class="badge badge-light badge-pill mr-1">Latest report: {{dateFormatter.timeAgoShort(crashTrendsSummaryResponse.latestCrashDate)}}</span>
                            <span class="badge badge-light badge-pill mr-1" *ngIf="crashGroup.fixVersion">Marked as fixed in: <span class="font-weight-semibold">{{crashGroup.fixVersion}}</span></span>
                        </div>
                        <div class="mb-0" *ngIf="crashTrendsSummaryResponse == null">
                            <span class="badge badge-light">Loading...</span>
                        </div>
                    </div>

                    <div class="header-elements d-flex">
                        <div class="dropdown">
                            <button type="button" class="btn btn-outline bg-primary border-primary text-primary-800 btn-icon dropdown-toggle ml-2" data-toggle="dropdown">
                                {{issueStateFormatter.displayName(crashGroup.state)}}
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <a href="javascript:void(0)" (click)="updateIssueState(IssueState.Open)" class="dropdown-item" [ngClass]="{ 'active' : crashGroup.state == 1 }">{{issueStateFormatter.displayName(IssueState.Open)}}</a>
                                <a href="javascript:void(0)" (click)="updateIssueState(IssueState.Closed)" class="dropdown-item d-block" [ngClass]="{ 'active' : crashGroup.state == 0 }">
                                    <ng-container *ngIf="crashGroup.state == IssueState.Closed && crashGroup.fixVersion != null">Fixed in: {{crashGroup.fixVersion}}</ng-container>
                                    <ng-container *ngIf="crashGroup.state != IssueState.Closed || crashGroup.fixVersion == null">{{issueStateFormatter.displayName(IssueState.Closed)}}</ng-container>
                                </a>
                                <a href="javascript:void(0)" (click)="updateIssueState(IssueState.Ignored)" class="dropdown-item" [ngClass]="{ 'active' : crashGroup.state == 2 }">{{issueStateFormatter.displayName(IssueState.Ignored)}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- NOTES -->
            <div class="card-body">
                <h5 class="mb-0 pb-1">Notes</h5>

                <ng-container *ngIf="crashGroup.annotation">
                    <markdown [content]="crashGroup.annotation"></markdown>
                    <ul class="list-inline list-inline-dotted list-inline-condensed mt-1 mb-0">
                        <li class="list-inline-item"><a href="javascript:void(0)" (click)="onAnnotateCrashGroupClick()" class="font-weight-semibold">Edit annotation</a></li>
                        <li class="list-inline-item"><span class="text-muted font-size-sm font-weight-light">Updated {{dateFormatter.timeAgo(crashGroup.annotationUpdateDate)}}</span></li>
                    </ul>
                </ng-container>

                <ng-container *ngIf="crashGroup.annotation == null">
                    <a href="javascript:void(0)" (click)="onAnnotateCrashGroupClick()" class="font-weight-semibold">Annotate this crash group</a>
                    <span class="text-muted"> with contextual information and links to an issue tracking system like JIRA or Trello.</span>
                </ng-container>
            </div>

            <!-- EXCEPTION -->
            <div class="card-body">
                <h5 class="mb-0 pb-1 mr-2">Exception</h5>
                <div class="align-items-baseline">
                    <span class="font-weight-semibold">{{crashGroup.exceptionSymbol}}</span>
                    <span class="text-muted ml-1">{{crashGroup.sourceCodeLocation}}</span>
                </div>
                <div class="text-muted">
                    <span *ngIf="crashGroup.exceptionName">{{crashGroup.exceptionName}}</span>
                    <span *ngIf="crashGroup.exceptionName && crashGroup.exceptionMessage">:</span>
                    {{crashGroup.exceptionMessage}}
                </div>

                <a href="javascript:void(0)" (click)="onShowStackTraceClick()" class="font-weight-semibold" *ngIf="!stackTraceVisible">Show latest stack trace</a>
                <call-stack [callStack]="callStack" [crashedSymbol]="crashGroup.exceptionSymbol" *ngIf="callStack && stackTraceVisible"></call-stack>
                <a href="javascript:void(0)" (click)="onShowStackTraceClick()" class="font-weight-semibold" *ngIf="stackTraceVisible">Hide stack trace</a>
            </div>

            <!-- FILTERS -->
            <div class="card-body d-flex align-items-center align-items-baseline">
                <div class="d-flex align-items-baseline">
                    <h5 class="mb-0 pb-0 mr-2">Filters</h5>

                    <div class="form-group mb-0 d-flex mr-2" *ngIf="crashFiltersResponse.applications.length > 1">
                        <select id="applicationSelect" select2 data-placeholder="App" data-allow-clear="true" class="form-control select2">
                            <option *ngFor="let application of crashFiltersResponse.applications">{{application.name}} {{platformFormatter.platformName(application.platform)}}</option>
                        </select>
                    </div>

                    <div class="form-group mb-0 d-flex mr-2" *ngIf="crashFiltersResponse.bundleIdentifiers.length > 0">
                        <select id="bundleIdentifierSelect" select2 data-placeholder="App identifier" data-allow-clear="true" class="form-control select2">
                            <option *ngFor="let bundleIdentifier of crashFiltersResponse.bundleIdentifiers">{{bundleIdentifier.value}}</option>
                        </select>
                    </div>

                    <div class="form-group mb-0 d-flex mr-2" *ngIf="crashFiltersResponse.applicationVersions.length > 0">
                        <select id="applicationVersionSelect" select2 data-placeholder="App version" data-allow-clear="true" class="form-control select2">
                            <option *ngFor="let applicationVersion of crashFiltersResponse.applicationVersions">{{applicationVersion.bundleShortVersion}}</option>
                        </select>
                    </div>

                    <div class="form-group mb-0 d-flex mr-2" *ngIf="crashFiltersResponse.osVersions.length > 0">
                        <select id="osVersionSelect" select2 data-placeholder="OS version" data-allow-clear="true" class="form-control select2">
                            <option *ngFor="let osVersion of crashFiltersResponse.osVersions">{{osVersion.version}}</option>
                        </select>
                    </div>
                </div>

                <ul class="ml-auto pagination">
                    <li class="page-item active">
                        <date-range [dateRange]="this.selectedDateRange" (onDateRangeChange)="onDateRangeChanged($event)"></date-range>
                    </li>
                </ul>
            </div>

            <!-- TRENDS -->
            <div class="card-body py-0 my-0">
                <!-- <h5 class="mb-0 pb-0">Trends</h5> -->

                <!-- TRENDS LOADING -->
                <div class="justify-content-center text-muted d-flex w-100 align-items-center" style="min-height: 280px" *ngIf="!crashTrendsResponse || !crashTrendsSummaryResponse">
                    <div class="align-self-center">
                        <i class="icon-spinner4 spinner mr-2"></i> Loading trends...
                    </div>
                </div>

                <!-- TRENDS LOADED -->
                <div class="d-flex" *ngIf="crashTrendsResponse && crashTrendsSummaryResponse">

                    <!-- TRENDS -->
                    <div style="width: calc(100vw - 290px); margin-top: 20px; margin-bottom: 20px;">
                        <crashes-over-time-chart [datePoints]="crashTrendsResponse.datePoints" [crashCounts]="crashTrendsResponse.crashCounts"></crashes-over-time-chart>
                    </div>

                    <!-- SUMMARY -->
                    <div class="border-left-light pl-3" style="padding-top: 20px;">
                        <h5 class="mb-0 pb-3">Summary</h5>

                        <div style="width: 180px;">
                            <crash-trends-summary [crashTrendsSummaryResponse]="crashTrendsSummaryResponse"></crash-trends-summary>
                        </div>
                    </div>
                </div>
            </div>

            <!-- DIMENSIONS -->
            <div class="card-body d-flex align-items-baseline" *ngIf="crashFiltersResponse.applications.length > 1">
                <h5 class="mb-0 pb-0">Dimensions</h5>
                <ul class="nav nav-pills nav-pills-bordered ml-2 my-0">
                    <li class="nav-item ml-1">
                        <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : crashGroupDimension == CrashGroupDimension.CrashReports }" (click)="setCrashGroupDimension(CrashGroupDimension.CrashReports)">Crash reports</a>
                    </li>

                    <li class="nav-item ml-1" *ngIf="crashFiltersResponse.applications.length > 1">
                        <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : crashGroupDimension == CrashGroupDimension.Applications }" (click)="setCrashGroupDimension(CrashGroupDimension.Applications)">Applications</a>
                    </li>
                </ul>
            </div>

            <!-- SEGMENTS -->

            <!-- CRASH REPORTS LIST -->
                <ng-container *ngIf="crashGroupDimension == CrashGroupDimension.CrashReports">
                <table class="table" *ngIf="crashReports && crashReports.length > 0">
                    <thead>
                        <tr role="row">
                            <th class="border-bottom-0 nowrap w-100">Crash reports</th>
                            <th class="border-bottom-0 nowrap text-center">App version</th>
                            <th class="border-bottom-0 nowrap text-center">OS</th>
                            <th class="border-bottom-0 nowrap text-center">Device</th>
                            <th class="border-bottom-0 nowrap text-center">Locale</th>
                            <th class="border-bottom-0 nowrap text-right">Attachment</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let crashReport of crashReports" (click)="navigateToCrashReport(crashReport)">
                            <td class="d-flex">
                                <div class="mr-2">
                                    <span class="badge badge-mark border-pink-400"></span>
                                </div>
                                <div>
                                    <a class="font-weight-semibold button-muted nowrap" [routerLink]="['crash-reports/', crashReport.serialNumber]">
                                        #{{crashReport.serialNumber}}
                                    </a>
                                    <div class="text-muted breakall">{{dateFormatter.dateTimeShort(crashReport.crashDate)}}</div>
                                </div>
                            </td>
                            <td class="nowrap text-center">
                                <span class="badge badge-light badge-pill">{{crashReport.appVersion}}</span>
                            </td>
                            <td class="nowrap text-center">{{crashReport.system}}</td>
                            <td class="nowrap text-center">{{crashReport.deviceModelName}}</td>
                            <td class="nowrap text-center">{{crashReport.locale}}</td>
                            <td class="text-right" [ngClass]="{ 'text-muted' : crashReport.attachment == null }">
                                <i class="icon-attachment" *ngIf="crashReport.attachment != null"></i>
                                <span class="text-muted" *ngIf="crashReport.attachment == null">-</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>

            <!-- APPLICATION SEGMENTS -->
            <ng-container *ngIf="crashGroupDimension == CrashGroupDimension.Applications">
                <table class="table" *ngIf="crashApplicationSegments && crashApplicationSegments.length > 0">
                    <thead>
                        <tr role="row">
                            <th class="border-bottom-0 w-100">Application</th>
                            <th class="border-bottom-0 nowrap text-right">
                                <sortable [name]="'Crashes'" [ascValue]="CrashSegmentsOrder.COUNT_ASC" [descValue]="CrashSegmentsOrder.COUNT_DESC" [default]="CrashSegmentsOrder.COUNT_DESC"></sortable>
                            </th>
                            <th class="border-bottom-0 nowrap text-right">
                                <sortable [name]="'Users'" [ascValue]="CrashSegmentsOrder.AFFECTED_USERS_ASC" [descValue]="CrashSegmentsOrder.AFFECTED_USERS_DESC"></sortable>
                            </th>
                            <th class="border-bottom-0 nowrap text-center">App versions</th>
                            <th class="border-bottom-0 nowrap text-right">First seen</th>
                            <th class="border-bottom-0 nowrap text-right">Last seen</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let crashApplicationSegment of crashApplicationSegments">
                            <td class="hover-expendable">
                                <div class="d-flex button-muted align-items-center">
                                    <app-icon class="mr-2" [application]="crashApplicationSegment.application" [enableAppearanceAnimations]="false" style="width: 40px; height: 40px;;"></app-icon>
                                    <div>{{crashApplicationSegment.application.name}} <span class="text-muted">{{platformFormatter.platformName(crashApplicationSegment.application.platform)}}</span></div>
                                    <a [routerLink]="['./']" [queryParams]="{app: crashApplicationSegment.application.id}" queryParamsHandling="merge" class="hover-target badge badge-flat badge-pill border-primary text-primary ml-2">
                                        <i class="icon-filter3 mr-1" style="font-size: 90%;"></i>Add to filter
                                    </a>
                                </div>
                            </td>
                            <td class="text-right">
                                <count [count]="crashApplicationSegment.crashesCount"></count>
                            </td>
                            <td class="text-right">
                                <count [count]="crashApplicationSegment.distinctDeviceIds"></count>
                            </td>
                            <td class="text-center" *ngIf="crashApplicationSegment.earliestCrashVersion == crashApplicationSegment.latestCrashVersion">
                                <span class="badge badge-light badge-pill">{{crashApplicationSegment.earliestCrashVersion}}</span>
                            </td>
                            <td class="text-center nowrap" *ngIf="crashApplicationSegment.earliestCrashVersion != crashApplicationSegment.latestCrashVersion">
                                <span class="badge badge-light badge-pill">{{crashApplicationSegment.earliestCrashVersion}}</span>…<span class="badge badge-light badge-pill">{{crashApplicationSegment.latestCrashVersion}}</span>
                            </td>
                            <td class="nowrap text-right">{{dateFormatter.timeAgoShort(crashApplicationSegment.earliestCrashDate)}}</td>
                            <td class="nowrap text-right">{{dateFormatter.timeAgoShort(crashApplicationSegment.latestCrashDate)}}</td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>

        </div>
    </div>
</div>

<edit-crash-group-modal #editCrashGroupModal [crashGroup]="this.crashGroup" (crashGroupUpdated)="onCrashGroupUpdated()"></edit-crash-group-modal>
