import { Component, ViewChild, ElementRef, OnInit, OnDestroy, HostListener } from '@angular/core';
import { AlertService, ApplicationService, AuthenticationService, WidgetsService } from 'app/_services';
import { Application, Platform, Workspace, Widget, WidgetConfigurationType, BackendResponse, BugReportsWidgetConfiguration, TestExecutionsWidgetConfiguration } from 'app/_models'
import { AppConfig } from 'app/app.config'
import { PlatformFormatter, DateFormatter, ApplicationFormatter } from 'app/_helpers'
import * as bootbox from 'bootbox'
import { ActivatedRoute, Router } from '@angular/router';
import { NewWidgetModal } from '../new.widget.modal';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { DropzoneComponent } from 'app/_components/dropzone.component';

@Component({
    templateUrl: 'application.dashboard.component.html'
})
export class ApplicationDashboardComponent implements OnInit, OnDestroy {

    AppConfig = AppConfig

    workspace: Workspace
    application: Application
    widgets: Widget[]

    uploadUrl: string
    uploadParamName: string
    applicationAlias: string

    private currentApplicationSubscription: Subscription

    @ViewChild('closeButton') closeButton: ElementRef
    @ViewChild('newWidgetButton') newWidgetButton: ElementRef
    @ViewChild('newWidgetModal') widgetModal: NewWidgetModal
    @ViewChild('dropzone') dropzone: DropzoneComponent

    constructor(
        public platformFormatter: PlatformFormatter,
        public dateFormatter: DateFormatter,
        private alertService: AlertService,
        private widgetsService: WidgetsService,
        private authenticationService: AuthenticationService,
        private applicationService: ApplicationService,
        private router: Router,
        private route: ActivatedRoute,
        private applicationFormatter: ApplicationFormatter,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue
        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
            if (this.application != null) {
                this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | Dashboard`)
                this.reloadDashboard()
            }
        })
    }

    ngOnDestroy() {
        this.currentApplicationSubscription?.unsubscribe()
    }

    private reloadDashboard() {
        this.uploadUrl = `${AppConfig.apiBaseUrl}/builds/`
        this.applicationAlias = this.application.alias
        switch (this.application.platform) {
            case Platform.Ios: this.uploadParamName = "ipa"; break
            case Platform.Android: this.uploadParamName = "apk"; break
            case Platform.Tvos: this.uploadParamName = "app"; break
        }

        this.widgetsService.getApplicationWidgets(this.application.id).then((response) => {
            this.widgets = response.data

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    onWidgetCreated() {
        this.reloadWidgets()
    }

    onWidgetUpdated() {
        this.reloadWidgets()
    }

    onWidgetClick(widget: Widget) {
        let pathComponents = new Array<any>()
        let queryParams = {}

        switch (widget.configurationType) {
            case WidgetConfigurationType.BugReports:
                pathComponents = ['./bugs']

                let bugsConfig = widget.configuration as BugReportsWidgetConfiguration
                if (bugsConfig.reporterEmail != null) { queryParams['reporterEmail'] = bugsConfig.reporterEmail }
                break

            case WidgetConfigurationType.TestExecutions:
                pathComponents = ['./test-executions']

                let testsConfig = widget.configuration as TestExecutionsWidgetConfiguration
                if (testsConfig.environmentName) { queryParams['environment'] = testsConfig.environmentName }
                if (testsConfig.testPlanName) { queryParams['testPlan'] = testsConfig.testPlanName }
                break

            case WidgetConfigurationType.Builds:
                pathComponents = ['./builds']
                break
        }

        this.router.navigate(pathComponents, { queryParams: queryParams, relativeTo: this.route })
    }

    onWidgetEdit(widget: Widget) {
        this.widgetModal.widget = widget
        this.newWidgetButton.nativeElement.click()
    }

    onWidgetDelete(widget: Widget) {
        let weakThis = this

        bootbox.dialog({
            title: `Delete ${widget.name}?`,
            message: widget.isShared == true ? "Note that this widget is shared - the deletion will affect other users as well." : "Widget will be permanently delete from your personal dashboard.",
            size: 'small',
            buttons: {
                cancel: { label: 'Cancel', className: 'btn-link' },
                delete: {
                    label: 'Delete Widget', className: 'btn-danger', callback: function () {
                        weakThis.deleteWidget(widget)
                    }
                }
            }
        })
    }

    onFileAdded() {
        this.dropzone.startUpload(null)
    }

    onUploadFailed(errorMessage: string) {
        this.alertService.error(errorMessage)
        this.closeButton.nativeElement.click()
    }

    onUploadSucceeded() {
        this.alertService.success("Build successfully uploaded")
        this.closeButton.nativeElement.click()
        this.reloadDashboard()
    }

    private reloadWidgets() {
        this.widgetsService.getApplicationWidgets(this.application.id).then((response) => {
            this.widgets = response.data

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private deleteWidget(widget: Widget) {
        this.widgetsService.deleteWidget(widget).then((response) => {
            // Remove the deleted widget from the list
            this.widgets = this.widgets.filter(widgetCandidate => widgetCandidate !== widget);

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
