import { Injectable } from "@angular/core";
import { IssueState } from "app/_models";

@Injectable()
export class IssueStateFormatter {

    displayName(state: IssueState): string {
        switch (state) {
            case IssueState.Closed: return "Closed"
            case IssueState.Open: return "Open"
            case IssueState.Ignored: return "Ignored"
            default:
                console.warn("Unknown IssueState: " + state);
                return "Unknown"
        }
    }

    issueStateFromDisplayName(displayName: string): IssueState | null {
        if (displayName === null || displayName === undefined) {
            return null
        }

        if (displayName == this.displayName(IssueState.Open)) { return IssueState.Open }
        else if (displayName == this.displayName(IssueState.Ignored)) { return IssueState.Ignored }
        else if (displayName == this.displayName(IssueState.Closed)) { return IssueState.Closed }
        else {
            console.warn("Unknown IssueState: " + displayName);
            return null
        }
    }

}
