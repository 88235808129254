<div class="navbar navbar-expand navbar-light d-flex justify-content-between">
    <navbar-app-feature></navbar-app-feature>

    <h3 class="m-0 d-flex align-items-center font-weight-semibold">App Distributions</h3>

    <navbar-user-menu *ngIf="workspace"></navbar-user-menu>
    <!-- Empty element just so that the title is centered using justify-content-between -->
    <span *ngIf="!workspace"></span>
</div>

<div class="page-content">

    <div class="content-wrapper">

        <div class="content">
            <div class="row">
                <div class="col-md-12">
                    <alert></alert>
                </div>
            </div>

            <div class="d-flex row flex-wrap" *ngIf="applicationDistributions && applicationDistributions.length > 0">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12" *ngFor="let application of applicationDistributions">
                    <div class="card" style="overflow: hidden;">
                        <a [routerLink]="[application.slug]" class="text-default">
                            <div class="card-header d-flex align-items-center">
                                <app-icon [application]="application" style="width: 64px; height: 64px;"></app-icon>
                                <div class="ml-2">
                                    <div class="d-flex mt-1">
                                        <h3 class="mb-1">{{application.name}}</h3>
                                        <h3 class="ml-1 mb-1 text-muted font-weight-thin">{{platformFormatter.platformName(application.platform)}}</h3>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="w-100 h-100 p-3" *ngIf="applicationDistributions && applicationDistributions.length == 0">
                <div class="card wizard w-100 h-100 p-3 bg-transparent">
                    <div class="card-body text-center d-flex justify-content-center align-items-center">
                        <div class="wizard-content">
                            <i class="icon-cross2 icon-2x text-primary border-primary border-3 rounded-pill p-3 my-3"></i>
                            <h5 class="mt-2 mb-0">No apps available</h5>
                            <p class="text-muted">Please come back later or contact us if you are missing an access.</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
