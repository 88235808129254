import { Component, HostListener, OnInit } from '@angular/core';
import { AlertService, AuthenticationService, WorkspaceService } from 'app/_services'
import { FormGroup, FormBuilder } from '@angular/forms';
import { Application, BackendEnvironment, Workspace } from 'app/_models';
import { PlatformFormatter, SearchUtils } from 'app/_helpers';
import { Router } from '@angular/router';

@Component({
    selector: 'quick-jump-modal',
    templateUrl: 'quick.jump.modal.html',
    styleUrls: ['quick.jump.modal.css']
})
export class QuickJumpModal implements OnInit {

    private workspace: Workspace
    searchForm: FormGroup

    filteredApplications: Application[] = []
    filteredBackendEnvironments: BackendEnvironment[] = []

    // Zero can be used as a neutral value as no apps have ID zero
    selectedApplicationId = 0
    selectedBackendEnvironmentId = 0

    private allApplications: Application[]
    private allBackendEnvironments: BackendEnvironment[]

    private isShown = false

    constructor(
        public formBuilder: FormBuilder,
        public platformFormatter: PlatformFormatter,
        private alertService: AlertService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private workspaceService: WorkspaceService,
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue

        this.searchForm = this.formBuilder.group({
            query: [null],
        })

        this.searchForm.controls.query.valueChanges.subscribe(value => {
            // Remove selection
            this.selectedApplicationId = 0
            this.selectedBackendEnvironmentId = 0

            this.updateSearchResults()
        })

        let weakThis = this
        $('#quickJumpModal').on('hide.bs.modal', function () {
            // Fix warning in Chrome about hiding an element that is focused
            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur()
            }

            weakThis.isShown = false
            weakThis.reset()
        })
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (this.isShown) {
            if (event.key === 'ArrowUp') { this.handleArrowUp(); event.preventDefault() }
            else if (event.key === 'ArrowDown') { this.handleArrowDown(); event.preventDefault() }
            else if (event.key == 'Enter') { this.navigateToSelection(); event.preventDefault() }
            else if (event.key == 'Escape') { this.hide() }

        } else {
            if (event.key == 'j' && this.isAnyInputFieldFocused == false) { this.show(); event.preventDefault() }
        }
    }

    show() {
        if (this.isShown) { return }

        let quickJumpModal: any = $('#quickJumpModal')
        quickJumpModal.modal('show')

        document.getElementById('queryInput').focus()
        this.loadDataIfNeeded()

        this.isShown = true
    }

    hide() {
        if (!this.isShown) { return }

        // Fix warning in Chrome about hiding an element that is focused
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur()
        }

        let quickJumpModal: any = $('#quickJumpModal')
        quickJumpModal.modal('hide')

        this.reset()
        this.isShown = false
    }

    navigateToApplication(application: Application) {
        this.router.navigate(['/', this.workspace.slug, 'apps', application.slug])
        this.hide()
    }

    navigateToBackendEnvironment(backendEnvironment: BackendEnvironment) {
        this.router.navigate(['/', this.workspace.slug, 'envs', backendEnvironment.slug, 'network'], { queryParams: { environment: backendEnvironment.name } })
        this.hide()
    }

    private handleArrowDown() {
        // If nothing is selected, try to select the first app
        if (this.selectedApplicationId == 0 && this.selectedBackendEnvironmentId == 0) {
            if (this.filteredApplications.length > 0) {
                this.selectedApplicationId = this.filteredApplications[0].id
                this.selectedBackendEnvironmentId = 0
                return
            }
        }

        // If there is already an app selected, try to select the next one
        if (this.selectedApplicationId > 0) {
            let nextApplicationIndex = this.filteredApplications.findIndex(candidate => candidate.id === this.selectedApplicationId) + 1
            if (nextApplicationIndex < this.filteredApplications.length) {
                this.selectedApplicationId = this.filteredApplications[nextApplicationIndex].id
                this.selectedBackendEnvironmentId = 0
                return
            }
        }

        // If there is no env selected, try to select the first one
        if (this.selectedBackendEnvironmentId == 0) {
            if (this.filteredBackendEnvironments.length > 0) {
                this.selectedBackendEnvironmentId = this.filteredBackendEnvironments[0].id
                this.selectedApplicationId = 0
                return
            }
        }

        // If there is already an env selected, try to select the next one
        if (this.selectedBackendEnvironmentId > 0) {
            let nextEnvironmentIndex = this.filteredBackendEnvironments.findIndex(candidate => candidate.id === this.selectedBackendEnvironmentId) + 1
            if (nextEnvironmentIndex < this.filteredBackendEnvironments.length) {
                this.selectedBackendEnvironmentId = this.filteredBackendEnvironments[nextEnvironmentIndex].id
                this.selectedApplicationId = 0
                return
            }
        }
    }

    private handleArrowUp() {
        // If nothing is selected, ignore the call
        if (this.selectedApplicationId == 0 && this.selectedBackendEnvironmentId == 0) {
            return
        }

        // If there is already an env selected, try to select the previous one
        if (this.selectedBackendEnvironmentId > 0) {
            let prevEnvironmentIndex = this.filteredBackendEnvironments.findIndex(candidate => candidate.id === this.selectedBackendEnvironmentId) - 1
            if (prevEnvironmentIndex >= 0) {
                this.selectedApplicationId = 0
                this.selectedBackendEnvironmentId = this.filteredBackendEnvironments[prevEnvironmentIndex].id
                return
            }
        }

        // If there is no app selected, try to select the last one
        if (this.selectedApplicationId == 0) {
            if (this.filteredApplications.length > 0) {
                this.selectedApplicationId = this.filteredApplications[this.filteredApplications.length - 1].id
                this.selectedBackendEnvironmentId = 0
                return
            }
        }

        // If there is already an app selected, try to select the previous one
        if (this.selectedApplicationId > 0) {
            let prevApplicationIndex = this.filteredApplications.findIndex(candidate => candidate.id === this.selectedApplicationId) - 1
            if (prevApplicationIndex >= 0) {
                this.selectedApplicationId = this.filteredApplications[prevApplicationIndex].id
                this.selectedBackendEnvironmentId = 0
                return
            }
        }
    }

    private navigateToSelection() {
        if (this.selectedApplicationId != 0) {
            let application = this.filteredApplications.find(candidate => { return candidate.id == this.selectedApplicationId })
            this.navigateToApplication(application)

        } else if (this.selectedBackendEnvironmentId != 0) {
            let environment = this.filteredBackendEnvironments.find(candidate => { return candidate.id == this.selectedBackendEnvironmentId })
            this.navigateToBackendEnvironment(environment)

        } else if (this.filteredApplications.length == 1 && this.filteredBackendEnvironments.length == 0) {
            this.navigateToApplication(this.filteredApplications[0])

        } else if (this.filteredApplications.length == 0 && this.filteredBackendEnvironments.length == 1) {
            this.navigateToBackendEnvironment(this.filteredBackendEnvironments[0])
        }
    }

    private loadDataIfNeeded() {
        if (this.workspace == null || this.allApplications?.length > 0) { return }

        this.workspaceService.getWorkspaceDashboard(this.workspace.id).then((response) => {
            this.allApplications = response.applications
            this.allBackendEnvironments = response.environments
            this.updateSearchResults()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private reset() {
        this.filteredApplications = []
        this.filteredBackendEnvironments = []
        this.selectedApplicationId = 0
        this.selectedBackendEnvironmentId = 0
        this.searchForm.controls.query.setValue(null)
    }

    private updateSearchResults() {
        let searchQuery: string = this.searchForm.controls.query.value

        if (searchQuery == null || searchQuery.length == 0) {
            this.filteredApplications = []
            this.filteredBackendEnvironments = []
            this.selectedApplicationId = 0
            this.selectedBackendEnvironmentId = 0
            return
        }

        this.filteredApplications = this.matchingApplications(searchQuery)
        this.filteredBackendEnvironments = this.matchingBackendApplications(searchQuery)

        // Select the first result if it is the only one
        if (this.filteredApplications.length == 1 && this.filteredBackendEnvironments.length == 0) {
            this.selectedApplicationId = this.filteredApplications[0].id
            this.selectedBackendEnvironmentId = 0

        } else if (this.filteredApplications.length == 0 && this.filteredBackendEnvironments.length == 1) {
            this.selectedApplicationId = 0
            this.selectedBackendEnvironmentId = this.filteredBackendEnvironments[0].id
        }
    }

    private matchingApplications(searchQuery: string): Application[] {
        if (!this.allApplications?.length) { return [] }

        return this.allApplications.filter((app) => {
            let searchableFields = [app.name, app.slug, this.platformFormatter.platformName(app.platform)]
            return SearchUtils.searchQueryMatchesSearchableFields(searchQuery, searchableFields)
        })
    }

    private matchingBackendApplications(searchQuery: string): BackendEnvironment[] {
        if (!this.allBackendEnvironments?.length) { return [] }

        return this.allBackendEnvironments.filter((environment) => {
            var searchableFields = [environment.name, environment.slug]
            if (environment.latestVersion) {
                searchableFields.push(environment.latestVersion.version)
            }
            return SearchUtils.searchQueryMatchesSearchableFields(searchQuery, searchableFields)
        })
    }

    private get isAnyInputFieldFocused(): boolean {
        const focusedElement = document.activeElement;

        if (focusedElement instanceof HTMLInputElement ||
            focusedElement instanceof HTMLTextAreaElement ||
            focusedElement instanceof HTMLSelectElement) {
            return true
        }

        return false
    }

}
