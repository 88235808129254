import { Animations } from 'app/_animations/animations';
import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ApplicationIconInterface, ApplicationService } from 'app/_services';
import { trigger } from '@angular/animations';

@Component({
    selector: 'app-icon',
    templateUrl: 'app.icon.component.html',
    styleUrls: [
        'app.icon.component.css'
    ],
    animations: [
        trigger('iconLoaded', Animations.fadeInTransition)
    ]
})

export class AppIconComponent {

    iconLoaded = false
    iconUrl: SafeUrl
    animateAppearance = false

    private defaultIconUrl = this.sanitizer.bypassSecurityTrustUrl("assets/img/default_app_icon.jpeg")

    private _application: ApplicationIconInterface
    @Input()
    set application(application: ApplicationIconInterface) {
        if (this._application === application) {
            return
        }
        this._application = application
        this.reloadIcon()
    }
    get application() { return this._application }

    @Input() enableAppearanceAnimations = false

    constructor(
        private applicationService: ApplicationService,
        private sanitizer: DomSanitizer
    ) {
    }

    onImgLoad() {
        this.iconLoaded = true
    }

    private reloadIcon() {
        this.iconUrl = null
        this.iconLoaded = false
        if (!this.application) { return }

        if (this.application.icon) {
            this.applicationService.getApplicationIconBlob(this.application).then((iconBlob) => {
                if (iconBlob) {
                    this.iconUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(iconBlob))
                    this.animateAppearance = this.enableAppearanceAnimations
                } else {
                    this.iconUrl = this.defaultIconUrl
                    this.animateAppearance = false
                }
            })
        } else {
            this.iconUrl = this.defaultIconUrl
            this.animateAppearance = false
        }
    }

}
