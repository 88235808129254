import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MissingSymbolsFile } from 'app/_models';
import { AppConfig } from 'app/app.config';
import { CollectionResponse } from './response';

@Injectable({ providedIn: 'root' })
export class MissingSymbolsService {

    constructor(private http: HttpClient) {
    }

    getMissingSymbolsFiles(applicationId: number | null, crashReportId: string | null): Promise<CollectionResponse<MissingSymbolsFile>> {
        let url = `${AppConfig.apiBaseUrl}/missingSymbolsFiles`
        const params = []

        if (applicationId !== null) {
            params.push(`applicationId=${applicationId}`)
        }
        if (crashReportId !== null) {
            params.push(`crashReportId=${crashReportId}`)
        }

        if (params.length > 0) {
            url += `?${params.join('&')}`
        }

        return this.http.get<CollectionResponse<MissingSymbolsFile>>(url).toPromise();
    }

}
