import { Component, OnInit, OnDestroy, ViewChild, AfterViewChecked } from '@angular/core';
import * as moment from 'moment'
import { BackendResponse, TestRunInfo2, TestCase, TimeFilter, Application, BackendEnvironment } from 'app/_models';
import { TestRunResult } from 'app/_models/test.run.result';
import { AlertService, TestRunsService, TestCasesService, ApplicationService } from 'app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { TestsFilter } from 'app/_models/tests.filter';
import { ApplicationFormatter, DateFormatter } from 'app/_helpers';
import { Subscription } from 'rxjs';
import { EditTestCaseModal } from './edit.test.case.modal/edit.test.case.modal';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'test-case',
    templateUrl: 'test.case.component.html',
    styleUrls: [
        'test.case.component.css'
    ]
})
export class TestCaseComponent implements OnInit, AfterViewChecked, OnDestroy {

    application: Application

    @ViewChild('editTestCaseModal') editTestCaseModal: EditTestCaseModal

    testCase: TestCase
    testRunInfos: TestRunInfo2[]

    selectedTestsFilter: TestsFilter = TestsFilter.All
    selectedTimeFilter: TimeFilter = TimeFilter.OneMonth
    filteredTestRunInfos: TestRunInfo2[]

    environments: BackendEnvironment[]

    passedCount: number
    passedPercentage: string
    failedCount: number
    failedPercentage: string
    skippedPercentage: string
    successRate: string

    private queryParamsSubscription: Subscription
    private currentApplicationSubscription: Subscription

    constructor(
        public dateFormatter: DateFormatter,
        private testCasesService: TestCasesService,
        private route: ActivatedRoute,
        private router: Router,
        private alertService: AlertService,
        private applicationService: ApplicationService,
        private testRunService: TestRunsService,
        private applicationFormatter: ApplicationFormatter,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
            var newTimeFilter = params['history']
            if (newTimeFilter == null) {
                newTimeFilter = TimeFilter.OneMonth
            }

            var newTestsFilter = params['filter']
            if (newTestsFilter == null) {
                newTestsFilter = TestsFilter.All
            }

            this.selectedTimeFilter = newTimeFilter
            this.selectedTestsFilter = newTestsFilter
            this.reloadTestRuns()
            this.updateSelect2SelectionFromQuery()
        })

        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
            if (this.application != null) {
                this.reloadTestCase()
            }
        })
    }

    private _didInitSelect2 = false
    ngAfterViewChecked() {
        if (!this._didInitSelect2 && $('.select2').length > 0) {
            this.initSelect2Components()
            this.updateSelect2SelectionFromQuery()
            this._didInitSelect2 = true
        }
    }

    ngOnDestroy() {
        this.queryParamsSubscription?.unsubscribe()
        this.currentApplicationSubscription?.unsubscribe()
    }

    reloadTestCase() {
        let testCaseSlug = this.route.snapshot.params['testCaseSlug']

        this.testCasesService.getTestCase(this.application.id, testCaseSlug).then((response) => {
            this.testCase = response.data[0]
            this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | ${this.testCase.suiteName}.${this.testCase.name}`)
            this.reloadTestRuns()
            return this.testCasesService.getTestCaseFilters(this.testCase.id)

        }).then((response) => {
            this.environments = response.environments
            this.updateSelect2SelectionFromQuery()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    reloadTestRuns() {
        if (!this.testCase) { return }

        var environmentId: number = null
        let environmentFromQuery = this.environmentWithName(this.route.snapshot.queryParams['environment'])
        if (environmentFromQuery) {
            environmentId = environmentFromQuery.id
        }

        this.testRunService.getTestCaseTestRuns(this.testCase.id, environmentId, this.selectedTimeFilter).then((response) => {
            this.testRunInfos = response.data
            this.filterTestRuns()
        })
    }

    setTestsFilter(testsFilter: TestsFilter) {
        this.router.navigate([], { queryParams: { filter: testsFilter, history: this.selectedTimeFilter }, queryParamsHandling: 'merge' })
    }

    setTimeFilter(timeFilter: TimeFilter) {
        this.router.navigate([], { queryParams: { history: timeFilter, filter: this.selectedTestsFilter }, queryParamsHandling: 'merge' })
    }

    onAnnotateButtonClick() {
        this.editTestCaseModal.testCase = this.testCase
    }

    onTestCaseUpdated() {
        this.reloadTestCase()
    }

    filterTestRuns() {
        if (this.testRunInfos == null) {
            return
        }

        this.filteredTestRunInfos = []
        this.passedCount = 0
        this.failedCount = 0

        for (let testRun of this.testRunInfos) {
            var shouldIncludeTestRun = false
            switch (testRun.testRunSummary.result) {
                case TestRunResult.Success:
                    shouldIncludeTestRun = this.selectedTestsFilter == TestsFilter.All || this.selectedTestsFilter == TestsFilter.Passed
                    this.passedCount += 1
                    break

                case TestRunResult.Aborted:
                case TestRunResult.Failure:
                    shouldIncludeTestRun = this.selectedTestsFilter == TestsFilter.All || this.selectedTestsFilter == TestsFilter.Failed
                    this.failedCount += 1
                    break

                default:
                    shouldIncludeTestRun = this.selectedTestsFilter == TestsFilter.All
                    break
            }

            if (shouldIncludeTestRun) {
                this.filteredTestRunInfos.push(testRun)
            }
        }

        let total = this.passedCount + this.failedCount
        if (total == 0) {
            this.successRate = '-'
            this.passedPercentage = "0%"
            this.failedPercentage = "0%"
            this.skippedPercentage = "100%"
            return
        }

        this.passedPercentage = (this.passedCount / total) * 100 + "%"
        this.failedPercentage = (this.failedCount / total) * 100 + "%"
        this.skippedPercentage = "0%"
        this.successRate = ((this.passedCount / total) * 100).toFixed(0) + '%'
    }

    statusClassForTestRun(testRunInfo: TestRunInfo2): string {
        switch (testRunInfo.testRunSummary.result) {
            case TestRunResult.Pending: return "badge badge-mark border-grey-400"
            case TestRunResult.InProgress: return "badge badge-mark border-grey-400"
            case TestRunResult.Skipped: return "badge badge-mark border-grey-400"
            case TestRunResult.Success: return "badge badge-mark border-success-400"
            case TestRunResult.Failure: return "badge badge-mark border-pink-400"
            case TestRunResult.Aborted: return "badge badge-mark border-slate-600"
        }
    }

    statusTextForTestRun(testRunInfo: TestRunInfo2): string {
        switch (testRunInfo.testRunSummary.result) {
            case TestRunResult.Pending: return "Pending"
            case TestRunResult.InProgress: return "Executing"
            case TestRunResult.Skipped: return "Skipped"
            case TestRunResult.Success: return "Passed"
            case TestRunResult.Failure: return testRunInfo.testRunSummary.failureDescription
            case TestRunResult.Aborted: return "Aborted"
        }
    }

    testRunDuration(testRun: TestRunInfo2): string {
        return (testRun.testRunSummary.duration !== undefined) ? this.dateFormatter.duration(testRun.testRunSummary.duration) : "-"
    }

    navigateToTestRunInfo(testRunInfo: TestRunInfo2) {
        this.router.navigate(['../../test-executions/', testRunInfo.testExecutionSummary.serialNumber, 'runs', testRunInfo.testRunSummary.serialNumber], { relativeTo: this.route })
    }

    copyTestNameToClipboard() {
        navigator.clipboard.writeText(`${this.testCase.suiteName}.${this.testCase.name}`)
    }

    private initSelect2Components() {
        $('.select2').select2({ placeholder: 'Any', allowClear: true, dropdownAutoWidth: true, width: '250px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678

        // Fix for select2 being expanded after clicking on clear button
        $('.select2').on("select2:unselecting", (event) => {
            $(event.target).val(null).trigger('change')
            event.preventDefault()
        })

        $('#environmentNameSelect').on('select2:select', (event) => {
            let selectedEnvironment = this.environmentWithName(event.params.data.text)
            this.router.navigate([], { queryParams: { environment: selectedEnvironment.name }, queryParamsHandling: 'merge' })
        })
        $('#environmentNameSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { environment: null }, queryParamsHandling: 'merge' })
        })
    }

    private updateSelect2SelectionFromQuery() {
        if (!this.environments) { return }

        let environmentFromQuery = this.environmentWithName(this.route.snapshot.queryParams['environment'])
        if (environmentFromQuery) {
            $('#environmentNameSelect').val(environmentFromQuery.name).trigger('change')
        } else {
            $('#environmentNameSelect').val(null).trigger('change')
        }
    }

    private environmentWithName(environmentName: string): BackendEnvironment | null {
        if (this.environments == null || name == null) { return null }
        return this.environments.find((environment) => { return environment.name == environmentName })
    }

}
