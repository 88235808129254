import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Application, CrashApplicationSegment, CrashDimension, CrashFilter, CrashGroupSegment, CrashPlatformSegment, CrashSegmentsOrder, crashSegmentsOrderFromQuery, CrashTrendsResponse, CrashTrendsSummaryResponse, DateRange, IssueState, MissingSymbolsFile, Workspace } from 'app/_models';
import { AlertService, AuthenticationService, CrashFiltersResponse, CrashesService, MissingSymbolsService } from 'app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationFormatter, DateFormatter, IssueStateFormatter, PlatformFormatter } from 'app/_helpers';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment'
import { Utils } from 'app/utils';
import { UploadSymbolsComponent } from 'app/_global.modals';

@Component({
    selector: 'crashes-overview',
    templateUrl: 'crashes.overview.component.html',
    styleUrls: [
        'crashes.overview.component.css'
    ]
})
export class CrashesOverviewComponent implements OnInit, OnDestroy {

    CrashSegmentsOrder = CrashSegmentsOrder
    CrashDimension = CrashDimension
    IssueState = IssueState

    workspace: Workspace
    crashGroupSegments: CrashGroupSegment[]
    crashApplicationSegments: CrashApplicationSegment[]
    crashPlatformSegments: CrashPlatformSegment[]
    missingSymbols: MissingSymbolsFile[]

    crashFiltersResponse: CrashFiltersResponse
    crashTrendsResponse: CrashTrendsResponse
    crashTrendsSummaryResponse: CrashTrendsSummaryResponse

    selectedDateRange: DateRange
    crashDimension: CrashDimension

    @Input() application: Application

    @ViewChild('uploadSymbolsModal') uploadSymbolsModal: UploadSymbolsComponent

    private queryParamsSubscription: Subscription
    private previousQueryParams: any = {}

    constructor(
        public dateFormatter: DateFormatter,
        public issueStateFormatter: IssueStateFormatter,
        public platformFormatter: PlatformFormatter,
        private crashesService: CrashesService,
        private missingSymbolsService: MissingSymbolsService,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        private alertService: AlertService,
        private applicationFormatter: ApplicationFormatter,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue
        this.titleService.setTitle(`${this.workspace.name} | Crashes`)
        this.refreshFilters()
        this.reloadMissingSymbols()

        let query = this.route.snapshot.queryParams
        this.selectedDateRange = DateRange.fromParams(query) ?? DateRange.lastMonth()
        this.crashDimension = query['dimension'] ?? CrashDimension.CrashGroups
        this.updateSelect2SelectionFromQuery()

        this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
            let changedParamNames = Utils.getChangedParamNames(params, this.previousQueryParams)
            this.previousQueryParams = { ...params }

            this.updateSelect2SelectionFromQuery()

            // If we don't have the filters response yet, no need to do anything as all the data will
            // get reloaded once the filters call finishes.
            if (!this.crashFiltersResponse) { return }

            // If only the dimension changed, reload just the segments
            if (changedParamNames.toString() == ['dimension'].toString()) {
                this.crashDimension = params['dimension']
                this.reloadCrashSegments()

            // If ordering changed, reload only the segments
            } else if (changedParamNames.toString() == ['orderBy'].toString()) {
                this.reloadCrashSegments()

            // If we reached this point, it means that either date range or the filters changed,
            // and then we need to reload everything.
            } else {
                this.selectedDateRange = DateRange.fromParams(params) ?? DateRange.lastMonth()
                this.refreshCrashTrends()
                this.reloadCrashSegments()
            }
        })
    }

    ngOnDestroy() {
        this.queryParamsSubscription?.unsubscribe()
    }

    private _didInitSelect2 = false
    ngAfterViewChecked() {
        if (!this.crashFiltersResponse) { return }

        if (!this._didInitSelect2 && $('.select2').length > 0) {
            this.initSelect2Components()
            this.updateSelect2SelectionFromQuery()
            this._didInitSelect2 = true
        }
    }

    refreshFilters() {
        if (!this.workspace) { return }

        this.crashFiltersResponse = null

        let filtersPromise = this.application ? this.crashesService.getApplicationCrashFilters(this.application.id) : this.crashesService.getWorkspaceCrashFilters(this.workspace.id)
            filtersPromise.then((response) => {
            this.crashFiltersResponse = response
            this.refreshCrashTrends()
            this.reloadCrashSegments()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    refreshCrashTrends() {
        if (!this.workspace) { return }

        // Will also switch the trends chart into loading mode
        this.crashTrendsResponse = null
        this.crashTrendsSummaryResponse = null

        let crashFilter = this.makeCurrentCrashFilter()

        this.crashesService.getCrashTrends(this.workspace.id, crashFilter).then((response) => {
            this.crashTrendsResponse = response
            this.selectedDateRange = response.dateRange

            this.crashesService.getCrashTrendsSummary(this.workspace.id, crashFilter).then((response) => {
                this.crashTrendsSummaryResponse = response
            }).catch((error) => {
                this.alertService.handleError(error)
            })
        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    reloadMissingSymbols() {
        // Don't load missing symbols for the entire workspace
        if (!this.application) { return }

        this.missingSymbols = null
        this.missingSymbolsService.getMissingSymbolsFiles(this.application.id, null).then((response) => {
            this.missingSymbols = response.data
        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    reloadCrashSegments() {
        if (!this.workspace) { return }

        this.crashGroupSegments = null
        this.crashApplicationSegments = null

        let crashFilter = this.makeCurrentCrashFilter()
        let orderBy = crashSegmentsOrderFromQuery(this.route.snapshot.queryParams) ?? CrashSegmentsOrder.COUNT_DESC
        return this.crashesService.getWorkspaceCrashSegments(this.workspace.id, crashFilter, this.crashDimension, orderBy).then((response) => {
            switch (this.crashDimension) {
                case CrashDimension.CrashGroups: this.crashGroupSegments = response.data as any; break
                case CrashDimension.Applications: this.crashApplicationSegments = response.data as any; break
                case CrashDimension.Platforms: this.crashPlatformSegments = response.data as any; break
            }

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    onDateRangeChanged(dateRange: DateRange) {
        this.router.navigate([], { queryParams: { startDate: moment(dateRange.startDate).toISOString(), endDate: moment(dateRange.endDate).toISOString() }, queryParamsHandling: 'merge' })
    }

    setCrashDimension(crashDimension: CrashDimension) {
        this.router.navigate([], { queryParams: { dimension: crashDimension }, queryParamsHandling: 'merge' })
    }

    navigateToCrashGroup(crashGroupSegment: CrashGroupSegment) {
        this.router.navigate([crashGroupSegment.serialNumber], { relativeTo: this.route, queryParamsHandling: 'merge' } )
    }

    showUploadSymbolsModal() {
        this.uploadSymbolsModal.application = this.application
        this.uploadSymbolsModal.missingSymbols = this.missingSymbols
    }

    dismissMissingSymbolsAlert() {
        this.missingSymbols = null
    }

    private makeCurrentCrashFilter(): CrashFilter {
        if (!this.workspace || !this.crashFiltersResponse) {
            return null
        }

        let query = this.route.snapshot.queryParams

        let crashFilter = new CrashFilter()
        crashFilter.startDate = moment(this.selectedDateRange.startDate).toDate()
        crashFilter.endDate = moment(this.selectedDateRange.endDate).toDate()
        crashFilter.issueState = this.issueStateFormatter.issueStateFromDisplayName(query['state'])
        crashFilter.platform = this.platformFormatter.platformFromDisplayName(query['platform'])

        // If we have an application, we need to filter by it, this is set only in the application errors page
        if (this.application) {
            crashFilter.applicationId = this.application.id
        }

        if (this.crashFiltersResponse) {
            // In case of workspace errors page, we need to apply the filter for the selected application.
            if (query['app']) {
                crashFilter.applicationId = query['app']
            }

            if (query['bundleIdentifier']) {
                crashFilter.bundleIdentifierId = this.crashFiltersResponse.bundleIdentifiers.find((bi) => { return bi.value == query['bundleIdentifier'] }).id
            }

            if (query['applicationVersion']) {
                crashFilter.applicationVersionId = this.crashFiltersResponse.applicationVersions.find((v) => { return v.bundleShortVersion == query['applicationVersion'] }).id
            }

            if (query['osVersion']) {
                crashFilter.osVersionId = this.crashFiltersResponse.osVersions.find((osVersion) => { return osVersion.version == query['osVersion'] }).id
            }
        }

        return crashFilter
    }

    private initSelect2Components() {
        $('#applicationSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '180px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#platformSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '140px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#bundleIdentifierSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '240px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#applicationVersionSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '160px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#osVersionSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '140px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#issueStateSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '140px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678

        // Fix for select2 being expanded after clicking on clear button
        $('.select2').on("select2:unselecting", (event) => {
            $(event.target).val(null).trigger('change')
            event.preventDefault()
        })

        // Applications
        $('#applicationSelect').on('select2:select', (event) => {
            let application = this.crashFiltersResponse.applications.find((o) => { return `${o.name} ${this.platformFormatter.platformName(o.platform)}` == event.params.data.id })
            this.router.navigate([], { queryParams: { app: application.id }, queryParamsHandling: 'merge' })
        })
        $('#applicationSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { app: null }, queryParamsHandling: 'merge' })
        })

        // Bundle identifiers
        $('#bundleIdentifierSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { bundleIdentifier: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#bundleIdentifierSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { bundleIdentifier: null }, queryParamsHandling: 'merge' })
        })

        // Application versions
        $('#applicationVersionSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { applicationVersion: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#applicationVersionSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { applicationVersion: null }, queryParamsHandling: 'merge' })
        })

        // Os versions
        $('#osVersionSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { osVersion: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#osVersionSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { osVersion: null }, queryParamsHandling: 'merge' })
        })

        // Issue states
        $('#issueStateSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { state: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#issueStateSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { state: null }, queryParamsHandling: 'merge' })
        })

        // Platforms
        $('#platformSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { platform: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#platformSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { platform: null }, queryParamsHandling: 'merge' })
        })
    }

    private updateSelect2SelectionFromQuery() {
        if (!this.workspace || !this.crashFiltersResponse) { return }

        let query = this.route.snapshot.queryParams

        $('#bundleIdentifierSelect').val(query['bundleIdentifier']).trigger('change')
        $('#applicationVersionSelect').val(query['applicationVersion']).trigger('change')
        $('#osVersionSelect').val(query['osVersion']).trigger('change')
        $('#issueStateSelect').val(query['state']).trigger('change')

        if (query['platform']) {
            $('#platformSelect').val(query['platform']).trigger('change')
        } else {
            $('#platformSelect').val(null).trigger('change')
        }

        if (query['app']) {
            let application = this.crashFiltersResponse.applications.find((a) => { return a.id == query['app'] })
            if (application) { $('#applicationSelect').val(application.name + ' ' + this.platformFormatter.platformName(application.platform)).trigger('change') }
        } else {
            $('#applicationSelect').val(null).trigger('change')
        }
    }

}
