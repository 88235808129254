import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AuthenticationService, WorkspaceService, AlertService, InvoicesService } from 'app/_services'
import { Workspace, BackendResponse, Role, WorkspaceSubscription, Invoice } from 'app/_models';
import { DateFormatter, InvoiceStateFormatter } from 'app/_helpers';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
    templateUrl: 'invoice.component.html'
})
export class InvoiceComponent implements OnInit, OnDestroy {

    invoice: Invoice | null

    private workspace: Workspace
    private routeParamsSubscription: Subscription

    constructor(
        private route: ActivatedRoute,
        private alertService: AlertService,
        private authenticationService: AuthenticationService,
        private invoicesService: InvoicesService,
        private dateFormatter: DateFormatter,
        private invoiceStateFormatter: InvoiceStateFormatter
    ) {
        this.workspace = this.authenticationService.currentWorkspaceValue
    }

    ngOnInit() {
        this.routeParamsSubscription = this.route.params.subscribe(params => {
            this.loadInvoice()
        })
    }

    ngOnDestroy() {
        this.routeParamsSubscription?.unsubscribe()
    }

    loadInvoice() {
        let invoiceSlug = this.route.snapshot.params['invoiceSlug']
        if (invoiceSlug == null) {
            return
        }

        this.invoicesService.getWorkspaceInvoicesWithSlug(this.workspace.id, invoiceSlug).then((response) => {
            this.invoice = response.data[0]

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
