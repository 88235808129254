<div class="content-wrapper" #pageContent>

    <div class="content">
        <div class="row">
            <div class="col-md-12">
                <alert></alert>
            </div>
        </div>

        <div class="row" *ngIf="application">
            <div class="col-md-12">
                <div class="card">
                    <div class="page-header border-bottom-light">
                        <div class="page-header-content header-elements-md-inline">
                            <div class="page-title">
                                <h1 class="font-weight-thin">
                                    <a [routerLink]="['../']" class="breadcrumb-item">{{application.name}}</a>
                                    <span class="breadcrumb-item active">Error Groups</span>
                                </h1>
                            </div>
                        </div>
                    </div>

                    <errors-overview [application]="application"></errors-overview>
                </div>

            </div>
        </div>

    </div>
</div>
