import { CountFormatter, PercentageFormatter } from 'app/_helpers';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { Subscription, Observable, timer } from 'rxjs';
import { NetworkSegment } from 'app/_models';

@Component({
    selector: 'network-failures-progress',
    templateUrl: 'network.failures.progress.item.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NetworkFailuresProgressItem implements OnDestroy {

    _networkSegment: NetworkSegment
    @Input()
    set networkSegment(networkSegment: NetworkSegment) {
        this._networkSegment = networkSegment

        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe()
        }

        // Call immediately as the second call in a timer is causing progress to be animated
        this.recalculateState()
        this.timerSubscription = this.timerObservable.subscribe((seconds) => {
            this.recalculateState()
        })
    }
    get networkSegment() { return this._networkSegment }

    statusText: string
    detailText: string | null

    hasNetworkFailures: boolean

    percentageClient: string
    percentageBusiness: string
    percentageServer: string

    private timerObservable: Observable<number> = timer(0, 1000)
    private timerSubscription: Subscription

    constructor(
        private countFormatter: CountFormatter,
        private percentageFormatter: PercentageFormatter,
        private changeDetector: ChangeDetectorRef
    ) {
    }

    ngOnDestroy() {
        this.timerSubscription?.unsubscribe()
    }

    private recalculateState() {
        let segment = this.networkSegment
        let totalErrorsCount = segment.clientErrors + segment.businessErrors + segment.serverErrors

        if (totalErrorsCount == 0) {
            this.hasNetworkFailures = false
            this.changeDetector.detectChanges()
            return
        }

        this.hasNetworkFailures = true

        this.percentageClient = (segment.clientErrors / segment.requestsCount) * 100 + "%"
        this.percentageBusiness = (segment.businessErrors / segment.requestsCount) * 100 + "%"
        this.percentageServer = (segment.serverErrors / segment.requestsCount) * 100 + "%"

        this.statusText = this.countFormatter.countString(totalErrorsCount)

        this.detailText = this.percentageFormatter.percentage(totalErrorsCount / segment.requestsCount) + "%"
        this.changeDetector.detectChanges()
    }

}
