import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { AuthenticationService, AlertService, InvoicesService, SubscriptionTemplatesResponse, WorkspaceSubscriptionService, WorkspaceSubscriptionPreview } from 'app/_services'
import { Workspace, BackendResponse, Role, Invoice, SubscriptionType, SubscriptionTemplate } from 'app/_models';
import { DateFormatter, InvoiceStateFormatter, DataRetentionTierFormatter } from 'app/_helpers';
import { Subscription } from 'rxjs';
import * as bootbox from 'bootbox'
import { Router } from '@angular/router';
import * as FileServer from 'file-saver'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';

@Component({
    templateUrl: 'subscription.settings.component.html',
    styleUrls: [
        'subscription.settings.component.css'
    ]
})
export class SubscriptionSettingsComponent implements OnInit, OnDestroy {

    workspace: Workspace
    subscriptionTemplates: SubscriptionTemplatesResponse
    billingPreview: WorkspaceSubscriptionPreview
    invoices: Invoice[]
    nextInvoiceDate: string
    canEditWorkspace: boolean
    isFreeSubscription: boolean
    isTrialSubscription: boolean
    isDemoSubscription: boolean
    yearlyBilling = true
    voucherForm: FormGroup
    voucherErrorMessage: string
    voucherRedeemed = false

    @ViewChild('closeButton') closeButton: ElementRef

    private purchasingSubscription: SubscriptionTemplate
    private currentWorkspaceSubscription: Subscription

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private invoicesService: InvoicesService,
        private dateFormatter: DateFormatter,
        private workspaceSubscriptionService: WorkspaceSubscriptionService,
        private invoiceStateFormatter: InvoiceStateFormatter,
        private formBuilder: FormBuilder,
        public dataRetentionTierFormatter: DataRetentionTierFormatter,
        private titleService: Title
    ) {
        // Observe for Workspace changes after purchase is made
        this.currentWorkspaceSubscription = this.authenticationService.currentWorkspace.subscribe(workspace => {
            if (!workspace) { return }

            this.workspace = workspace
            this.canEditWorkspace = this.workspace.role == Role.Agent
            this.isFreeSubscription = this.workspace.subscription.type == SubscriptionType.Free
            this.isTrialSubscription = this.workspace.subscription.type == SubscriptionType.ProTrial
            this.isDemoSubscription = this.workspace.subscription.isDemo
        })
    }

    ngOnInit() {
        this.titleService.setTitle("Settings | Subscription")

        this.voucherForm = this.formBuilder.group({
            voucherCode: [null, Validators.required],
        })

        this.reloadInvoices()

        return this.workspaceSubscriptionService.getSubscriptionTemplates("CHF").then((response) => {
            this.subscriptionTemplates = response

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    ngOnDestroy() {
        this.currentWorkspaceSubscription?.unsubscribe()
    }

    setYearlyBilling(enabled: boolean) {
        this.yearlyBilling = enabled
    }

    isDateInFuture(date: Date) {
        return new Date(date.toDateString()) > new Date(new Date().toDateString())
    }

    onSubscriptionUpgrade(subscription: SubscriptionTemplate) {
        this.purchasingSubscription = subscription

        if (this.workspace.billingAddress == null) {
            this.showMissingBillingInformationAlert()
            return
        }

        this.updateSubscriptionPreview()
    }

    updateSubscriptionPreview() {
        let voucherCode = this.voucherForm.controls.voucherCode.value
        this.workspaceSubscriptionService.getWorkspaceSubscriptionPreview(this.workspace.id, this.purchasingSubscription.type, this.yearlyBilling, "CHF", voucherCode).then((response) => {
            this.billingPreview = response
            this.voucherErrorMessage = null
            this.voucherRedeemed = voucherCode != null

        }).catch((error) => {
            if (error.status == 0 || (error.error as BackendResponse).error == null) {
                this.alertService.handleError(error)
                return
            }

            let backendResponse = error.error as BackendResponse
            this.voucherRedeemed = false
            this.voucherErrorMessage = backendResponse.error.title
        })
    }

    onConfirmSubscription() {
        let voucherCode = this.voucherForm.controls.voucherCode.value
        this.workspaceSubscriptionService.createWorkspaceSubscription(this.workspace.id, this.purchasingSubscription.type, this.yearlyBilling, "CHF", voucherCode).then((response) => {
            this.closeButton.nativeElement.click()
            return this.authenticationService.refreshCurrentWorkspace()

        }).then((response) => {
            this.reloadInvoices()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    downloadInvoice(invoice: Invoice) {
        this.invoicesService.getInvoiceHTML(invoice.id).then((response) => {
            FileServer.saveAs(response, `invoice_${invoice.serialNumber}.html`)

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    updateSubscriptionAutorenewal(enabled: boolean) {
        let weakThis = this

        if (enabled) {
            bootbox.dialog({
                title: `Enable automatic renewal?`,
                message: "Subscription will renew automatically 24h before expiration.",
                size: 'small',
                buttons: {
                    cancel: { label: 'Cancel', className: 'btn-link' },
                    confirm: {
                        label: 'Confirm', className: 'btn-primary', callback: function () {
                            weakThis.updateSubscription(weakThis.workspace.subscription.id, true)
                        }
                    }
                }
            })
        } else {
            bootbox.dialog({
                title: `Cancel automatic renewal?`,
                message: "Subscription will remain active but will not renew automatically once it expires.",
                size: 'small',
                buttons: {
                    cancel: { label: 'Cancel', className: 'btn-link' },
                    confirm: {
                        label: 'Confirm', className: 'btn-primary', callback: function () {
                            weakThis.updateSubscription(weakThis.workspace.subscription.id, false)
                        }
                    }
                }
            })
        }
    }

    private showMissingBillingInformationAlert() {
        let weakThis = this

        bootbox.dialog({
            title: `Billing information missing`,
            message: "Please fill in the billing information in Workspace settings before subscribing.",
            size: 'small',
            buttons: {
                cancel: { label: 'Cancel', className: 'btn-link' },
                confirm: {
                    label: 'Workspace settings', className: 'btn-primary', callback: function () {
                        weakThis.navigateToWorkspaceSettings()
                    }
                }
            }
        })
    }

    private navigateToWorkspaceSettings() {
        this.router.navigate(['/settings/workspace'])
    }

    private updateSubscription(id: string, automaticRenewalEnabled: boolean) {
        this.workspaceSubscriptionService.updateWorkspaceSubscription(id, automaticRenewalEnabled).then((response) => {
            return this.authenticationService.refreshCurrentWorkspace()

        }).then((response) => {

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private reloadInvoices() {
        return this.invoicesService.getWorkspaceInvoices(this.workspace.id).then((response) => {
            this.invoices = response.data

            let nextInvoiceDate = response.nextInvoiceDate
            this.nextInvoiceDate = nextInvoiceDate == null ? null : this.dateFormatter.timeAgo(nextInvoiceDate)

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
