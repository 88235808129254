export enum CrashGroupDimension {
    CrashReports = 'CRASH_REPORTS',
    Applications = 'APPLICATIONS',
}

export class CrashGroupDimensionQueryParser {

    static fromString(dimension: any): CrashGroupDimension | null {
        if (dimension == null) {
            return null
        }

        // Make sure that dimension is a valid CrashGroupDimension
        if (Object.values(CrashGroupDimension).includes(dimension)) {
            return dimension as CrashGroupDimension
        } else {
            return null
        }
    }

}
