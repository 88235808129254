import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Application, ImageFile, Platform, TestPlan } from 'app/_models';
import { AppConfig } from 'app/app.config';
import { CollectionResponse, ElementResponse } from './response';
import { BehaviorSubject, Observable } from 'rxjs';
import * as moment from 'moment'

export class ApplicationFilterInfos {
    testPlans: TestPlan[]
}

export interface ApplicationIconInterface {
    id: number
    icon: ImageFile
    updateDate: Date
}

@Injectable({ providedIn: 'root' })
export class ApplicationService {

    private currentApplicationSubject: BehaviorSubject<Application>
    public currentApplication: Observable<Application>

    setCurrentApplication(application: Application | null) {
        // Update the value only if we don't have any yet, or if the ID is different
        if (this.currentApplicationSubject.value == null || application == null || this.currentApplicationSubject.value.id != application.id) {
            this.currentApplicationSubject.next(application)
        }
    }

    public get currentApplicationValue(): Application {
        return this.currentApplicationSubject.value
    }

    constructor(
        private http: HttpClient,
    ) {
        this.currentApplicationSubject = new BehaviorSubject<Application>(null)
        this.currentApplication = this.currentApplicationSubject.asObservable()
    }

    createApplication(name: string, platform: Platform): Promise<Object> {
        let requestBody = {
            name: name,
            platform: platform
        }

        return this.http.post(`${AppConfig.apiBaseUrl}/applications`, requestBody).toPromise()
    }

    getWorkspaceApplications(): Promise<CollectionResponse<Application>> {
        return this.http.get<CollectionResponse<Application>>(`${AppConfig.apiBaseUrl}/applications`).toPromise()
    }

    getWorkspaceApplicationsWithSlug(applicationSlug: string): Promise<ElementResponse<Application>> {
        return this.http.get<ElementResponse<Application>>(`${AppConfig.apiBaseUrl}/applications?slug=${applicationSlug}`).toPromise()
    }

    getApplicationIconBlob(application: ApplicationIconInterface): Promise<Blob | null> {
        let cacheKey = `${application.id}_${moment(application.updateDate).unix()}`

        return new Promise((resolve, reject) => {
            // If application doesn't have an icon, nothing needs to be done
            if (application.icon == null) {
                resolve(null)
                return
            }

            // Check if there is already cached icon
            this.getIconFromIndexedDB(cacheKey).then((cachedIcon) => {
                if (cachedIcon) {
                    resolve(cachedIcon)
                    return
                }

                // Fetch new icon and update the cache
                this.http.get(application.icon.url, { observe: 'response', responseType: 'blob' }).toPromise().then((response) => {
                    let iconBlob = response.body
                    this.saveIconToIndexedDB(iconBlob, cacheKey).then(() => {
                        resolve(iconBlob)
                    })

                }).catch(error => {
                    resolve(null)
                })
            }).catch(error => {
                resolve(null)
            })
        })
    }

    deleteApplication(application: Application): Promise<Object> {
        return this.http.delete(`${AppConfig.apiBaseUrl}/applications/${application.id}`).toPromise()
    }

    updateApplication(
        application: Application,
        name: string,
        applicationIcon: File | null,
        testsSuccessThreshold: number,
        testPlansAffectingStatus: string[],
        targetLaunchDuration: number
    ): Promise<ElementResponse<Application>> {
        const formData = new FormData()
        formData.append('name', name)
        formData.append('testsSuccessThreshold', testsSuccessThreshold.toFixed(2))
        formData.append('testPlansAffectingStatus', testPlansAffectingStatus.join(","))
        formData.append('targetLaunchDuration', targetLaunchDuration.toFixed(3))

        if (applicationIcon != null) {
            formData.append('icon', applicationIcon)
        }

        return this.http.put<ElementResponse<Application>>(`${AppConfig.apiBaseUrl}/applications/${application.id}`, formData).toPromise()
    }

    getFilterInfos(): Promise<ApplicationFilterInfos> {
        return this.http.get<ApplicationFilterInfos>(`${AppConfig.apiBaseUrl}/applications/filterInfos`).toPromise()
    }

    private getIconFromIndexedDB(key: string): Promise<Blob | null> {
        return new Promise((resolve, reject) => {
            this.getIconsStore().then((store) => {
                const request: IDBRequest = store.get(key)

                request.onsuccess = (event: Event) => {
                    resolve((event.target as IDBRequest).result as Blob | null)
                };

                request.onerror = (event: Event) => {
                    let error = (event.target as IDBRequest).error
                    console.log(`Error retrieving data: ${error}`)
                    resolve(null)
                }
            })
        })
    }

    private saveIconToIndexedDB(iconBlob: Blob, key: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.getIconsStore().then((store) => {
                const request: IDBRequest = store.put(iconBlob, key)

                request.onsuccess = () => {
                    resolve()
                }

                request.onerror = (event: Event) => {
                    let error = (event.target as IDBRequest).error
                    console.log(`Error saving data: ${error}`)
                    resolve()
                }
            })
        })
    }

    private getIconsStore(): Promise<IDBObjectStore> {
        return new Promise((resolve, reject) => {
            this.getIconsDatabase().then((db) => {
                const transaction: IDBTransaction = db.transaction('icons', 'readwrite')
                const store: IDBObjectStore = transaction.objectStore('icons')
                // this._cachedStore = store
                resolve(store)

            }).catch(error => {
                reject(error)
            })
        })
    }

    private getIconsDatabase(): Promise<IDBDatabase> {
        return new Promise((resolve, reject) => {
            const request: IDBOpenDBRequest = indexedDB.open("AppIcons")

            request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
                const db = (event.target as IDBOpenDBRequest).result

                // Create 'icons' object store with 'id' as the key
                if (!db.objectStoreNames.contains('icons')) {
                    db.createObjectStore('icons')
                }
            }

            request.onsuccess = (event: Event) => {
                resolve((event.target as IDBOpenDBRequest).result)
            }

            request.onerror = (event: Event) => {
                let error = (event.target as IDBRequest).error
                console.log(`Error opening IndexedDB: ${error}`)
                reject(error)
            }
        })
    }

}
