import { IssueState } from "./issue.state"
import { Platform } from "./platform"

export class ErrorFilter {
    startDate: Date
    endDate: Date

    applicationId: number | null
    applicationVersionId: number | null
    bundleIdentifierId: number | null
    osVersionId: number | null
    deviceId: number | null
    errorGroupId: number | null
    issueState: IssueState | null
    platform: Platform | null
}
