import { Component, OnInit, OnDestroy, AfterViewChecked, ViewChild, ElementRef } from '@angular/core';
import { AlertService, BackendEnvironmentService, TestExecutionsService, AuthenticationService } from 'app/_services'
import { ActivatedRoute, Router } from '@angular/router';
import { TestExecution, Application, BackendEnvironment, TestExecutionFilter, Workspace, TestPlan } from 'app/_models';
import * as bootbox from 'bootbox'
import { Observable, timer, Subscription } from 'rxjs';
import { ApplicationFormatter, DateFormatter, PlatformFormatter } from 'app/_helpers';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment'

@Component({
    templateUrl: 'backend.environment.test.executions.component.html',
    styleUrls: ['backend.environment.test.executions.component.css']
})
export class BackendEnvironmentTestExecutionsComponent implements OnInit, OnDestroy, AfterViewChecked {

    workspace: Workspace
    backendEnvironment: BackendEnvironment
    testExecutions: TestExecution[]

    applications: Application[]
    testPlans: TestPlan[]

    isLoadingMoreRecords = false
    hasMoreRecords = false
    showFilters = false

    private timerObservable: Observable<number> = timer(0, 5000)
    private timerSubscription: Subscription

    @ViewChild('pageContent') pageContent: ElementRef

    private routeParamsSubscription: Subscription
    private queryParamsSubscription: Subscription
    private currentBackendEnvironmentSubscription: Subscription
    private resizeListener: any

    private oldestTestExecutionDate: Date = null

    constructor(
        public dateFormatter: DateFormatter,
        public platformFormatter: PlatformFormatter,
        private testExecutionsService: TestExecutionsService,
        private route: ActivatedRoute,
        private router: Router,
        private backendEnvironmentService: BackendEnvironmentService,
        private alertService: AlertService,
        private applicationFormatter: ApplicationFormatter,
        private titleService: Title,
        private authenticationService: AuthenticationService
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue

        this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
            this.oldestTestExecutionDate = null
            this.refreshCurrentTestExecutions(true)
            this.updateSelect2SelectionFromQuery()
        })

        this.routeParamsSubscription = this.route.params.subscribe(params => {
            this.reloadTestExecutions()
        })

        this.currentBackendEnvironmentSubscription = this.backendEnvironmentService.currentBackendEnvironment.subscribe((environment) => {
            this.backendEnvironment = environment
            if (this.backendEnvironment != null) {
                this.titleService.setTitle(`${this.backendEnvironment.name} | Test Executions`)
                this.reloadTestExecutions()
            }
        })

        this.timerSubscription = this.timerObservable.subscribe((seconds) => {
            // Ignore the initial call
            if (seconds < 1) {
                return
            }
            this.refreshCurrentTestExecutions(false)
        })

        this.resizeListener = this.loadMoreTestExecutionsIfAtBottom.bind(this)
        window.addEventListener("resize", this.resizeListener)
    }

    ngOnDestroy() {
        this.currentBackendEnvironmentSubscription?.unsubscribe()
        this.timerSubscription?.unsubscribe()
        this.routeParamsSubscription?.unsubscribe()
        this.queryParamsSubscription?.unsubscribe()
        window.removeEventListener("resize", this.resizeListener)
    }

    private _didInitSelect2 = false
    ngAfterViewChecked() {
        if (this._didInitSelect2 || !this.applications) { return }
        if ($('.select2').length > 0) {
            this.initSelect2Components()
            this.updateSelect2SelectionFromQuery()
            this._didInitSelect2 = true
        }
    }

    onPageContentScroll(event: any) {
        this.loadMoreTestExecutionsIfAtBottom()
    }

    reloadTestExecutions() {
        this.testExecutions = []
        this.oldestTestExecutionDate = null
        this.hasMoreRecords = false
        if (this.pageContent) {
            this.pageContent.nativeElement.scrollTo(0, 0)
        }

        if (this.backendEnvironment == null) {
            return
        }

        let needsFilterResponseForFetchingTestExecutions = this.makeTestExecutionFilter() == null
        if (!needsFilterResponseForFetchingTestExecutions) {
            this.refreshCurrentTestExecutions(true)
        }

        this.testExecutionsService.getEnvironmentTestExecutionFilters(this.backendEnvironment.id).then((response) => {
            this.applications = response.applications
            this.testPlans = response.testPlans
            this.showFilters = this.applications.length > 1 || this.testPlans.length > 1
            if (needsFilterResponseForFetchingTestExecutions) {
                this.refreshCurrentTestExecutions(true)
            }

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    refreshCurrentTestExecutions(force: boolean) {
        if (!this.backendEnvironment) { return }
        if (this.isLoadingMoreRecords && !force) { return }

        this.isLoadingMoreRecords = true
        let filter = this.makeTestExecutionFilter()
        filter.from = this.oldestTestExecutionDate
        this.testExecutionsService.getTestExecutions(this.backendEnvironment.workspaceId, filter).then((response) => {
            this.testExecutions = response.data

            // Update state and load more if needed
            if (this.testExecutions.length > 0) {
                this.oldestTestExecutionDate = moment(this.testExecutions[this.testExecutions.length - 1].creationDate).toDate()
                this.hasMoreRecords = response.hasMoreRecords
                setTimeout(this.loadMoreTestExecutionsIfAtBottom, 500)
            }

        }).catch((error) => {
            this.alertService.handleError(error)

        }).finally(() => {
            this.isLoadingMoreRecords = false
        })
    }

    navigateToTestExecution(testExecution: TestExecution) {
        this.router.navigate(['/', this.workspace.slug, 'apps', testExecution.applicationSlug, 'test-executions', testExecution.serialNumber], { relativeTo: this.route })
    }

    loadMoreTestExecutionsIfAtBottom() {
        if (!this.backendEnvironment || !this.hasMoreRecords || this.isLoadingMoreRecords) {
            return
        }

        let pageContent = this.pageContent.nativeElement
        if (pageContent.offsetHeight + pageContent.scrollTop < pageContent.scrollHeight) {
            return
        }

        this.isLoadingMoreRecords = true
        let filter = this.makeTestExecutionFilter()
        filter.pageBefore = this.oldestTestExecutionDate
        this.testExecutionsService.getTestExecutions(this.backendEnvironment.workspaceId, filter).then((response) => {
            this.testExecutions = this.testExecutions.concat(response.data)
            this.oldestTestExecutionDate = moment(this.testExecutions[this.testExecutions.length - 1].creationDate).toDate()
            this.hasMoreRecords = response.hasMoreRecords
            this.loadMoreTestExecutionsIfAtBottom()

        }).catch((error) => {
            this.alertService.handleError(error)

        }).finally(() => {
            this.isLoadingMoreRecords = false
        })
    }

    testExecutionShouldDelete(testExecution: TestExecution) {
        let weakThis = this

        bootbox.dialog({
            title: `Delete Test Execution #${testExecution.serialNumber}?`,
            message: "The Test Execution will be permanently deleted. This action is not reversible.",
            size: 'small',
            buttons: {
                cancel: { label: 'Cancel', className: 'btn-link' },
                delete: { label: 'Delete', className: 'btn-danger', callback: function() {
                    weakThis.deleteTestExecution(testExecution)
                }}
            }
        })
    }

    private makeTestExecutionFilter(): TestExecutionFilter | null {
        let query = this.route.snapshot.queryParams

        let networkFilter = new TestExecutionFilter()
        networkFilter.backendEnvironmentId = this.backendEnvironment.id

        if (query['app']) {
            networkFilter.applicationId = query['app']
        }

        if (query['testPlan']) {
            if (this.testPlans) {
                networkFilter.testPlanId = this.testPlans.find((tp) => { return tp.name == query['testPlan'] }).id
            } else {
                return null
            }
        }

        return networkFilter
    }

    private deleteTestExecution(testExecution: TestExecution) {
        this.testExecutionsService.deleteTestExecutionWithId(testExecution.id).then((response) => {
            this.refreshCurrentTestExecutions(true)

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private initSelect2Components() {
        $('#applicationSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '180px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#testPlanSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '180px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678

        // Fix for select2 being expanded after clicking on clear button
        $('.select2').on("select2:unselecting", (event) => {
            $(event.target).val(null).trigger('change')
            event.preventDefault()
        })

        // Applications
        $('#applicationSelect').on('select2:select', (event) => {
            let application = this.applications.find((o) => { return `${o.name} ${this.platformFormatter.platformName(o.platform)}` == event.params.data.id })
            this.router.navigate([], { queryParams: { app: application.id }, queryParamsHandling: 'merge' })
        })
        $('#applicationSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { app: null }, queryParamsHandling: 'merge' })
        })

        // Test plans
        $('#testPlanSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { testPlan: event.params.data.text }, queryParamsHandling: 'merge' })
        })
        $('#testPlanSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { testPlan: null }, queryParamsHandling: 'merge' })
        })
    }

    private updateSelect2SelectionFromQuery() {
        if (!this.applications) { return }

        let query = this.route.snapshot.queryParams

        if (query['app']) {
            let application = this.applications.find((a) => { return a.id == query['app'] })
            if (application) { $('#applicationSelect').val(application.name + ' ' + this.platformFormatter.platformName(application.platform)).trigger('change') }
        } else {
            $('#applicationSelect').val(null).trigger('change')
        }

        $('#testPlanSelect').val(query['testPlan']).trigger('change')
    }

}
