export class Utils {

    static getChangedParamNames(newParams: any, oldParams: any): string[] {
        var changedParamNames = []

        // Loop through unique keys of both old and new params in order to catch param removals too
        const uniqueKeys = Array.from(new Set([...Object.keys(oldParams), ...Object.keys(newParams)]));

        for (const key of uniqueKeys) {
            if (newParams[key] != oldParams[key]) {
                changedParamNames.push(key)
            }
        }

        return changedParamNames
    }

}
