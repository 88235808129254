import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { AlertService, AuthenticationService } from 'app/_services'
import { Workspace, Application, Role } from 'app/_models';
import { ApplicationService } from 'app/_services';
import { PlatformFormatter, DateFormatter, ApplicationFormatter } from 'app/_helpers';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TagModel } from 'ngx-chips/core/accessor';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as bootbox from 'bootbox'

@Component({
    templateUrl: 'application.settings.component.html'
})
export class ApplicationSettingsComponent implements OnInit, OnDestroy {

    workspace: Workspace
    application: Application
    canEditApplication = false

    form: FormGroup
    selectedFile: File

    @ViewChild("fileUpload") fileUpload: ElementRef

    public availableTestPlanNames: string[] = []
    private currentApplicationSubscription: Subscription

    constructor(
        public platformFormatter: PlatformFormatter,
        public dateFormatter: DateFormatter,
        public applicationFormatter: ApplicationFormatter,
        private applicationService: ApplicationService,
        private router: Router,
        private alertService: AlertService,
        private authenticationService: AuthenticationService,
        private formBuilder: FormBuilder,
        private titleService: Title,
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue
        this.canEditApplication = this.workspace.role >= Role.Admin

        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
            if (this.application != null) {
                this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | Network`)
                this.reloadForm()
            }
        })

        this.applicationService.getFilterInfos().then((response) => {
            this.availableTestPlanNames = response.testPlans.map((testPlan) => { return testPlan.name })
            this.reloadForm()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    ngOnDestroy() {
        this.currentApplicationSubscription?.unsubscribe()
    }

    onBrowseApplicationIconClick() {
        const fileUpload = this.fileUpload.nativeElement
        fileUpload.onchange = () => {
            if (fileUpload.files.length <= 0) {
                return
            }

            this.selectedFile = fileUpload.files[0]
        }
        fileUpload.click()
    }

    copyApplicationAliasToClipboard() {
        navigator.clipboard.writeText(this.form.controls.applicationAlias.value)
    }

    copyAppSecretToClipboard() {
        navigator.clipboard.writeText(this.application.token)
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls }

    saveApplication() {
        let f = this.form.controls
        let testsSuccessThreshold = parseFloat(f.testsSuccessThreshold.value.replace("%", "")) / 100
        let targetLaunchDuration = parseFloat(f.targetLaunchDuration.value.replace("s", ""))
        let testPlanNames = (f.testPlanNames.value as TagModel[]).map((tagModel) => { return tagModel['value'] })

        this.applicationService.updateApplication(
            this.application,
            f.applicationName.value,
            this.selectedFile,
            testsSuccessThreshold,
            testPlanNames,
            targetLaunchDuration
        ).then((response) => {
            this.application = response.data
            this.reloadForm()
            this.alertService.success("Settings successfully updated")

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    onDeleteApplicationClick() {
        let weakThis = this

        bootbox.dialog({
            title: `Delete ${this.application.name}?`,
            message: "The application will be permanently deleted including all associated data. This action is not reversible.",
            size: 'small',
            buttons: {
                cancel: { label: 'Cancel', className: 'btn-link' },
                delete: {
                    label: 'Delete Application', className: 'btn-danger', callback: function () {
                        weakThis.deleteApplication()
                    }
                }
            }
        })
    }

    deleteApplication() {
        this.applicationService.deleteApplication(this.application).then((response) => {
            return this.authenticationService.refreshCurrentWorkspace()

        }).then((response) => {
            this.router.navigate(['/'])

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private reloadForm() {
        if (!this.application) { return }

        this.form = this.formBuilder.group({
            applicationName: new FormControl(
                {
                    value: this.application.name,
                    disabled: false
                },
                Validators.required
            ),
            applicationAlias: new FormControl(
                {
                    value: this.application.alias,
                    disabled: true
                },
                Validators.required
            ),
            testsSuccessThreshold: new FormControl(
                {
                    value: (this.application.testsSuccessThreshold * 100).toFixed(1) + "%",
                    disabled: !this.canEditApplication
                },
                Validators.compose([Validators.required, Validators.minLength(1), Validators.pattern(/^\d{1,3}(\.\d)?%?%?$/)])
            ),
            targetLaunchDuration: new FormControl(
                {
                    value: this.application.targetLaunchDuration.toFixed(1) + "s",
                    disabled: !this.canEditApplication
                },
                Validators.compose([
                    Validators.required,
                    Validators.minLength(1),
                    Validators.pattern(/^\d{1,3}(\.\d{1,3})?s?$/)])
            ),
            testPlanNames: new FormControl(
                {
                    value: this.application.testPlansAffectingStatus.map(this.tagModelFromString),
                    disabled: !this.canEditApplication
                },
                null
            )
        })
    }

    private tagModelFromString(value: string): TagModel {
        return { value: value, display: value }
    }

}
