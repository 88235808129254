import { Component, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'app/_services';
import { SubscriptionType, Workspace } from 'app/_models';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { DateFormatter } from 'app/_helpers';

@Component({
    selector: 'subscription-notice',
    templateUrl: 'subscription.notice.html'
})
export class SubscriptionNotice implements OnDestroy {

    workspace: Workspace
    subscriptionNoticeText: String = null

    private currentWorkspaceSubscription: Subscription

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private dateFormatter: DateFormatter,
    ) {
        this.currentWorkspaceSubscription = this.authenticationService.currentWorkspace.subscribe(workspace => {
            this.workspace = workspace
            this.updateSubscriptionNoticeText()
        })
    }

    ngOnDestroy() {
        this.currentWorkspaceSubscription?.unsubscribe()
    }

    navigateToSubscriptionSettings() {
        this.router.navigate(['/settings/subscription'])
    }

    private updateSubscriptionNoticeText() {
        if (this.workspace == null) {
            return
        }

        let subscription = this.workspace.subscription
        let daysTillExpiration = moment(subscription.notValidAfter).diff(moment.now(), 'days')
        let relativeExpirationDate = this.dateFormatter.timeAgo(new Date(subscription.notValidAfter))

        // FREE subscription means that trial has ended
        if (subscription.type == SubscriptionType.Free) {
            this.subscriptionNoticeText = "Your trial has ended. See Plans."
            return
        }

        // PRO_TRIAL subscription
        if (subscription.type == SubscriptionType.ProTrial && daysTillExpiration < 30) {
            this.subscriptionNoticeText = `Your trial expires ${relativeExpirationDate}. See Plans.`
            return
        }

        // BASIC or PRO subscription - warn only if the renewal is disabled
        if (!subscription.automaticRenewalEnabled && daysTillExpiration < 30) {
            this.subscriptionNoticeText = `Subscription expires ${relativeExpirationDate}. See Plans.`
            return
        }

        // If all good, don't display the text
        this.subscriptionNoticeText = null
    }

}
