import { BackendEnvironment } from 'app/_models';
import { BackendEnvironmentService } from 'app/_services';
import { Component, OnDestroy, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
    templateUrl: 'backend.environment.network.component.html'
})
export class BackendEnvironmentNetworkComponent implements OnInit, OnDestroy {

    backendEnvironment: BackendEnvironment

    private currentBackendEnvironmentSubscription: Subscription

    constructor(
        private backendEnvironmentService: BackendEnvironmentService,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.currentBackendEnvironmentSubscription = this.backendEnvironmentService.currentBackendEnvironment.subscribe((environment) => {
            this.backendEnvironment = environment
            if (environment != null) {
                this.titleService.setTitle(`${environment.name} | Network`)
            }
        })
    }

    ngOnDestroy() {
        this.currentBackendEnvironmentSubscription?.unsubscribe()
    }

}
