import { Component, OnInit, OnDestroy } from '@angular/core';
import { Application } from 'app/_models';
import { ApplicationService } from 'app/_services';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: 'application.crash.group.component.html',
})
export class ApplicationCrashGroupComponent implements OnInit, OnDestroy {

    application: Application

    private currentApplicationSubscription: Subscription

    constructor(
        private applicationService: ApplicationService,
    ) {
    }

    ngOnInit() {
        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
        })
    }

    ngOnDestroy() {
        this.currentApplicationSubscription?.unsubscribe()
    }

}
