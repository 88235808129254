export enum ErrorGroupDimension {
    ErrorReports = 'ERROR_REPORTS',
    Applications = 'APPLICATIONS',
}

export class ErrorGroupDimensionQueryParser {

    static fromString(dimension: any): ErrorGroupDimension | null {
        if (dimension == null) {
            return null
        }

        // Make sure that dimension is a valid ErrorGroupDimension
        if (Object.values(ErrorGroupDimension).includes(dimension)) {
            return dimension as ErrorGroupDimension
        } else {
            return null
        }
    }

}
