<div class="d-flex">
    <div class="sidebar sidebar-light sidebar-secondary sidebar-expand-md">

        <div #errorReportsList class="reports-content-wrapper">
            <ul class="media-list media-list-linked">
                <div *ngFor="let errorReport of errorReports">
                    <li [class.selected]="errorReport.serialNumber == selectedErrorReportSerialNumber">
                        <a [routerLink]="[errorReport.serialNumber]" class="media">
                            <error-report-list-item [errorReport]="errorReport"></error-report-list-item>
                        </a>
                    </li>
                </div>
            </ul>
        </div>
    </div>

    <div class="content-wrapper">
        <div class="w-100 h-100 text-center d-flex justify-content-center align-items-center" *ngIf="errorReports && selectedErrorReportSerialNumber == null">
            <h4 class="text-muted m-0">No Error Report Selected</h4>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>
