import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AlertService, ErrorsService, AuthenticationService, ApplicationService } from 'app/_services'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ErrorReport, ErrorGroup, ErrorFilter, Workspace, Application } from 'app/_models';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment'

@Component({
    selector: 'error-reports-list',
    templateUrl: 'error.reports.list.component.html',
    styleUrls: [
        'error.reports.list.component.css'
    ]
})
export class ErrorReportsListComponent implements OnInit, OnDestroy {

    workspace: Workspace
    application: Application

    private _errorGroup: ErrorGroup
    set errorGroup(errorGroup: ErrorGroup) {
        this._errorGroup = errorGroup
        this.errorsService.setCurrentErrorGroup(errorGroup)
    }
    get errorGroup() { return this._errorGroup }

    errorReports: ErrorReport[]
    selectedErrorReportSerialNumber: number | null

    @ViewChild('errorReportsList') errorReportsList: ElementRef

    private navigationChangeSubscription: Subscription
    private currentApplicationSubscription: Subscription

    constructor(
        private errorsService: ErrorsService,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private router: Router,
        private authenticationService: AuthenticationService,
        private applicationService: ApplicationService,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue
        this.reloadErrorReports()

        this.navigationChangeSubscription = this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(_ => {
            this.updateSelection()
        })

        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
        })
    }

    ngOnDestroy() {
        // Resets the currentErrorGroup in ErrorsService
        this.errorGroup = null
        this.navigationChangeSubscription?.unsubscribe()
        this.currentApplicationSubscription?.unsubscribe()
    }

    private updateSelection() {
        if (this.errorReports == null || this.route.firstChild == null) {
            this.selectedErrorReportSerialNumber = null
            return
        }

        this.selectedErrorReportSerialNumber = this.route.firstChild.snapshot.params['errorReportSlug']
    }

    private reloadErrorReports() {
        if (!this.workspace) { return }

        let errorGroupSlug = this.route.snapshot.params['errorGroupSlug']
        this.errorsService.getWorkspaceErrorGroupWithSlug(this.workspace.id, errorGroupSlug).then((response) => {
            this.errorGroup = response.data

            let filter = this.makeCurrentErrorFilter()
            this.errorsService.getErrorGroupErrorReports(this.errorGroup.id, filter).then((response) => {
                this.errorReports = response.data
                this.updateSelection()

            }).catch((error) => {
                this.alertService.handleError(error)

            })

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }


    private makeCurrentErrorFilter(): ErrorFilter {
        let errorFilter = new ErrorFilter()
        errorFilter.endDate = new Date()
        errorFilter.startDate = moment(errorFilter.endDate).subtract(1, 'year').toDate();
        errorFilter.errorGroupId = this.errorGroup.id
        if (this.application) {
            errorFilter.applicationId = this.application.id
        }

        return errorFilter
    }

}
