import { Injectable } from "@angular/core"
import { Platform } from "../_models/platform"

@Injectable()
export class PlatformFormatter {

    platformName(platform: Platform): string {
        // Safe handling both number and string values
        switch (parseInt(platform.toString()) as Platform) {
            case Platform.Ios: return "iOS"
            case Platform.Android: return "Android"
            case Platform.Tvos: return "tvOS"
            case Platform.Ipados: return "iPadOS"
            default:
                return ""
        }
    }

    platformIconName(platform: Platform): string {
        // Safe handling both number and string values
        switch (parseInt(platform.toString()) as Platform) {
            case Platform.Ios: return "icon-apple2"
            case Platform.Android: return "icon-android"
            case Platform.Tvos: return "icon-apple2"
            case Platform.Ipados: return "icon-apple2"
            default:
                return ""
        }
    }

    availablePlatforms(): Platform[] {
        return [Platform.Android, Platform.Ios, Platform.Ipados, Platform.Tvos]
    }

    platformFromDisplayName(displayName: string): Platform | null {
        if (displayName === null || displayName === undefined) {
            return null
        }

        for (let platform of this.availablePlatforms()) {
            if (this.platformName(platform) == displayName) { return platform }
        }

        console.warn("Unknown Platform: " + displayName);
        return null
    }

}
