import { Routes } from '@angular/router';
import { NoContentComponent } from './no-content';
import { BugReportComponent, BugReportsComponent } from './bug.reports';
import { LoginComponent } from './login';
import { AccountPendingActivationComponent } from './account.pending.activation';
import { RegisterComponent } from './register';
import { AuthGuard } from './_guards';
import { AcceptInvitationComponent } from './accept.invitation';
import { SecuritySettingsComponent } from './settings/security.settings.component';
import { ApplicationsSettingsComponent } from './settings/applications.settings.component/applications.settings.component';
import { PeopleSettingsComponent } from './settings/people.settings.component/people.settings.component';
import { IntegrationsComponent } from './settings/integrations.component/integrations.component';
import { SlackRedirectComponent } from './redirects/slack.redirect.component';
import { TestExecutionsComponent } from './test.executions/test.executions.component';
import { TestExecutionComponent } from './test.executions/test.execution.component';
import { TestRunComponent } from './test.executions/test.run.component';
import { WorkspaceResolverComponent } from './workspace.resolver';
import { BuildsComponent } from './builds/builds.component';
import { BuildComponent } from './builds/build.component';
import { ApplicationInstallComponent } from './application.install/application.install.component';
import { WorkspaceDashboardComponent } from './workspace.dashboard/workspace.dashboard.component';
import { HomeComponent } from './home/home.component';
import { AccountActivityComponent } from './settings/account.activity.component/account.activity.component';
import { ApplicationDashboardComponent } from './application/dashboard/application.dashboard.component';
import { ApiSettingsComponent } from './settings/api.settings.component/api.settings.component';
import { BuildInstallComponent } from './application.install/build.install.component';
import { TestCaseComponent } from './test.executions/test.case.component';
import { ApplicationComponent } from './application';
import { TestCasesComponent } from './test.executions/test.cases.component';
import { WorkspaceSettingsComponent } from './settings/workspace.settings.component/workspace.settings.component';
import { SubscriptionSettingsComponent } from './settings/subscription.settings.component/subscription.settings.component';
import { PasswordRecoveryComponent } from './password.recovery/password.recovery.component';
import { InvoiceComponent } from './settings/invoice.component/invoice.component';
import { TestFailureGroupsComponent } from './test.executions/test.failure.groups.component';
import { TestFailureGroupComponent } from './test.executions/test.failure.group.component';
import { AppLaunchComponent } from './app.launch/app.launch.component';
import { AppNetworkComponent } from './app.network.component/app.network.component';
import { BackendEnvironmentComponent } from './environment/backend.environment.component';
import { BackendEnvironmentNetworkComponent } from './environment/backend.environment.network.component';
import { BackendEnvironmentTestExecutionsComponent } from './environment/backend.environment.test.executions.component';
import { BackendEnvironmentSettingsComponent } from './environment/backend.environment.settings.component';
import { ApplicationSettingsComponent } from './application/application.settings.component';
import { CrashReportsComponent } from './crash.reports/crash.reports.list.component/crash.reports.list.component';
import { CrashReportComponent } from './crash.reports/crash.report.component/crash.report.component';
import { ApplicationErrorGroupComponent } from './error.reports/application.error.group.component/application.error.group.component';
import { ErrorReportsListComponent } from './error.reports/error.reports.list.component/error.reports.list.component';
import { ErrorReportComponent } from './error.reports/error.report.component/error.report.component';
import { DistributionGroupsComponent } from './distribution.groups/distribution.groups.component/distribution.groups.component';
import { DistributionGroupComponent } from './distribution.groups/distribution.group.component/distribution.group.component';
import { ApplicationDistributionsComponent } from './application.install/application.distributions.component';
import { WorkspaceCrashesComponent } from './crash.reports/workspace.crashes.component/workspace.crashes.component';
import { WorkspaceErrorsComponent } from './error.reports/workspace.errors.component/workspace.errors.component';
import { WorkspaceErrorGroupComponent } from './error.reports/workspace.error.group.component/workspace.error.group.component';
import { ApplicationErrorsComponent } from './error.reports/application.errors.component/application.errors.component';
import { WorkspaceErrorReportsListComponent } from './error.reports/workspace.error.reports.list.component/workspace.error.reports.list.component';
import { ApplicationCrashesComponent } from './crash.reports/application.crashes.component/application.crashes.component';
import { ApplicationCrashGroupComponent } from './crash.reports/application.crash.group.component/application.crash.group.component';
import { WorkspaceCrashGroupComponent } from './crash.reports/workspace.crash.group.component/workspace.crash.group.component';
import { WorkspaceCrashReportsListComponent } from './crash.reports/workspace.crash.reports.list.component/workspace.crash.reports.list.component';
import { WorkspaceNetworkComponent } from './network/workspace.network.component/workspace.network.component';

export const ROUTES: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    // App distribution pages
    { path: ':workspaceSlug/app-distributions', component: ApplicationDistributionsComponent, pathMatch: 'full' },
    { path: ':workspaceSlug/app-distributions/:applicationSlug', component: ApplicationInstallComponent, pathMatch: 'full' },
    { path: ':workspaceSlug/app-distributions/:applicationSlug/builds/:buildSlug', component: BuildInstallComponent, pathMatch: 'full' },

    // Workspace Crashes
    { path: ':workspaceSlug/crashes', component: WorkspaceCrashesComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: ':workspaceSlug/crashes/:crashGroupSlug', component: WorkspaceCrashGroupComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    {
        path: ':workspaceSlug/crashes/:crashGroupSlug/crash-reports', component: WorkspaceCrashReportsListComponent,
        children: [
            { path: ':crashReportSlug', component: CrashReportComponent }
        ]
    },

    // Workspace Errors
    { path: ':workspaceSlug/errors', component: WorkspaceErrorsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: ':workspaceSlug/errors/:errorGroupSlug', component: WorkspaceErrorGroupComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    {
        path: ':workspaceSlug/errors/:errorGroupSlug/error-reports', component: WorkspaceErrorReportsListComponent,
        children: [
            { path: ':errorReportSlug', component: ErrorReportComponent }
        ]
    },

    // Workspace Network
    { path: ':workspaceSlug/network', component: WorkspaceNetworkComponent, canActivate: [AuthGuard], pathMatch: 'full' },

    // Application
    { path: ':workspaceSlug/apps/:applicationSlug', component: ApplicationComponent, canActivate: [AuthGuard],
        children: [
            // Dashboard
            { path: '', component: ApplicationDashboardComponent, canActivate: [AuthGuard], pathMatch: 'full' },

            // Bug reports
            { path: 'bugs', component: BugReportsComponent,
                children: [
                    { path: ':bugReportSlug', component: BugReportComponent }
                ]
            },

            // Crashes
            { path: 'crash-groups', component: ApplicationCrashesComponent, canActivate: [AuthGuard], pathMatch: 'full' },
            { path: 'crash-groups/:crashGroupSlug', component: ApplicationCrashGroupComponent, canActivate: [AuthGuard], pathMatch: 'full' },

            // Distributions groups
            { path: 'distribution-groups', component: DistributionGroupsComponent,
                children: [
                    { path: ':distributionGroupSlug', component: DistributionGroupComponent }
                ]
            },

            // Crash reports
            {
                path: 'crash-groups/:crashGroupSlug/crash-reports', component: CrashReportsComponent,
                children: [
                    { path: ':crashReportSlug', component: CrashReportComponent }
                ]
            },

            // Errors
            { path: 'error-groups', component: ApplicationErrorsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
            { path: 'error-groups/:errorGroupSlug', component: ApplicationErrorGroupComponent, canActivate: [AuthGuard], pathMatch: 'full' },

            // Error reports
            {
                path: 'error-groups/:errorGroupSlug/error-reports', component: ErrorReportsListComponent,
                children: [
                    { path: ':errorReportSlug', component: ErrorReportComponent }
                ]
            },

            // Test failure groups
            { path: 'test-failure-groups', component: TestFailureGroupsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
            { path: 'test-failure-groups/:testFailureGroupSlug', component: TestFailureGroupComponent, canActivate: [AuthGuard] },

            // Test cases
            { path: 'test-cases', component: TestCasesComponent, canActivate: [AuthGuard], pathMatch: 'full' },
            { path: 'test-cases/:testCaseSlug', component: TestCaseComponent, canActivate: [AuthGuard] },

            // Test executions
            { path: 'test-executions', component: TestExecutionsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
            { path: 'test-executions/:testExecutionSlug', component: TestExecutionComponent, canActivate: [AuthGuard] },

            // Test runs
            { path: 'test-executions/:testExecutionSlug/runs/:testRunSlug', component: TestRunComponent, canActivate: [AuthGuard] },

            // Network
            { path: 'network', component: AppNetworkComponent, canActivate: [AuthGuard], pathMatch: 'full' },

            // App launch
            { path: 'app-launch', component: AppLaunchComponent, canActivate: [AuthGuard], pathMatch: 'full' },

            // Builds
            { path: 'builds', component: BuildsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
            { path: 'builds/:buildSlug', component: BuildComponent, canActivate: [AuthGuard] },

            // Settings
            { path: 'settings', component: ApplicationSettingsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
        ],
    },

    // Environment
    {
        path: ':workspaceSlug/envs/:backendEnvironmentSlug', component: BackendEnvironmentComponent, canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: 'network', pathMatch: 'full' },

            // Network
            { path: 'network', component: BackendEnvironmentNetworkComponent, canActivate: [AuthGuard], pathMatch: 'full' },

            // Test executions
            { path: 'test-executions', component: BackendEnvironmentTestExecutionsComponent, canActivate: [AuthGuard], pathMatch: 'full' },

            // Settings
            { path: 'settings', component: BackendEnvironmentSettingsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
        ]
    },

    // Workspace resolver
    { path: ':workspaceSlug/resolve', component: WorkspaceResolverComponent, canActivate: [AuthGuard] },

    // Settings
    { path: 'settings', redirectTo: 'settings/workspace', pathMatch: 'full' },
    { path: 'settings/applications', component: ApplicationsSettingsComponent, canActivate: [AuthGuard] },
    { path: 'settings/workspace', component: WorkspaceSettingsComponent, canActivate: [AuthGuard] },
    { path: 'settings/subscription', component: SubscriptionSettingsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'settings/subscription/invoices/:invoiceSlug', component: InvoiceComponent, canActivate: [AuthGuard] },
    { path: 'settings/team', component: PeopleSettingsComponent, canActivate: [AuthGuard] },
    { path: 'settings/security', component: SecuritySettingsComponent, canActivate: [AuthGuard] },
    { path: 'settings/account-activity', component: AccountActivityComponent, canActivate: [AuthGuard] },
    { path: 'settings/integrations', component: IntegrationsComponent, canActivate: [AuthGuard] },
    { path: 'settings/api', component: ApiSettingsComponent, canActivate: [AuthGuard] },

    { path: 'redirects/slack', component: SlackRedirectComponent, canActivate: [AuthGuard] },

    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    // { path: 'demo', component: DemoComponent },
    { path: 'accept_invitation', component: AcceptInvitationComponent },
    { path: 'pending_activation', component: AccountPendingActivationComponent },
    { path: 'password_recovery', component: PasswordRecoveryComponent },

    { path: ':workspaceSlug', component: WorkspaceDashboardComponent, canActivate: [AuthGuard] },

    // otherwise redirect to home
    { path: '**', component: NoContentComponent },
]
