<ng-container *ngIf="workspace && crashFiltersResponse">

    <div class="card-body pb-0" *ngIf="missingSymbols != null && missingSymbols.length > 0">
        <div class="alert alert-dismissible alert-warning">
            <span>Some debug symbols are missing. <a href="javascript:void(0)" class="alert-link" (click)="showUploadSymbolsModal()" data-target="#uploadSymbolsModal" data-toggle="modal">View details</a></span>
            <button type="button" (click)="dismissMissingSymbolsAlert()" class="close"><span>×</span></button>
        </div>
    </div>

    <!-- FILTERS -->
    <div class="card-body d-flex align-items-center align-items-baseline">
        <div class="d-flex align-items-baseline">
            <h5 class="mb-0 pb-0 mr-2">Filters</h5>

            <div class="form-group mb-0 d-flex mr-2" *ngIf="crashFiltersResponse.applications.length > 1">
                <select id="applicationSelect" select2 data-placeholder="App" data-allow-clear="true" class="form-control select2">
                    <option *ngFor="let application of crashFiltersResponse.applications">{{application.name}} {{platformFormatter.platformName(application.platform)}}</option>
                </select>
            </div>

            <div class="form-group mb-0 d-flex mr-2" *ngIf="crashFiltersResponse.bundleIdentifiers.length > 0">
                <select id="bundleIdentifierSelect" select2 data-placeholder="App identifier" data-allow-clear="true" class="form-control select2">
                    <option *ngFor="let bundleIdentifier of crashFiltersResponse.bundleIdentifiers">{{bundleIdentifier.value}}</option>
                </select>
            </div>

            <div class="form-group mb-0 d-flex mr-2" *ngIf="crashFiltersResponse.platforms.length > 1">
                <select id="platformSelect" select2 data-placeholder="Platform" data-allow-clear="true" class="form-control select2">
                    <option *ngFor="let platform of crashFiltersResponse.platforms">{{platformFormatter.platformName(platform)}}</option>
                </select>
            </div>

            <div class="form-group mb-0 d-flex mr-2" *ngIf="crashFiltersResponse.applicationVersions.length > 0">
                <select id="applicationVersionSelect" select2 data-placeholder="App version" data-allow-clear="true" class="form-control select2">
                    <option *ngFor="let applicationVersion of crashFiltersResponse.applicationVersions">{{applicationVersion.bundleShortVersion}}</option>
                </select>
            </div>

            <div class="form-group mb-0 d-flex mr-2" *ngIf="crashFiltersResponse.osVersions.length > 0">
                <select id="osVersionSelect" select2 data-placeholder="OS version" data-allow-clear="true" class="form-control select2">
                    <option *ngFor="let osVersion of crashFiltersResponse.osVersions">{{osVersion.version}}</option>
                </select>
            </div>

            <div class="form-group mb-0 d-flex mr-2">
                <select id="issueStateSelect" select2 data-placeholder="Status" data-allow-clear="true" class="form-control select2">
                    <option>{{issueStateFormatter.displayName(IssueState.Open)}}</option>
                    <option>{{issueStateFormatter.displayName(IssueState.Ignored)}}</option>
                    <option>{{issueStateFormatter.displayName(IssueState.Closed)}}</option>
                </select>
            </div>
        </div>

        <ul class="ml-auto pagination">
            <li class="page-item active">
                <date-range [dateRange]="this.selectedDateRange" (onDateRangeChange)="onDateRangeChanged($event)"></date-range>
            </li>
        </ul>
    </div>

    <!-- TRENDS -->
    <div class="card-body d-flex py-0 my-0">

        <!-- TRENDS LOADING -->
        <div class="justify-content-center text-muted d-flex w-100 align-items-center" style="min-height: 280px" *ngIf="!crashTrendsResponse">
            <div class="align-self-center">
                <i class="icon-spinner4 spinner mr-2"></i> Loading crash trends...
            </div>
        </div>

        <!-- TRENDS LOADED -->
        <div class="w-100" style="margin-top: 20px; margin-bottom: 20px;" *ngIf="crashTrendsResponse">
            <crashes-over-time-chart [datePoints]="crashTrendsResponse.datePoints" [crashCounts]="crashTrendsResponse.crashCounts" (onDateRangeChange)="onDateRangeChanged($event)"></crashes-over-time-chart>
        </div>

        <!-- SUMMARY LOADING -->
        <div class="justify-content-center text-muted d-flex align-items-center pl-3" *ngIf="!crashTrendsSummaryResponse">
            <div class="align-self-center" style="min-width: 180px;">
                <i class="icon-spinner4 spinner mr-1"></i> Loading summary...
            </div>
        </div>

        <!-- SUMMARY LOADED -->
        <div class="border-left-light pl-3" style="padding-top: 20px;" *ngIf="crashTrendsSummaryResponse">
            <h5 class="mb-0 pb-3">Summary</h5>
            <div style="width: 180px;">
                <crash-trends-summary [crashTrendsSummaryResponse]="crashTrendsSummaryResponse"></crash-trends-summary>
            </div>
        </div>
    </div>

    <!-- DIMENSIONS -->
    <div class="card-body d-flex align-items-baseline" *ngIf="crashFiltersResponse.applications.length > 1">
        <h5 class="mb-0 pb-0">Dimensions</h5>
        <ul class="nav nav-pills nav-pills-bordered ml-2 my-0">
            <li class="nav-item ml-1">
                <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : crashDimension == CrashDimension.CrashGroups }" (click)="setCrashDimension(CrashDimension.CrashGroups)">Crash groups</a>
            </li>

            <li class="nav-item ml-1" *ngIf="crashFiltersResponse.applications.length > 1">
                <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : crashDimension == CrashDimension.Applications }" (click)="setCrashDimension(CrashDimension.Applications)" style="border-top-right-radius: 0; border-bottom-right-radius: 0;">Applications</a>
            </li>

            <li class="nav-item ml-1" *ngIf="crashFiltersResponse.platforms.length > 1">
                <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : crashDimension == CrashDimension.Platforms }" (click)="setCrashDimension(CrashDimension.Platforms)">Platforms</a>
            </li>
        </ul>
    </div>

    <!-- SEGMENTS -->

    <!-- CRASH GROUP SEGMENTS -->
    <ng-container *ngIf="crashDimension == CrashDimension.CrashGroups">
        <table class="table" *ngIf="crashGroupSegments && crashGroupSegments.length > 0">
            <thead>
                <tr role="row">
                    <th class="border-0">#</th>
                    <th class="border-bottom-0">Crash group</th>
                    <th class="border-bottom-0 nowrap text-right">
                        <sortable [name]="'Crashes'" [ascValue]="CrashSegmentsOrder.COUNT_ASC" [descValue]="CrashSegmentsOrder.COUNT_DESC" [default]="CrashSegmentsOrder.COUNT_DESC"></sortable>
                    </th>
                    <th class="border-bottom-0 nowrap text-right">
                        <sortable [name]="'Users'" [ascValue]="CrashSegmentsOrder.AFFECTED_USERS_ASC" [descValue]="CrashSegmentsOrder.AFFECTED_USERS_DESC"></sortable>
                    </th>
                    <th class="border-bottom-0 nowrap text-center">App versions</th>
                    <th class="border-bottom-0 nowrap text-center">First seen</th>
                    <th class="border-bottom-0 nowrap text-center">Last seen</th>
                    <th class="border-bottom-0 nowrap text-center">Annotation</th>
                    <th class="border-bottom-0 nowrap text-right">Status</th>
                </tr>
            </thead>
            <tbody>
                <tr class="hoverable" *ngFor="let crashGroupSegment of crashGroupSegments" (click)="navigateToCrashGroup(crashGroupSegment)">
                    <td>
                        <a [routerLink]="[crashGroupSegment.serialNumber]" class="button-muted mt-2 mb-2">#{{crashGroupSegment.serialNumber}}</a>
                    </td>
                    <td>
                        <div class="d-flex">
                            <div class="mr-2">
                                <span class="badge badge-mark border-pink-400"></span>
                            </div>
                            <div class="button-muted breakall">
                                <div class="align-items-baseline">
                                    <span class="font-weight-semibold breakall">{{crashGroupSegment.exceptionSymbol}}</span>
                                    <span class="text-muted ml-1">{{crashGroupSegment.sourceCodeLocation}}</span>
                                </div>
                                <div class="text-muted">
                                    <span *ngIf="crashGroupSegment.exceptionName">{{crashGroupSegment.exceptionName}}</span>
                                    <span class="breakall" *ngIf="crashGroupSegment.exceptionName && crashGroupSegment.exceptionMessage">:</span>
                                    {{crashGroupSegment.exceptionMessage}}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="text-right" [ngClass]="{ 'text-muted' : crashGroupSegment.state == 2 }">
                        <count [count]="crashGroupSegment.crashesCount"></count>
                    </td>
                    <td class="text-right" [ngClass]="{ 'text-muted' : crashGroupSegment.state == 2 }">
                        <count [count]="crashGroupSegment.distinctDeviceIds"></count>
                    </td>
                    <td class="text-center" *ngIf="crashGroupSegment.earliestCrashVersion == crashGroupSegment.latestCrashVersion">
                        <span class="badge badge-light badge-pill">{{crashGroupSegment.earliestCrashVersion}}</span>
                    </td>
                    <td class="text-center nowrap" *ngIf="crashGroupSegment.earliestCrashVersion != crashGroupSegment.latestCrashVersion">
                        <span class="badge badge-light badge-pill">{{crashGroupSegment.earliestCrashVersion}}</span>…<span class="badge badge-light badge-pill">{{crashGroupSegment.latestCrashVersion}}</span>
                    </td>
                    <td class="nowrap text-center" [ngClass]="{ 'text-muted' : crashGroupSegment.state == 2 }">{{dateFormatter.timeAgoShort(crashGroupSegment.earliestCrashDate)}}</td>
                    <td class="nowrap text-center" [ngClass]="{ 'text-muted' : crashGroupSegment.state == 2 }">{{dateFormatter.timeAgoShort(crashGroupSegment.latestCrashDate)}}</td>
                    <td class="text-center" [ngClass]="{ 'text-muted' : crashGroupSegment.state == 2 }">
                        <i class="icon-attachment" *ngIf="crashGroupSegment.hasAnnotation"></i>
                        <span class="text-muted" *ngIf="crashGroupSegment.hasAnnotation == false">-</span>
                    </td>
                    <td class="text-right" [ngClass]="{ 'text-success-600' : crashGroupSegment.state == 1, 'text-muted' : crashGroupSegment.state == 2 }">{{issueStateFormatter.displayName(crashGroupSegment.state)}}</td>
                </tr>
            </tbody>
        </table>
    </ng-container>

    <!-- APPLICATION SEGMENTS -->
    <ng-container *ngIf="crashDimension == CrashDimension.Applications">
        <table class="table" *ngIf="crashApplicationSegments && crashApplicationSegments.length > 0">
            <thead>
                <tr role="row">
                    <th class="border-bottom-0">Application</th>
                    <th class="border-bottom-0 nowrap text-right">
                        <sortable [name]="'Crashes'" [ascValue]="CrashSegmentsOrder.COUNT_ASC" [descValue]="CrashSegmentsOrder.COUNT_DESC" [default]="CrashSegmentsOrder.COUNT_DESC"></sortable>
                    </th>
                    <th class="border-bottom-0 nowrap text-right">
                        <sortable [name]="'Users'" [ascValue]="CrashSegmentsOrder.AFFECTED_USERS_ASC" [descValue]="CrashSegmentsOrder.AFFECTED_USERS_DESC"></sortable>
                    </th>
                    <th class="border-bottom-0 nowrap text-center">App versions</th>
                    <th class="border-bottom-0 nowrap text-right">First seen</th>
                    <th class="border-bottom-0 nowrap text-right">Last seen</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let crashApplicationSegment of crashApplicationSegments">
                    <td class="hover-expendable">
                        <div class="d-flex button-muted align-items-center">
                            <app-icon class="mr-2" [application]="crashApplicationSegment.application" [enableAppearanceAnimations]="false" style="width: 40px; height: 40px;;"></app-icon>
                            <div>{{crashApplicationSegment.application.name}} <span class="text-muted">{{platformFormatter.platformName(crashApplicationSegment.application.platform)}}</span></div>
                            <a [routerLink]="['./']" [queryParams]="{app: crashApplicationSegment.application.id}" queryParamsHandling="merge" class="hover-target badge badge-flat badge-pill border-primary text-primary ml-2">
                                <i class="icon-filter3 mr-1" style="font-size: 90%;"></i>Add to filter
                            </a>
                        </div>
                    </td>
                    <td class="text-right">
                        <count [count]="crashApplicationSegment.crashesCount"></count>
                    </td>
                    <td class="text-right">
                        <count [count]="crashApplicationSegment.distinctDeviceIds"></count>
                    </td>
                    <td class="text-center" *ngIf="crashApplicationSegment.earliestCrashVersion == crashApplicationSegment.latestCrashVersion">
                        <span class="badge badge-light badge-pill">{{crashApplicationSegment.earliestCrashVersion}}</span>
                    </td>
                    <td class="text-center nowrap" *ngIf="crashApplicationSegment.earliestCrashVersion != crashApplicationSegment.latestCrashVersion">
                        <span class="badge badge-light badge-pill">{{crashApplicationSegment.earliestCrashVersion}}</span>…<span class="badge badge-light badge-pill">{{crashApplicationSegment.latestCrashVersion}}</span>
                    </td>
                    <td class="nowrap text-right">{{dateFormatter.timeAgoShort(crashApplicationSegment.earliestCrashDate)}}</td>
                    <td class="nowrap text-right">{{dateFormatter.timeAgoShort(crashApplicationSegment.latestCrashDate)}}</td>
                </tr>
            </tbody>
        </table>
    </ng-container>

    <!-- PLATFORM SEGMENTS -->
    <ng-container *ngIf="crashDimension == CrashDimension.Platforms">
        <table class="table" *ngIf="crashPlatformSegments && crashPlatformSegments.length > 0">
            <thead>
                <tr role="row">
                    <th class="border-bottom-0 w-100">Platform</th>
                    <th class="border-bottom-0 nowrap text-right">
                        <sortable [name]="'Crashes'" [ascValue]="CrashSegmentsOrder.COUNT_ASC" [descValue]="CrashSegmentsOrder.COUNT_DESC" [default]="CrashSegmentsOrder.COUNT_DESC"></sortable>
                    </th>
                    <th class="border-bottom-0 nowrap text-right">
                        <sortable [name]="'Users'" [ascValue]="CrashSegmentsOrder.AFFECTED_USERS_ASC" [descValue]="CrashSegmentsOrder.AFFECTED_USERS_DESC"></sortable>
                    </th>
                    <th class="border-bottom-0 nowrap text-center">App versions</th>
                    <th class="border-bottom-0 nowrap text-right">First seen</th>
                    <th class="border-bottom-0 nowrap text-right">Last seen</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let crashPlatformSegment of crashPlatformSegments">
                    <td class="hover-expendable">
                        <div class="d-flex button-muted align-items-center">
                            <i class="{{platformFormatter.platformIconName(crashPlatformSegment.platform)}}"></i> <span class="ml-1">{{platformFormatter.platformName(crashPlatformSegment.platform)}}</span>
                            <a [routerLink]="['./']" [queryParams]="{platform: this.platformFormatter.platformName(crashPlatformSegment.platform)}" queryParamsHandling="merge" class="hover-target badge badge-flat badge-pill border-primary text-primary ml-2">
                                <i class="icon-filter3 mr-1" style="font-size: 90%;"></i>Add to filter
                            </a>
                        </div>
                    </td>
                    <td class="text-right">
                        <count [count]="crashPlatformSegment.crashesCount"></count>
                    </td>
                    <td class="text-right">
                        <count [count]="crashPlatformSegment.distinctDeviceIds"></count>
                    </td>
                    <td class="text-center" *ngIf="crashPlatformSegment.earliestCrashVersion == crashPlatformSegment.latestCrashVersion">
                        <span class="badge badge-light badge-pill">{{crashPlatformSegment.earliestCrashVersion}}</span>
                    </td>
                    <td class="text-center nowrap" *ngIf="crashPlatformSegment.earliestCrashVersion != crashPlatformSegment.latestCrashVersion">
                        <span class="badge badge-light badge-pill">{{crashPlatformSegment.earliestCrashVersion}}</span>…<span class="badge badge-light badge-pill">{{crashPlatformSegment.latestCrashVersion}}</span>
                    </td>
                    <td class="nowrap text-right">{{dateFormatter.timeAgoShort(crashPlatformSegment.earliestCrashDate)}}</td>
                    <td class="nowrap text-right">{{dateFormatter.timeAgoShort(crashPlatformSegment.latestCrashDate)}}</td>
                </tr>
            </tbody>
        </table>
    </ng-container>

</ng-container>

<upload-symbols-modal #uploadSymbolsModal></upload-symbols-modal>
