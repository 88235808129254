import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from 'app/_services';
import { Workspace } from 'app/_models';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppConfig } from 'app/app.config';

@Component({
    selector: 'navbar-user-menu',
    templateUrl: 'navbar.user.menu.html',
    styleUrls: [
        'navbar.user.menu.css'
    ]
})
export class NavbarUserMenu implements OnDestroy {

    userName: string
    workspace: Workspace

    docsUrl: string

    private userLoginResponseSubscription: Subscription
    private currentWorkspaceSubscription: Subscription

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        this.docsUrl = AppConfig.documentationBaseUrl

        this.userLoginResponseSubscription = this.authenticationService.userLoginResponse.subscribe(userLoginResponse => {
            this.userName = userLoginResponse == null ? null : userLoginResponse.userName
        })

        this.currentWorkspaceSubscription = this.authenticationService.currentWorkspace.subscribe(workspace => {
            this.workspace = workspace
        })
    }

    ngOnDestroy() {
        this.userLoginResponseSubscription.unsubscribe()
        this.currentWorkspaceSubscription.unsubscribe()
    }

    logout() {
        this.userLoginResponseSubscription.unsubscribe()
        this.currentWorkspaceSubscription.unsubscribe()
        this.authenticationService.logout()
        this.router.navigate(['/login'])
    }

}
