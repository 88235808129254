import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TagInputModule } from 'ngx-chips';
/*
 * Platform and Environment providers/directives/pipes
 */
import { environment } from 'environments/environment';
import { ROUTES } from './app.routes';
// App is our top level component
import { AppComponent }  from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { AppState, InternalStateType } from './app.service';
import { NoContentComponent } from './no-content';
import { NgxPopperModule } from 'ngx-popper';
import { ServiceWorkerModule } from '@angular/service-worker';

import '../styles/icons/icomoon/styles.css'
import '../styles/bootstrap.css'
import '../styles/bootstrap_limitless.css'
import '../styles/daterangepicker.css'
import '../styles/layout.css'
import '../styles/components.css'
import '../styles/colors.css'
import '../styles/app.css'

import { AlertComponent, SettingsSidebarComponent, NavbarUserMenu, NavbarUnregistered, NavbarSettings, QRCodeComponent, ApplicationLogContent, ConsoleLogContent, NetworkLogContent, SubscriptionNotice, NetworkStateLogContent, HistogramComponent, MillisecondsComponent, AppdexComponent, CountComponent, ByteCountComponent, StatusCodeComponent, UrlPatternComponent, MFAComponent, PercentageComponent, AppIconComponent } from './_components';
import { ErrorInterceptor, TokenAuthInterceptor, PlatformFormatter, DateFormatter, ByteCountFormatter, CountFormatter, RoleFormatter, InvoiceStateFormatter, HTTPFormatter, DataRetentionTierFormatter, TimeFilterFormatter, ConsoleLogLevelFormatter, IssueStateFormatter, TimeStyleFormatter, NetworkInterfaceFormatter, NetworkRequestSourceFormatter, PercentageFormatter } from './_helpers';
import { LoginComponent } from './login';
import { AccountPendingActivationComponent } from './account.pending.activation';
import { RegisterComponent } from './register';
import { BugReportsComponent, BugReportComponent, ValueItem } from './bug.reports';
import { AcceptInvitationComponent } from './accept.invitation';
import { NewAppComponent } from './_global.modals/new.app.component';
import { SecuritySettingsComponent } from './settings/security.settings.component';
import { BugReportsListItem } from './bug.reports/bug.reports.list/bug.reports.list.item';
import { ApplicationsSettingsComponent } from './settings/applications.settings.component/applications.settings.component';
import { PeopleSettingsComponent } from './settings/people.settings.component/people.settings.component';
import { IntegrationsComponent } from './settings/integrations.component/integrations.component';
import { SlackRedirectComponent } from './redirects/slack.redirect.component';
import { TestExecutionsComponent } from './test.executions/test.executions.component';
import { TestExecutionsListItem } from './test.executions/test.executions.list/test.executions.list.item';
import { TestExecutionComponent } from './test.executions/test.execution.component';
import { ApplicationLogsComponent } from './_components/application.logs.component/application.logs.component';
import { CallStackComponent } from './_components/call.stack.component';
import { TestRunComponent } from './test.executions/test.run.component';
import { TestRunnerLogsComponent } from './_components/test.runner.logs.component/test.runner.logs.component';
import { EditIntegrationTriggerModal } from './settings/integrations.component/edit.integration.trigger.modal';
import { WorkspaceResolverComponent } from './workspace.resolver';
import { BuildsComponent } from './builds/builds.component';
import { BuildComponent } from './builds/build.component';
import { ApplicationInstallComponent } from './application.install/application.install.component';
import { WorkspaceDashboardComponent } from './workspace.dashboard/workspace.dashboard.component';
import { NavbarAppFeature } from './_components/navbar.app.feature';
import { HomeComponent } from './home/home.component';
import { AccountActivityComponent } from './settings/account.activity.component/account.activity.component';
import { ApplicationDashboardComponent } from './application/dashboard/application.dashboard.component';
import { TestExecutionProgressItem } from './test.executions/test.executions.list/test.execution.progress.item';
import { DropzoneComponent } from './_components/dropzone.component';
import { ApiSettingsComponent } from './settings/api.settings.component/api.settings.component';
import { NewAuthTokenModal } from './settings/api.settings.component/new.auth.token.modal';
import { EditAuthTokenModal } from './settings/api.settings.component/edit.auth.token.modal';
import { BuildInstallComponent } from './application.install/build.install.component';
import { TestCaseComponent } from './test.executions/test.case.component';
import { ApplicationComponent, NewWidgetModal } from './application';
import { TestCasesComponent } from './test.executions/test.cases.component';
import { WorkspaceSettingsComponent } from './settings/workspace.settings.component/workspace.settings.component';
import { SubscriptionSettingsComponent } from './settings/subscription.settings.component/subscription.settings.component';
import { PasswordRecoveryComponent } from './password.recovery/password.recovery.component';
import { InvoiceComponent } from './settings/invoice.component/invoice.component';
import { TestFailureGroupsComponent } from './test.executions/test.failure.groups.component';
import { TestFailureGroupComponent } from './test.executions/test.failure.group.component';
import { EditTestFailureGroupModal } from './test.executions/edit.test.failure.group.modal/edit.test.failure.group.modal';
import { MarkdownComponent } from './_components/markdown.component';
import { NewTeamsWebhookModal } from './settings/integrations.component/new.teams.webhook.modal';
import { EditTestCaseModal } from './test.executions/edit.test.case.modal/edit.test.case.modal';
import { WidgetViewComponent } from './_components/widget.view.component';
import { LogSourceComponent } from './_components/application.logs.component/logs/log.source.component';
import { DemoComponent } from './demo.component/demo.component';
import { ImageVideoComponent } from './_components/image.video.component/image.video.component';
import { XCTActionLogContent } from './_components/application.logs.component/logs/xct.action.log.content';
import { XCTQueryLogContent } from './_components/application.logs.component/logs/xct.query.log.content';
import { AppConfig } from './app.config';
import { MobileWarningComponent } from './_components/mobile.warning.component/mobile.warning.component';
import { VideoTimelineComponent } from './_components/application.logs.component/video.timeline.component/video.timeline.component';
import { VideoFrameComponent } from './_components/application.logs.component/video.timeline.component/video.frame.component';
import { ApplicationFormatter } from './_helpers/application.formatter';
import { HeaderValueItem } from './_components/header.value.item/header.value.item';
import { SortDirective } from './directive/sort.directive';
import { AppdexChartComponent } from './_components/appdex.chart/appdex.chart.component';
import { EditNetworkOperationModal } from './network/edit.network.operation.modal/edit.network.operation.modal';
import { AppLaunchComponent } from './app.launch/app.launch.component';
import { NetworkTrendsComponent } from './network/network.trends.component/network.trends.component';
import { AppNetworkComponent } from './app.network.component/app.network.component';
import { NetworkFailuresProgressItem } from './network/network.failures.progress.item/network.failures.progress.item';
import { RequestsOverTimeChart } from './network/requests.over.time.chart/requests.over.time.chart';
import { PayloadOverTimeChart } from './network/payload.over.time.chart/payload.over.time.chart';
import { NetworkErrorsChart } from './network/network.errors.chart/network.errors.chart';
import { ResponseTimeOverTimeChart } from './network/response.time.over.time.chart/response.time.over.time.chart';
import { NetworkSegmentsComponent } from './network/network.segments.component/network.segments.component';
import { RequestPreviewModal } from './network/request.preview.modal/request.preview.modal';
import { AppdexFormatter } from './_helpers/appdex.formatter';
import { BackendEnvironmentComponent } from './environment/backend.environment.component';
import { BackendEnvironmentNetworkComponent } from './environment/backend.environment.network.component';
import { BackendEnvironmentTestExecutionsComponent } from './environment/backend.environment.test.executions.component';
import { BackendEnvironmentSettingsComponent } from './environment/backend.environment.settings.component';
import { ApplicationSettingsComponent } from './application/application.settings.component';
import { AppLaunchSegmentsComponent } from './app.launch/app.launch.segments.component/app.launch.segments.component';
import { AppLaunchDistributionChartComponent } from './app.launch/app.launch.distribution.chart/app.launch.distribution.chart.component';
import { AppLaunchDurationChartComponent } from './app.launch/app.launch.duration.chart/app.launch.duration.chart.component';
import { AppLaunchTrendsComponent } from './app.launch/app.launch.trends.component/app.launch.trends.component';
import { ResponseTimeSummaryComponent } from './network/response.time.summary.component/response.time.summary.component';
import { ErrorRateSummaryComponent } from './network/error.rate.summary.component/error.rate.summary.component';
import { PayloadSummaryComponent } from './network/payload.summary.component/payload.summary.component';
import { AppdexSummaryComponent } from './network/appdex.summary.component/appdex.summary.component';
import { RequestCountSummaryComponent } from './network/request.count.summary.component/request.count.summary.component';
import { OsLaunchRateChart } from './app.launch/os.launch.rate.chart/os.launch.rate.chart';
import { AppVersionLaunchRateChart } from './app.launch/app.version.launch.rate.chart/app.version.launch.rate.chart';
import { CrashReportsComponent } from './crash.reports/crash.reports.list.component/crash.reports.list.component';
import { CrashReportComponent } from './crash.reports/crash.report.component/crash.report.component';
import { CrashReportListItem } from './crash.reports/crash.report.list.item/crash.report.list.item';
import { ErrorsOverTimeChart } from './error.reports/errors.over.time.chart/errors.over.time.chart';
import { EditErrorGroupModal } from './error.reports/edit.error.group.modal/edit.error.group.modal';
import { CrashTrendsSummaryComponent } from './crash.reports/crash.trends.summary.component/crash.trends.summary.component';
import { ErrorTrendsSummaryComponent } from './error.reports/error.trends.summary.component/error.trends.summary.component';
import { ApplicationErrorGroupComponent } from './error.reports/application.error.group.component/application.error.group.component';
import { ErrorReportListItem } from './error.reports/error.report.list.item/error.report.list.item';
import { ErrorReportsListComponent } from './error.reports/error.reports.list.component/error.reports.list.component';
import { ErrorReportComponent } from './error.reports/error.report.component/error.report.component';
import { UploadSymbolsComponent } from './_global.modals';
import { DistributionGroupsComponent } from './distribution.groups/distribution.groups.component/distribution.groups.component';
import { DistributionGroupEditorModal } from './distribution.groups/distribution.group.editor/distribution.group.editor.modal';
import { DistributionGroupComponent } from './distribution.groups/distribution.group.component/distribution.group.component';
import { DistributeBuildModal } from './builds/distribute.build.modal/distribute.build.modal';
import { UserEditorModal } from './user.editor/user.editor.modal';
import { ApplicationDistributionsComponent } from './application.install/application.distributions.component';
import { AvatarComponent } from './_components/avatar.component/avatar.component';
import { DateRangeComponent } from './_components/date.range.component/date.range.component';
import { QuickJumpModal } from './_components/quick.jump.modal/quick.jump.modal';
import { SortableComponent } from './_components/sortable.component/sortable.component';
import { CrashGroupComponent } from './crash.reports/crash.group.component/crash.group.component';
import { CrashesOverTimeChart } from './crash.reports/crashes.over.time.chart/crashes.over.time.chart';
import { EditCrashGroupModal } from './crash.reports/edit.crash.group.modal/edit.crash.group.modal';
import { WorkspaceCrashesComponent } from './crash.reports/workspace.crashes.component/workspace.crashes.component';
import { WorkspaceErrorsComponent } from './error.reports/workspace.errors.component/workspace.errors.component';
import { WorkspaceErrorGroupComponent } from './error.reports/workspace.error.group.component/workspace.error.group.component';
import { ErrorsOverviewComponent } from './error.reports/errors.overview.component/errors.overview.component';
import { ApplicationErrorsComponent } from './error.reports/application.errors.component/application.errors.component';
import { ErrorGroupComponent } from './error.reports/error.group.component/error.group.component';
import { WorkspaceErrorReportsListComponent } from './error.reports/workspace.error.reports.list.component/workspace.error.reports.list.component';
import { CrashesOverviewComponent } from './crash.reports/crashes.overview.component/crashes.overview.component';
import { ApplicationCrashesComponent } from './crash.reports/application.crashes.component/application.crashes.component';
import { WorkspaceCrashGroupComponent } from './crash.reports/workspace.crash.group.component/workspace.crash.group.component';
import { ApplicationCrashGroupComponent } from './crash.reports/application.crash.group.component/application.crash.group.component';
import { WorkspaceCrashReportsListComponent } from './crash.reports/workspace.crash.reports.list.component/workspace.crash.reports.list.component';
import { NetworkOverviewComponent } from './network/network.overview.component/network.overview.component';
import { WorkspaceNetworkComponent } from './network/workspace.network.component/workspace.network.component';
import { SseService } from './_services/sse.service';

// Application wide providers
const APP_PROVIDERS = [
    ...APP_RESOLVER_PROVIDERS,
    AppState,
    PlatformFormatter,
    DateFormatter,
    NetworkInterfaceFormatter,
    NetworkRequestSourceFormatter,
    ByteCountFormatter,
    CountFormatter,
    PercentageFormatter,
    RoleFormatter,
    TimeFilterFormatter,
    ConsoleLogLevelFormatter,
    AppdexFormatter,
    IssueStateFormatter,
    TimeStyleFormatter,
    ApplicationFormatter,
    SseService,

    InvoiceStateFormatter,
    DataRetentionTierFormatter,
    HTTPFormatter,
];

interface StoreType {
    state: InternalStateType;
    restoreInputValues: () => void;
    disposeOldHosts: () => void;
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        TagInputModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule.forRoot(ROUTES, {
            useHash: Boolean(history.pushState) === false,
            preloadingStrategy: PreloadAllModules
        }),
        NgxPopperModule.forRoot({
            trigger: 'hover',
            placement: 'top',
            appendTo: 'body',
            disableDefaultStyling: true,
            applyClass: 'tooltip,tooltip-inner',
            disableAnimation: true
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    declarations: [
        SortDirective,

        AppComponent,
        HomeComponent,
        MobileWarningComponent,

        ErrorsOverviewComponent,

        ApplicationComponent,
        BackendEnvironmentComponent,

        NavbarSettings,
        NavbarUserMenu,
        NavbarUnregistered,
        NavbarAppFeature,

        TestExecutionsComponent,
        BackendEnvironmentTestExecutionsComponent,
        TestExecutionsListItem,
        TestExecutionComponent,
        TestRunComponent,
        TestCasesComponent,
        TestCaseComponent,
        AppNetworkComponent,
        AppLaunchComponent,

        ApplicationLogContent,
        ConsoleLogContent,
        LogSourceComponent,
        NetworkLogContent,
        NetworkStateLogContent,
        XCTActionLogContent,
        XCTQueryLogContent,
        CallStackComponent,
        ImageVideoComponent,
        AppIconComponent,
        VideoTimelineComponent,
        VideoFrameComponent,

        DemoComponent,
        ApplicationLogsComponent,
        ApplicationInstallComponent,
        BuildInstallComponent,
        WorkspaceCrashGroupComponent,
        ApplicationCrashGroupComponent,
        TestRunnerLogsComponent,
        NoContentComponent,
        NetworkOverviewComponent,
        SubscriptionNotice,
        AlertComponent,
        WidgetViewComponent,
        HistogramComponent,
        AppLaunchDistributionChartComponent,
        AppLaunchSegmentsComponent,
        AppLaunchDurationChartComponent,
        AppdexChartComponent,
        IntegrationsComponent,
        WorkspaceErrorReportsListComponent,
        CrashesOverviewComponent,
        SlackRedirectComponent,
        WorkspaceResolverComponent,
        AcceptInvitationComponent,
        TestExecutionProgressItem,
        NetworkFailuresProgressItem,
        CrashTrendsSummaryComponent,
        ErrorTrendsSummaryComponent,
        NewAppComponent,
        ApiSettingsComponent,
        EditTestFailureGroupModal,
        EditTestCaseModal,
        DistributionGroupsComponent,
        NewAuthTokenModal,
        RequestPreviewModal,
        EditAuthTokenModal,
        EditIntegrationTriggerModal,
        NewTeamsWebhookModal,
        ApplicationSettingsComponent,
        EditNetworkOperationModal,
        SecuritySettingsComponent,
        PeopleSettingsComponent,
        DistributeBuildModal,
        WorkspaceSettingsComponent,
        SubscriptionSettingsComponent,
        InvoiceComponent,
        TestFailureGroupsComponent,
        TestFailureGroupComponent,
        ApplicationCrashesComponent,
        WorkspaceCrashesComponent,
        ApplicationErrorsComponent,
        ErrorGroupComponent,
        WorkspaceErrorsComponent,
        WorkspaceNetworkComponent,
        CrashGroupComponent,
        WorkspaceCrashReportsListComponent,
        EditCrashGroupModal,
        EditErrorGroupModal,
        CrashReportsComponent,
        ErrorReportsListComponent,
        CrashReportComponent,
        ErrorReportComponent,
        CrashReportListItem,
        ErrorReportListItem,
        AccountActivityComponent,
        ApplicationsSettingsComponent,
        BackendEnvironmentSettingsComponent,
        QRCodeComponent,
        MarkdownComponent,
        DropzoneComponent,
        CountComponent,
        DateRangeComponent,
        QuickJumpModal,
        ByteCountComponent,
        SortableComponent,
        AvatarComponent,
        ApplicationErrorGroupComponent,
        WorkspaceErrorGroupComponent,
        StatusCodeComponent,
        MFAComponent,
        UrlPatternComponent,
        ApplicationDistributionsComponent,
        DistributionGroupEditorModal,
        UserEditorModal,
        DistributionGroupComponent,
        ResponseTimeSummaryComponent,
        ErrorRateSummaryComponent,
        PayloadSummaryComponent,
        AppdexSummaryComponent,
        RequestCountSummaryComponent,
        MillisecondsComponent,
        PercentageComponent,
        AppdexComponent,
        NetworkTrendsComponent,
        NetworkSegmentsComponent,
        AppLaunchTrendsComponent,
        SettingsSidebarComponent,
        RequestsOverTimeChart,
        CrashesOverTimeChart,
        ErrorsOverTimeChart,
        PayloadOverTimeChart,
        NetworkErrorsChart,
        ResponseTimeOverTimeChart,
        OsLaunchRateChart,
        AppVersionLaunchRateChart,
        ValueItem,
        HeaderValueItem,
        BugReportsListItem,
        LoginComponent,
        RegisterComponent,
        BugReportsComponent,
        BugReportComponent,
        BuildsComponent,
        BuildComponent,
        ApplicationDashboardComponent,
        BackendEnvironmentComponent,
        BackendEnvironmentNetworkComponent,
        NewWidgetModal,
        UploadSymbolsComponent,
        WorkspaceDashboardComponent,
        AccountPendingActivationComponent,
        PasswordRecoveryComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TokenAuthInterceptor, multi: true },
        environment.ENV_PROVIDERS,
        APP_PROVIDERS
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
