import { Injectable, NgZone } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SseService {
    constructor(private zone: NgZone) { }

    connect(url: string, onMessage: (data: string) => void): EventSource {
        const eventSource = new EventSource(url)

        eventSource.onmessage = (event) => {
            // Use NgZone to ensure updates propagate to Angular's change detection
            this.zone.run(() => {
                onMessage(event.data)
            })
        }

        eventSource.onerror = (error) => {
            console.error('SSE error:', error)
            // Handle reconnection logic if needed
            eventSource.close()
        }

        return eventSource
    }
}
