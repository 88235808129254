<ul class="navbar-nav">
    <li class="nav-item dropdown dropdown-users m-0">
        <a class="navbar-nav-link d-flex align-items-center dropdown-toggle" data-toggle="dropdown" style="min-height: 53px; max-height: 53px;">
            <div class="align-items-center">
                <div>{{userName}}</div>
                <div class="text-muted text-right font-weight-light font-size-sm workspace-name">{{workspace.name}}</div>
            </div>
        </a>

        <div class="dropdown-menu dropdown-menu-right m-0">
            <a [routerLink]="['/', workspace.slug]" class="dropdown-item"><i class="icon-grid"></i>Dashboard</a>
            <div class="dropdown-divider"></div>
            <a [routerLink]="['/', workspace.slug, 'crashes']" class="dropdown-item">Crashes</a>
            <a [routerLink]="['/', workspace.slug, 'errors']" class="dropdown-item">Errors</a>
            <a [routerLink]="['/', workspace.slug, 'network']" class="dropdown-item">Network</a>
            <a [routerLink]="['/', workspace.slug, 'app-distributions']" class="dropdown-item">App Distributions</a>
            <div class="dropdown-divider"></div>
            <a href="{{docsUrl}}" class="dropdown-item">Documentation & API</a>
            <a href="mailto:support@appelium.com" class="dropdown-item">Contact Support</a>
            <div class="dropdown-divider"></div>
            <a [routerLink]="['/settings']" class="dropdown-item"><i class="icon-equalizer"></i>Workspace Settings</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" (click)="logout()"><i class="icon-exit"></i>Sign Out</a>
        </div>
    </li>
</ul>
