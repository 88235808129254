import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorGroup, IssueState, ErrorReport, ErrorFilter, ErrorTrendsResponse, ErrorTrendsSummaryResponse, ErrorGroupSegment, ApplicationVersion, OsVersion, BundleIdentifier, ErrorSegmentsOrder, Application, ErrorDimension, ErrorApplicationSegment, Platform } from 'app/_models';
import { AppConfig } from 'app/app.config';
import { CollectionResponse, ElementResponse } from './response';
import { BehaviorSubject, Observable } from 'rxjs';

export class ErrorFiltersResponse {
    applications: Application[]
    applicationVersions: ApplicationVersion[]
    bundleIdentifiers: BundleIdentifier[]
    platforms: Platform[]
    osVersions: OsVersion[]
}

@Injectable({ providedIn: 'root' })
export class ErrorsService {

    private currentErrorGroupSubject: BehaviorSubject<ErrorGroup>
    public currentErrorGroup: Observable<ErrorGroup>

    setCurrentErrorGroup(errorGroup: ErrorGroup | null) {
        if (this.currentErrorGroupSubject.value != errorGroup) {
            this.currentErrorGroupSubject.next(errorGroup)
        }
    }

    public get currentErrorGroupValue(): ErrorGroup {
        return this.currentErrorGroupSubject.value
    }

    constructor(private http: HttpClient) {
        this.currentErrorGroupSubject = new BehaviorSubject<ErrorGroup>(null)
        this.currentErrorGroup = this.currentErrorGroupSubject.asObservable()

    }

    getWorkspaceErrorFilters(workspaceId: number): Promise<ErrorFiltersResponse> {
        return this.http.get<ErrorFiltersResponse>(`${AppConfig.apiBaseUrl}/workspaces/${workspaceId}/errorFilters`).toPromise()
    }

    getApplicationErrorFilters(applicationId: number): Promise<ErrorFiltersResponse> {
        return this.http.get<ErrorFiltersResponse>(`${AppConfig.apiBaseUrl}/applications/${applicationId}/errorFilters`).toPromise()
    }

    getWorkspaceErrorSegments(workspaceId: number, filter: ErrorFilter, dimension: ErrorDimension, orderBy: ErrorSegmentsOrder): Promise<CollectionResponse<ErrorGroupSegment | ErrorApplicationSegment>> {
        var url = `${AppConfig.apiBaseUrl}/workspaces/${workspaceId}/errorGroups?`
        url = this.urlByApplyingErrorFilter(url, filter)
        url += `&dimension=${dimension}`

        if (orderBy) {
            url = url += `&orderBy=${orderBy}`
        }

        return this.http.get<CollectionResponse<ErrorGroupSegment | ErrorApplicationSegment>>(url).toPromise()
    }

    getWorkspaceErrorGroupWithSlug(workspaceId: number, slug: string): Promise<ElementResponse<ErrorGroup>> {
        return this.http.get<ElementResponse<ErrorGroup>>(`${AppConfig.apiBaseUrl}/workspaces/${workspaceId}/errorGroups?slug=${slug}`).toPromise()
    }

    getErrorGroupErrorReports(errorGroupId: number, filter: ErrorFilter): Promise<CollectionResponse<ErrorReport>> {
        var url = `${AppConfig.apiBaseUrl}/errorGroups/${errorGroupId}/errorReports?`
        url = this.urlByApplyingErrorFilter(url, filter)

        return this.http.get<CollectionResponse<ErrorReport>>(url).toPromise()
    }

    getErrorGroupById(errorGroupId: number): Promise<ElementResponse<ErrorGroup>> {
        return this.http.get<ElementResponse<ErrorGroup>>(`${AppConfig.apiBaseUrl}/errorGroups/${errorGroupId}`).toPromise()
    }

    updateErrorGroup(errorGroupId: number, annotation: string | null, state: IssueState | null, fixVersion: string | null): Promise<ElementResponse<ErrorGroup>> {
        let body = {
            annotation: annotation,
            state: state,
            fixVersion: fixVersion
        }

        return this.http.put<ElementResponse<ErrorGroup>>(`${AppConfig.apiBaseUrl}/errorGroups/${errorGroupId}`, body).toPromise()
    }

    getErrorTrends(workspaceId: number, filter: ErrorFilter): Promise<ErrorTrendsResponse> {
        let url = `${AppConfig.apiBaseUrl}/workspaces/${workspaceId}/errorTrends?`
        url = this.urlByApplyingErrorFilter(url, filter)

        return this.http.get<ErrorTrendsResponse>(url).toPromise()
    }

    getErrorTrendsSummary(workspaceId: number, filter: ErrorFilter): Promise<ErrorTrendsSummaryResponse> {
        let url = `${AppConfig.apiBaseUrl}/workspaces/${workspaceId}/errorTrendsSummary?`
        url = this.urlByApplyingErrorFilter(url, filter)

        return this.http.get<ErrorTrendsSummaryResponse>(url).toPromise()
    }

    // getErrorReportsFrom(errorGroupId: number, from: Date | null): Promise<CollectionResponse<ErrorReport>> {
    //     var url = `${AppConfig.apiBaseUrl}/errorGroups/${errorGroupId}/errorReports`
    //     if (from !== null) {
    //         url += `?from=${from.toISOString()}`
    //     } else {
    //         url += `?limit=20`
    //     }

    //     return this.http.get<CollectionResponse<ErrorReport>>(url).toPromise()
    // }

    // getErrorReportsPageBefore(errorGroupId: number, pageBefore: Date | null): Promise<CollectionResponse<ErrorReport>> {
    //     var url = `${AppConfig.apiBaseUrl}/errorGroups/${errorGroupId}/errorReports`
    //     if (pageBefore !== null) {
    //         url += `?pageBefore=${pageBefore.toISOString()}`
    //     }

    //     return this.http.get<CollectionResponse<ErrorReport>>(url).toPromise()
    // }

    getErrorGroupErrorReportWithSlug(errorGroupId: number, slug: string): Promise<ElementResponse<ErrorReport>> {
        return this.http.get<ElementResponse<ErrorReport>>(`${AppConfig.apiBaseUrl}/errorGroups/${errorGroupId}/errorReports?slug=${slug}`).toPromise()
    }

    private urlByApplyingErrorFilter(baseUrl: string, filter: ErrorFilter): string {
        var url = baseUrl

        url += `&startDate=${filter.startDate.toISOString()}`
        url += `&endDate=${filter.endDate.toISOString()}`

        if (filter.applicationId != null) { url += `&applicationId=${filter.applicationId}` }
        if (filter.applicationVersionId != null) { url += `&applicationVersionId=${filter.applicationVersionId}` }
        if (filter.bundleIdentifierId != null) { url += `&bundleIdentifierId=${filter.bundleIdentifierId}` }
        if (filter.errorGroupId != null) { url += `&errorGroupId=${filter.errorGroupId}` }
        if (filter.deviceId != null) { url += `&deviceId=${filter.deviceId}` }
        if (filter.osVersionId != null) { url += `&osVersionId=${filter.osVersionId}` }
        if (filter.issueState != null) { url += `&issueState=${filter.issueState}` }
        if (filter.platform != null) { url += `&platform=${filter.platform}` }

        return url
    }

}
