import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AlertService, ApplicationService  } from 'app/_services'
import { ActivatedRoute, Router } from '@angular/router';
import { Build, Application } from 'app/_models';
import * as bootbox from 'bootbox'
import { ApplicationFormatter, DateFormatter, PlatformFormatter } from 'app/_helpers';
import { BuildService } from 'app/_services';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment'

@Component({
    templateUrl: 'builds.component.html',
    styleUrls: [
        'builds.component.css'
    ]
})
export class BuildsComponent implements OnInit, OnDestroy {

    application: Application
    builds: Build[] = []
    isLoadingMoreRecords = false
    hasMoreRecords = false
    subtitle: string = ""

    @ViewChild('pageContent') pageContent: ElementRef

    private oldestBuildDate: Date = null
    private currentApplicationSubscription: Subscription
    private resizeListener: any

    constructor(
        public platformFormatter: PlatformFormatter,
        public dateFormatter: DateFormatter,
        private buildService: BuildService,
        private route: ActivatedRoute,
        private router: Router,
        private applicationService: ApplicationService,
        private alertService: AlertService,
        private applicationFormatter: ApplicationFormatter,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
            if (this.application != null) {
                this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | Builds`)
                this.onApplicationLoaded()
            }
        })

        this.resizeListener = this.loadMoreBuildsIfAtBottom.bind(this)
        window.addEventListener("resize", this.resizeListener)
    }

    ngOnDestroy() {
        this.currentApplicationSubscription?.unsubscribe()
        window.removeEventListener("resize", this.resizeListener)
    }

    onPageContentScroll(event: any) {
        this.loadMoreBuildsIfAtBottom()
    }

    private onApplicationLoaded() {
        this.refreshCurrentBuilds()
    }

    private refreshCurrentBuilds() {
        if (this.application == null || this.isLoadingMoreRecords) {
            return
        }

        this.buildService.getBuildsFrom(this.oldestBuildDate, this.application.id).then((response) => {
            this.builds = response.data
            this.hasMoreRecords = response.hasMoreRecords

            // Update state and load more if needed
            if (this.builds.length > 0) {
                this.oldestBuildDate = moment(this.builds[this.builds.length - 1].creationDate).toDate()
                this.hasMoreRecords = response.hasMoreRecords
                setTimeout(this.loadMoreBuildsIfAtBottom, 500)

                let latestBuild = this.builds[0]
                this.subtitle = `Latest build ${this.dateFormatter.timeAgo(latestBuild.creationDate)}`

            } else {
                this.subtitle = "No builds found"
            }
        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    loadMoreBuildsIfAtBottom() {
        if (!this.application || !this.hasMoreRecords || this.isLoadingMoreRecords) {
            return
        }

        let pageContent = this.pageContent.nativeElement
        if (pageContent.offsetHeight + pageContent.scrollTop < pageContent.scrollHeight) {
            return
        }

        this.isLoadingMoreRecords = true
        this.buildService.getBuildsPageBefore(this.oldestBuildDate, this.application.id).then((response) => {
            this.builds = this.builds.concat(response.data)
            this.oldestBuildDate = moment(this.builds[this.builds.length - 1].creationDate).toDate()
            this.hasMoreRecords = response.hasMoreRecords
            this.isLoadingMoreRecords = false
            this.loadMoreBuildsIfAtBottom()

        }).catch((error) => {
            this.isLoadingMoreRecords = false

            this.alertService.handleError(error)
        })
    }

    isBuildExpired(build: Build): boolean {
        // Android builds don't expire and thus don't have an expiration date
        if (build.expirationDate == null) {
            return false
        }

        let expirationDate = new Date(build.expirationDate)
        return expirationDate.getTime() <= new Date().getTime()
    }

    buildExpirationStatus(build: Build): string {
        // Android builds don't expire and thus don't have an expiration date
        if (build.expirationDate == null) {
            return ""
        }

        let expirationDate = new Date(build.expirationDate)
        if (this.isBuildExpired(build)) {
            return `Expired ${this.dateFormatter.timeAgo(expirationDate)}`

        } else {
            return `Expires ${this.dateFormatter.timeAgo(expirationDate)}`
        }
    }

    showBuild(build: Build) {
        this.router.navigate([build.serialNumber], { relativeTo: this.route })
    }

    buildShouldDelete(build: Build) {
        let weakThis = this

        bootbox.dialog({
            title: `Delete Build #${build.serialNumber}?`,
            message: "The Build will be permanently deleted. This action is not reversible.",
            size: 'small',
            buttons: {
                cancel: { label: 'Cancel', className: 'btn-link' },
                delete: {
                    label: 'Delete', className: 'btn-danger', callback: function () {
                        weakThis.deleteBuild(build)
                    }
                }
            }
        })
    }

    deleteBuild(build: Build) {
        this.buildService.deleteBuild(build).subscribe(
            response => {
                this.refreshCurrentBuilds()

            }, error => {
                this.alertService.handleError(error)
            }
        )
    }

}
