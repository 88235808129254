import { Component, Input, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TestExecution, Workspace } from 'app/_models';
import { Subscription, Observable, timer } from 'rxjs';
import { DateFormatter, PlatformFormatter } from 'app/_helpers';
import { AuthenticationService } from 'app/_services';

@Component({
    selector: '[my-tr]',
    templateUrl: 'test.executions.list.item.html'
})
export class TestExecutionsListItem implements OnInit, OnDestroy {

    workspace: Workspace

    _testExecution: TestExecution
    @Input()
    set testExecution(testExecution: TestExecution) {
        this._testExecution = testExecution

        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe()
        }

        // Call immediately as the second call in a timer is causing progress to be animated
        this.recalculateState()
        this.timerSubscription = this.timerObservable.subscribe((seconds) => {
            this.recalculateState()
        })
    }
    get testExecution() { return this._testExecution }

    @Input() showAppColumn: boolean
    @Input() showEnvironmentColumn: boolean

    @Output() testExecutionShouldDelete = new EventEmitter<TestExecution>()
    @Output() navigateToTestExecution = new EventEmitter<TestExecution>()

    duration: string

    private timerObservable: Observable<number> = timer(0, 1000)
    private timerSubscription: Subscription

    constructor(
        public dateFormatter: DateFormatter,
        public platformFormatter: PlatformFormatter,
        private authenticationService: AuthenticationService
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue
    }

    ngOnDestroy() {
        this.timerSubscription?.unsubscribe()
    }

    onTestExecutionClick() {
        this.navigateToTestExecution.emit(this.testExecution)
    }

    onTestExecutionDeleteButtonClick() {
        this.testExecutionShouldDelete.emit(this.testExecution)
    }

    private recalculateState() {
        this.duration = this.dateFormatter.duration(this.testExecution.stats.duration)

        let pendingAndInProgress = this.testExecution.stats.pending + this.testExecution.stats.inProgress

        // Stop the timer if execution is finished
        if (this.timerSubscription && pendingAndInProgress <= 0) {
            this.timerSubscription.unsubscribe()
        }
    }

}
