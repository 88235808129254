<div class="content-wrapper" id="pageContent">

    <div class="content">
        <div class="row">
            <div class="col-md-12">
                <alert></alert>
            </div>
        </div>

        <div class="row" *ngIf="backendEnvironment">
            <div class="col-md-12">
                <div class="card">
                    <div class="page-header border-bottom-light">
                        <div class="page-header-content header-elements-md-inline">
                            <div class="page-title">
                                <h1 class="font-weight-thin">
                                    <span class="breadcrumb-item">{{backendEnvironment.name}}</span>
                                    <span class="breadcrumb-item active">Network</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                    <network-overview [backendEnvironment]="backendEnvironment"></network-overview>
                </div>
            </div>
        </div>

    </div>

</div>
