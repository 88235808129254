import { NetworkInterface } from "./network.interface"

export class ConsoleLogsResponse {
    version: number
    logs: Log[]
}

export class Log {
    date: Date
    type: LogType
    source: LogSource
}

export enum LogType {
    Console = "CONSOLE",
    NetworkRequest = "NETWORK_REQUEST",
    NetworkResponse = "NETWORK_RESPONSE",
    Application = "APPLICATION",
    XctAction = "XCT_ACTION",
    XctQuery = "XCT_QUERY"
}

export enum LogSource {
    App = "APP",
    Test = "TEST",
    Logcat = "LOGCAT"
}

export class ApplicationLog extends Log {
    event: ApplicationLogEvent
    info: {string: string}
}

export enum ApplicationLogEvent {
    DidFinishLoading = "DID_FINISH_LOADING",
    DidEnterBackground = "DID_ENTER_BACKGROUND",
    WillEnterForeground = "WILL_ENTER_FOREGROUND",
    DidFinishLaunching = "DID_FINISH_LAUNCHING",
    DidBecomeActive = "DID_BECOME_ACTIVE",
    WillResignActive = "WILL_RESIGN_ACTIVE",
    WillTerminate = "WILL_TERMINATE",
    ScreenWillAppear = "SCREEN_WILL_APPEAR",
    ScreenDidAppear = "SCREEN_DID_APPEAR",

    OnCreate = "ON_CREATE",
    OnStart = "ON_START",
    OnResume = "ON_RESUME",
    OnPause = "ON_PAUSE",
    OnStop = "ON_STOP",
    OnDestroy = "ON_DESTROY"
}

export class ConsoleLog extends Log {
    level: ConsoleLogLevel
    message: string
    domain: string
    stackTrace: string[]
}

export enum ConsoleLogLevel {
    Verbose = "VERBOSE",
    Debug = "DEBUG",
    Info = "INFO",
    Warning = "WARNING",
    Error = "ERROR",
    Assert = "ASSERT"
}

export class NetworkRequestLog extends Log {
    requestId: string
    method: string
    url: URL
    headers: {string: string}
    body: string | null
}

export enum NetworkState {
    Unknown = "UNKNOWN",
    Available = "AVAILABLE",
    Unavailable = "UNAVAILABLE"
}

export class NetworkStateLog extends Log {
    state: NetworkState
    interface: NetworkInterface | null
    unavailableReason: string | null
}

export class NetworkResponseLog extends Log {
    requestId: string
    method: string
    statusCode: number
    networkProtocol: string | never
    url: URL
    headers: {string: string}
    body: string | null
    errorInfo: {string: string}
    duration: number
}

export class XCTActionLog extends Log {
    message: string
    duration: number
    queryChain: string[] | null
    launchEnvironment: {string: string}
    launchArguments: string[]
}

export class XCTQueryLog extends Log {
    message: string
    duration: number
    queryChain: string[] | null
    result: string
}
