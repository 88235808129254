import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Application, DateRange, ErrorApplicationSegment, ErrorDimension, ErrorFilter, ErrorGroupSegment, ErrorSegmentsOrder, errorSegmentsOrderFromQuery, ErrorTrendsResponse, ErrorTrendsSummaryResponse, IssueState, Workspace } from 'app/_models';
import { AlertService, ApplicationService, AuthenticationService, ErrorFiltersResponse, ErrorsService } from 'app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationFormatter, DateFormatter, IssueStateFormatter, PlatformFormatter } from 'app/_helpers';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment'
import { Utils } from 'app/utils';
import { ErrorPlatformSegment } from 'app/_models/error.platform.segment';

@Component({
    selector: 'errors-overview',
    templateUrl: 'errors.overview.component.html',
    styleUrls: [
        'errors.overview.component.css'
    ]
})
export class ErrorsOverviewComponent implements OnInit, OnDestroy {

    ErrorSegmentsOrder = ErrorSegmentsOrder
    ErrorDimension = ErrorDimension
    IssueState = IssueState

    workspace: Workspace
    errorGroupSegments: ErrorGroupSegment[]
    errorApplicationSegments: ErrorApplicationSegment[]
    errorPlatformSegments: ErrorPlatformSegment[]

    errorFiltersResponse: ErrorFiltersResponse
    errorTrendsResponse: ErrorTrendsResponse
    errorTrendsSummaryResponse: ErrorTrendsSummaryResponse

    selectedDateRange: DateRange
    errorDimension: ErrorDimension

    @Input() application: Application

    private queryParamsSubscription: Subscription
    private previousQueryParams: any = {}

    constructor(
        public dateFormatter: DateFormatter,
        public issueStateFormatter: IssueStateFormatter,
        public platformFormatter: PlatformFormatter,
        private errorsService: ErrorsService,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private applicationService: ApplicationService,
        private alertService: AlertService,
        private router: Router,
        private applicationFormatter: ApplicationFormatter,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue
        this.refreshFilters()

        let query = this.route.snapshot.queryParams
        this.selectedDateRange = DateRange.fromParams(query) ?? DateRange.lastMonth()
        this.errorDimension = query['dimension'] ?? ErrorDimension.ErrorGroups
        this.updateSelect2SelectionFromQuery()

        this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
            let changedParamNames = Utils.getChangedParamNames(params, this.previousQueryParams)
            this.previousQueryParams = { ...params }

            this.updateSelect2SelectionFromQuery()

            // If we don't have the filters response yet, no need to do anything as all the data will
            // get reloaded once the filters call finishes.
            if (!this.errorFiltersResponse) { return }

            // If only the dimension changed, reload segments
            if (changedParamNames.toString() == ['dimension'].toString()) {
                this.errorDimension = params['dimension']
                this.reloadErrorSegments()

                // If ordering changed, reload only the segments
            } else if (changedParamNames.toString() == ['orderBy'].toString()) {
                this.reloadErrorSegments()

                // If we reached this point, it means that either date range or the filters changed,
                // and then we need to reload everything.
            } else {
                this.selectedDateRange = DateRange.fromParams(params) ?? DateRange.lastMonth()
                this.refreshErrorTrends()
                this.reloadErrorSegments()
            }
        })
    }

    ngOnDestroy() {
        this.queryParamsSubscription?.unsubscribe()
    }

    private _didInitSelect2 = false
    ngAfterViewChecked() {
        if (!this.errorFiltersResponse) { return }

        if (!this._didInitSelect2 && $('.select2').length > 0) {
            this.initSelect2Components()
            this.updateSelect2SelectionFromQuery()
            this._didInitSelect2 = true
        }
    }

    refreshFilters() {
        if (!this.workspace) { return }

        this.errorFiltersResponse = null

        let filtersPromise = this.application ? this.errorsService.getApplicationErrorFilters(this.application.id) : this.errorsService.getWorkspaceErrorFilters(this.workspace.id)
        filtersPromise.then((response) => {
            this.errorFiltersResponse = response
            this.updateSelect2SelectionFromQuery()
            this.refreshErrorTrends()
            this.reloadErrorSegments()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    refreshErrorTrends() {
        if (!this.workspace) { return }

        // Will also switch the trends chart into loading mode
        this.errorTrendsResponse = null
        this.errorTrendsSummaryResponse = null

        let errorFilter = this.makeCurrentErrorFilter()

        this.errorsService.getErrorTrends(this.workspace.id, errorFilter).then((response) => {
            this.errorTrendsResponse = response
            this.selectedDateRange = response.dateRange

            this.errorsService.getErrorTrendsSummary(this.workspace.id, errorFilter).then((response) => {
                this.errorTrendsSummaryResponse = response
            }).catch((error) => {
                this.alertService.handleError(error)
            })
        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    reloadErrorSegments() {
        if (!this.workspace) { return }

        this.errorGroupSegments = null
        this.errorApplicationSegments = null
        this.errorPlatformSegments = null

        let errorFilter = this.makeCurrentErrorFilter()
        let orderBy = errorSegmentsOrderFromQuery(this.route.snapshot.queryParams) ?? ErrorSegmentsOrder.COUNT_DESC
        this.errorsService.getWorkspaceErrorSegments(this.workspace.id, errorFilter, this.errorDimension, orderBy).then((response) => {
            switch (this.errorDimension) {
                case ErrorDimension.ErrorGroups: this.errorGroupSegments = response.data as any
                case ErrorDimension.Applications: this.errorApplicationSegments = response.data as any
                case ErrorDimension.Platforms: this.errorPlatformSegments = response.data as any
            }

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    onDateRangeChanged(dateRange: DateRange) {
        this.router.navigate([], { queryParams: { startDate: moment(dateRange.startDate).toISOString(), endDate: moment(dateRange.endDate).toISOString() }, queryParamsHandling: 'merge' })
    }

    setErrorDimension(errorDimension: ErrorDimension) {
        this.router.navigate([], { queryParams: { dimension: errorDimension }, queryParamsHandling: 'merge' })
    }

    navigateToErrorGroup(errorGroupSegment: ErrorGroupSegment) {
        this.router.navigate([errorGroupSegment.serialNumber], { relativeTo: this.route, queryParamsHandling: 'merge' })
    }

    private makeCurrentErrorFilter(): ErrorFilter {
        if (!this.workspace || !this.errorFiltersResponse) {
            return null
        }

        let query = this.route.snapshot.queryParams

        let errorFilter = new ErrorFilter()
        errorFilter.startDate = moment(this.selectedDateRange.startDate).toDate()
        errorFilter.endDate = moment(this.selectedDateRange.endDate).toDate()
        errorFilter.issueState = this.issueStateFormatter.issueStateFromDisplayName(query['state'])
        errorFilter.platform = this.platformFormatter.platformFromDisplayName(query['platform'])

        // If we have an application, we need to filter by it, this is set only in the application errors page
        if (this.application) {
            errorFilter.applicationId = this.application.id
        }

        // In case of workspace errors page, we need to apply the filter for the selected application.
        if (query['app']) {
            errorFilter.applicationId = query['app']
        }

        if (query['bundleIdentifier']) {
            errorFilter.bundleIdentifierId = this.errorFiltersResponse.bundleIdentifiers.find((bi) => { return bi.value == query['bundleIdentifier'] }).id
        }

        if (query['applicationVersion']) {
            errorFilter.applicationVersionId = this.errorFiltersResponse.applicationVersions.find((v) => { return v.bundleShortVersion == query['applicationVersion'] }).id
        }

        if (query['osVersion']) {
            errorFilter.osVersionId = this.errorFiltersResponse.osVersions.find((osVersion) => { return osVersion.version == query['osVersion'] }).id
        }

        return errorFilter
    }

    private initSelect2Components() {
        $('#applicationSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '180px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#platformSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '140px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#bundleIdentifierSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '240px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#applicationVersionSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '160px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#osVersionSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '140px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#issueStateSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '140px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678

        // Fix for select2 being expanded after clicking on clear button
        $('.select2').on("select2:unselecting", (event) => {
            $(event.target).val(null).trigger('change')
            event.preventDefault()
        })

        // Applications
        $('#applicationSelect').on('select2:select', (event) => {
            let application = this.errorFiltersResponse.applications.find((o) => { return `${o.name} ${this.platformFormatter.platformName(o.platform)}` == event.params.data.id })
            this.router.navigate([], { queryParams: { app: application.id }, queryParamsHandling: 'merge' })
        })
        $('#applicationSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { app: null }, queryParamsHandling: 'merge' })
        })

        // Bundle identifiers
        $('#bundleIdentifierSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { bundleIdentifier: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#bundleIdentifierSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { bundleIdentifier: null }, queryParamsHandling: 'merge' })
        })

        // Application versions
        $('#applicationVersionSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { applicationVersion: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#applicationVersionSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { applicationVersion: null }, queryParamsHandling: 'merge' })
        })

        // Os versions
        $('#osVersionSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { osVersion: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#osVersionSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { osVersion: null }, queryParamsHandling: 'merge' })
        })

        // Issue states
        $('#issueStateSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { state: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#issueStateSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { state: null }, queryParamsHandling: 'merge' })
        })

        // Platforms
        $('#platformSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { platform: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#platformSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { platform: null }, queryParamsHandling: 'merge' })
        })
    }

    private updateSelect2SelectionFromQuery() {
        if (!this.workspace || !this.errorFiltersResponse) { return }

        let query = this.route.snapshot.queryParams

        $('#bundleIdentifierSelect').val(query['bundleIdentifier']).trigger('change')
        $('#applicationVersionSelect').val(query['applicationVersion']).trigger('change')
        $('#osVersionSelect').val(query['osVersion']).trigger('change')
        $('#issueStateSelect').val(query['state']).trigger('change')

        if (query['platform']) {
            $('#platformSelect').val(query['platform']).trigger('change')
        } else {
            $('#platformSelect').val(null).trigger('change')
        }

        if (query['app']) {
            let application = this.errorFiltersResponse.applications.find((a) => { return a.id == query['app'] })
            if (application) { $('#applicationSelect').val(application.name + ' ' + this.platformFormatter.platformName(application.platform)).trigger('change') }
        } else {
            $('#applicationSelect').val(null).trigger('change')
        }
    }

}
