<div class="justify-content-center text-muted d-flex w-100 align-items-center" style="height: 90vh" *ngIf="!networkFiltersResponse">
    <div class="align-self-center">
        <i class="icon-spinner4 spinner mr-2"></i> Loading network information...
    </div>
</div>

<ng-container *ngIf="networkFiltersResponse">
    <div class="card-body d-flex align-items-center align-items-baseline">
        <div class="d-flex align-items-baseline">
            <h5 class="mb-0 pb-0 mr-2">Filters</h5>

            <div class="d-flex align-items-center" style="flex-wrap: wrap;">
                <div class="form-group mb-0 d-flex mr-1 mb-1" *ngIf="backendEnvironment == null && networkFiltersResponse.environments.length > 0">
                    <select id="environmentSelect" select2 data-placeholder="Environment" data-allow-clear="true" class="form-control select2">
                        <option *ngFor="let environment of networkFiltersResponse.environments">{{environment.name}}</option>
                    </select>
                </div>

                <div class="form-group mb-0 d-flex mr-1 mb-1" *ngIf="networkFiltersResponse.environmentVersions.length > 0">
                    <select id="environmentVersionSelect" select2 data-placeholder="Env. version" data-allow-clear="true" class="form-control select2">
                        <option *ngFor="let environmentVersion of networkFiltersResponse.environmentVersions">{{environmentVersion.version}}</option>
                    </select>
                </div>

                <div class="form-group mb-0 d-flex mr-1 mb-1" *ngIf="networkFiltersResponse.networkHosts.length > 0">
                    <select id="networkHostSelect" select2 data-placeholder="Domain" data-allow-clear="true" class="form-control select2">
                        <option *ngFor="let networkHost of networkFiltersResponse.networkHosts">{{networkHost.name}}</option>
                    </select>
                </div>

                <div class="form-group mb-0 d-flex mr-1 mb-1" *ngIf="application == null && networkFiltersResponse.applications.length > 0">
                    <select id="applicationSelect" select2 data-placeholder="App" data-allow-clear="true" class="form-control select2">
                        <option *ngFor="let application of networkFiltersResponse.applications">{{application.name}} {{platformFormatter.platformName(application.platform)}}</option>
                    </select>
                </div>

                <div class="form-group mb-0 d-flex mr-1 mb-1" *ngIf="networkFiltersResponse.applicationVersions.length > 0">
                    <select id="applicationVersionSelect" select2 data-placeholder="App version" data-allow-clear="true" class="form-control select2">
                        <option *ngFor="let applicationVersion of networkFiltersResponse.applicationVersions">{{applicationVersion.bundleShortVersion}}</option>
                    </select>
                </div>

                <div class="form-group mb-0 d-flex mr-1 mb-1" *ngIf="networkFiltersResponse.networkOperations.length > 0">
                    <select id="networkOperationSelect" select2 data-placeholder="Operation" data-allow-clear="true" class="form-control select2">
                        <option *ngFor="let networkOperation of networkFiltersResponse.networkOperations">{{networkOperationOptionName(networkOperation)}}</option>
                    </select>
                </div>

                <div class="form-group mb-0 d-flex mr-1 mb-1" *ngIf="networkFiltersResponse.responseStatusCodes.length > 0">
                    <select id="responseStatusCodeSelect" select2 data-placeholder="Status" data-allow-clear="true" class="form-control select2">
                        <option *ngFor="let statusCode of networkFiltersResponse.responseStatusCodes">{{statusCode}}</option>
                    </select>
                </div>

                <div class="form-group mb-0 d-flex mr-1 mb-1" *ngIf="networkFiltersResponse.networkErrors.length > 0">
                    <select id="networkErrorSelect" select2 data-placeholder="Error" data-allow-clear="true" class="form-control select2">
                        <option *ngFor="let networkError of networkFiltersResponse.networkErrors">{{networkError.specifier}}</option>
                    </select>
                </div>

                <div class="form-group mb-0 d-flex mr-1 mb-1" *ngIf="networkFiltersResponse.networkInterfaces.length > 0">
                    <select id="networkInterfaceSelect" select2 data-placeholder="Radio" data-allow-clear="true" class="form-control select2">
                        <option *ngFor="let networkInterface of networkFiltersResponse.networkInterfaces">{{networkInterfaceFormatter.displayName(networkInterface)}}</option>
                    </select>
                </div>

                <div class="form-group mb-0 d-flex mr-1 mb-1" *ngIf="networkFiltersResponse.networkProtocols.length > 0">
                    <select id="networkProtocolSelect" select2 data-placeholder="Protocol" data-allow-clear="true" class="form-control select2">
                        <option *ngFor="let networkProtocol of networkFiltersResponse.networkProtocols">{{networkProtocol.name}}</option>
                    </select>
                </div>
            </div>
        </div>

        <ul class="ml-auto pagination">
            <li class="page-item active">
                <date-range [dateRange]="this.selectedDateRange" (onDateRangeChange)="onDateRangeChanged($event)"></date-range>
            </li>
        </ul>
    </div>

    <div class="card-body">
        <network-trends (onNetworkTrendTypeChanged)="onNetworkTrendTypeChanged($event)" (onDateRangeChange)="onDateRangeChanged($event)" [networkTrendType]="networkTrendType" [networkTrendsResponse]="networkTrendsResponse" [networkTrendsSummaryResponse]="networkTrendsSummaryResponse"></network-trends>
    </div>

    <div class="card-body d-flex align-items-baseline pb-0">
        <h5 class="mb-0 pb-0 mr-2">Dimensions</h5>
        <ul class="nav nav-pills nav-pills-bordered">
            <li class="nav-item ml-0 mr-1 mb-1">
                <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : networkDimension == NetworkDimension.Overview }" (click)="setNetworkDimension(NetworkDimension.Overview)">Overview</a>
            </li>
            <li class="nav-item ml-0 mr-1 mb-1">
                <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : networkDimension == NetworkDimension.NetworkHosts }" (click)="setNetworkDimension(NetworkDimension.NetworkHosts)">Domains</a>
            </li>
            <li class="nav-item ml-0 mr-1 mb-1">
                <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : networkDimension == NetworkDimension.Operations }" (click)="setNetworkDimension(NetworkDimension.Operations)">Operations</a>
            </li>
            <li class="nav-item ml-0 mr-1 mb-1">
                <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : networkDimension == NetworkDimension.ResponseCodes }" (click)="setNetworkDimension(NetworkDimension.ResponseCodes)">Responses</a>
            </li>
            <li class="nav-item ml-0 mr-1 mb-1" *ngIf="backendEnvironment == null">
                <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : networkDimension == NetworkDimension.Environments }" (click)="setNetworkDimension(NetworkDimension.Environments)">Environments</a>
            </li>
            <li class="nav-item ml-0 mr-1 mb-1">
                <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : networkDimension == NetworkDimension.EnvironmentVersions }" (click)="setNetworkDimension(NetworkDimension.EnvironmentVersions)">Env. versions</a>
            </li>
            <li class="nav-item ml-0 mr-1 mb-1" *ngIf="application == null">
                <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : networkDimension == NetworkDimension.Applications }" (click)="setNetworkDimension(NetworkDimension.Applications)">Apps</a>
            </li>
            <li class="nav-item ml-0 mr-1 mb-1">
                <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : networkDimension == NetworkDimension.ApplicationVersions }" (click)="setNetworkDimension(NetworkDimension.ApplicationVersions)">App versions</a>
            </li>
            <li class="nav-item ml-0 mr-1 mb-1">
                <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : networkDimension == NetworkDimension.NetworkInterfaces }" (click)="setNetworkDimension(NetworkDimension.NetworkInterfaces)">Radios</a>
            </li>
            <li class="nav-item ml-0 mr-1 mb-1">
                <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : networkDimension == NetworkDimension.NetworkProtocols }" (click)="setNetworkDimension(NetworkDimension.NetworkProtocols)">Protocols</a>
            </li>
        </ul>
    </div>

    <network-segments (onFindNetworkLog)="onFindNetworkLog($event)" (onEditNetworkOperation)="onEditNetworkOperation($event)" [networkDimension]="networkDimension" [networkSegments]="networkSegments"></network-segments>
</ng-container>

<request-preview-modal #requestPreviewModal></request-preview-modal>
<edit-network-operation-modal #editNetworkOperationModal (networkOperationUpdated)="onNetworkOperationUpdated()"></edit-network-operation-modal>
