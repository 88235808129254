import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertService, ApplicationDistributionsService, AuthTokenService, AuthenticationService } from 'app/_services'
import { ActivatedRoute } from '@angular/router';
import { Build, ApplicationDistribution, Workspace } from 'app/_models';
import { Subscription } from 'rxjs';
import { DateFormatter, PlatformFormatter } from 'app/_helpers';
import { DomSanitizer, SafeUrl, Title } from '@angular/platform-browser';

@Component({
    templateUrl: 'application.distributions.component.html',
})
export class ApplicationDistributionsComponent implements OnInit, OnDestroy {

    workspace: Workspace | null
    applicationDistributions: ApplicationDistribution[]

    private routeParamsSubscription: Subscription

    constructor(
        public platformFormatter: PlatformFormatter,
        public dateFormatter: DateFormatter,
        private authenticationService: AuthenticationService,
        private applicationDistributionsService: ApplicationDistributionsService,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private sanitizer: DomSanitizer,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.routeParamsSubscription = this.route.params.subscribe(params => {
            this.workspace = this.authenticationService.currentWorkspaceValue
            this.reloadApplicationDistributions()
        })
    }

    ngOnDestroy() {
        this.routeParamsSubscription?.unsubscribe()
    }

    buildInstallUrl(build: Build): SafeUrl {
        if (build.binary == null) {
            return null
        }

        return this.sanitizer.bypassSecurityTrustUrl(build.installUrl.toString())
    }

    private reloadApplicationDistributions() {
        this.applicationDistributions = null

        let workspaceSlug = this.route.snapshot.params['workspaceSlug']

        this.applicationDistributionsService.getApplicationDistributions(workspaceSlug).then((response) => {
            this.applicationDistributions = response.data

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
