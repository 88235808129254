<div class="justify-content-center text-muted d-flex w-100 align-items-center" style="height: 90vh" *ngIf="!didFinishInitialLoading">
    <div class="align-self-center">
        <i class="icon-spinner4 spinner mr-2"></i> Loading...
    </div>
</div>

<div class="row" *ngIf="didFinishInitialLoading">
    <div class="col-md-12">
        <div class="card mb-1">
            <div class="page-header border-bottom-light">
                <div class="page-header-content header-elements-md-inline">
                    <div class="page-title">
                        <h1 class="font-weight-thin">
                            <a [routerLink]="['../../']" class="breadcrumb-item" *ngIf="application">{{application.name}}</a>
                            <a [routerLink]="['../']" class="breadcrumb-item">Error Groups</a>
                            <span class="breadcrumb-item active">#{{errorGroup.serialNumber}}</span>
                        </h1>

                        <div class="mb-0" *ngIf="errorTrendsSummaryResponse">
                            <!-- <span class="badge badge-light badge-pill mr-1"><count [count]="errorTrendsSummaryResponse.totalErrorsCount"></count> reports</span>
                                        <span class="badge badge-light badge-pill mr-1"><count [count]="errorTrendsSummaryResponse.totalDevicesCount"></count> users</span> -->
                            <!-- <span class="badge badge-light badge-pill mr-1">App versions: {{errorGroup.affectedVersions}}</span> -->
                            <span class="badge badge-light badge-pill mr-1">Latest report: {{dateFormatter.timeAgoShort(errorTrendsSummaryResponse.latestErrorDate)}}</span>
                            <span class="badge badge-light badge-pill mr-1" *ngIf="errorGroup.fixVersion">Marked as fixed in: <span class="font-weight-semibold">{{errorGroup.fixVersion}}</span></span>
                        </div>
                        <div class="mb-0" *ngIf="errorTrendsSummaryResponse == null">
                            <span class="badge badge-light">Loading...</span>
                        </div>
                    </div>

                    <div class="header-elements d-flex">
                        <div class="dropdown">
                            <button type="button" class="btn btn-outline bg-primary border-primary text-primary-800 btn-icon dropdown-toggle ml-2" data-toggle="dropdown">
                                {{issueStateFormatter.displayName(errorGroup.state)}}
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <a href="javascript:void(0)" (click)="updateIssueState(IssueState.Open)" class="dropdown-item" [ngClass]="{ 'active' : errorGroup.state == 1 }">{{issueStateFormatter.displayName(IssueState.Open)}}</a>
                                <a href="javascript:void(0)" (click)="updateIssueState(IssueState.Closed)" class="dropdown-item d-block" [ngClass]="{ 'active' : errorGroup.state == 0 }">
                                    <ng-container *ngIf="errorGroup.state == IssueState.Closed && errorGroup.fixVersion != null">Fixed in: {{errorGroup.fixVersion}}</ng-container>
                                    <ng-container *ngIf="errorGroup.state != IssueState.Closed || errorGroup.fixVersion == null">{{issueStateFormatter.displayName(IssueState.Closed)}}</ng-container>
                                </a>
                                <a href="javascript:void(0)" (click)="updateIssueState(IssueState.Ignored)" class="dropdown-item" [ngClass]="{ 'active' : errorGroup.state == 2 }">{{issueStateFormatter.displayName(IssueState.Ignored)}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- NOTES -->
            <div class="card-body">
                <h5 class="mb-0 pb-1">Notes</h5>

                <ng-container *ngIf="errorGroup.annotation">
                    <markdown [content]="errorGroup.annotation"></markdown>
                    <ul class="list-inline list-inline-dotted list-inline-condensed mt-1 mb-0">
                        <li class="list-inline-item"><a href="javascript:void(0)" (click)="onAnnotateErrorGroupClick()" class="font-weight-semibold">Edit annotation</a></li>
                        <li class="list-inline-item"><span class="text-muted font-size-sm font-weight-light">Updated {{dateFormatter.timeAgo(errorGroup.annotationUpdateDate)}}</span></li>
                    </ul>
                </ng-container>

                <ng-container *ngIf="errorGroup.annotation == null">
                    <a href="javascript:void(0)" (click)="onAnnotateErrorGroupClick()" class="font-weight-semibold">Annotate this error group</a>
                    <span class="text-muted"> with contextual information and links to an issue tracking system like JIRA or Trello.</span>
                </ng-container>
            </div>

            <!-- ERROR MESSAGE -->
            <div class="card-body">
                <h5 class="mb-0 pb-1 mr-2">Sample error message</h5>
                <div class="mb-1"><samp>{{errorGroup.errorMessage}}</samp></div>
            </div>

            <!-- FILTERS -->
            <div class="card-body d-flex align-items-center align-items-baseline">
                <div class="d-flex align-items-baseline">
                    <h5 class="mb-0 pb-0 mr-2">Filters</h5>

                    <div class="form-group mb-0 d-flex mr-2" *ngIf="errorFiltersResponse.applications.length > 1">
                        <select id="applicationSelect" select2 data-placeholder="App" data-allow-clear="true" class="form-control select2">
                            <option *ngFor="let application of errorFiltersResponse.applications">{{application.name}} {{platformFormatter.platformName(application.platform)}}</option>
                        </select>
                    </div>

                    <div class="form-group mb-0 d-flex mr-2" *ngIf="errorFiltersResponse.bundleIdentifiers.length > 0">
                        <select id="bundleIdentifierSelect" select2 data-placeholder="App identifier" data-allow-clear="true" class="form-control select2">
                            <option *ngFor="let bundleIdentifier of errorFiltersResponse.bundleIdentifiers">{{bundleIdentifier.value}}</option>
                        </select>
                    </div>

                    <div class="form-group mb-0 d-flex mr-2" *ngIf="errorFiltersResponse.applicationVersions.length > 0">
                        <select id="applicationVersionSelect" select2 data-placeholder="App version" data-allow-clear="true" class="form-control select2">
                            <option *ngFor="let applicationVersion of errorFiltersResponse.applicationVersions">{{applicationVersion.bundleShortVersion}}</option>
                        </select>
                    </div>

                    <div class="form-group mb-0 d-flex mr-2" *ngIf="errorFiltersResponse.osVersions.length > 0">
                        <select id="osVersionSelect" select2 data-placeholder="OS version" data-allow-clear="true" class="form-control select2">
                            <option *ngFor="let osVersion of errorFiltersResponse.osVersions">{{osVersion.version}}</option>
                        </select>
                    </div>
                </div>

                <ul class="ml-auto pagination">
                    <li class="page-item active">
                        <date-range [dateRange]="this.selectedDateRange" (onDateRangeChange)="onDateRangeChanged($event)"></date-range>
                    </li>
                </ul>
            </div>

            <!-- TRENDS -->
            <div class="card-body py-0 my-0">
                <!-- <h5 class="mb-0 pb-0">Trends</h5> -->

                <!-- TRENDS LOADING -->
                <div class="justify-content-center text-muted d-flex w-100 align-items-center" style="min-height: 280px" *ngIf="!errorTrendsResponse || !errorTrendsSummaryResponse">
                    <div class="align-self-center">
                        <i class="icon-spinner4 spinner mr-2"></i> Loading trends...
                    </div>
                </div>

                <!-- TRENDS LOADED -->
                <div class="d-flex" *ngIf="errorTrendsResponse && errorTrendsSummaryResponse">

                    <!-- TRENDS -->
                    <div style="width: calc(100vw - 290px); margin-top: 20px; margin-bottom: 20px;">
                        <errors-over-time-chart [datePoints]="errorTrendsResponse.datePoints" [errorCounts]="errorTrendsResponse.errorCounts" (onDateRangeChange)="onDateRangeChanged($event)"></errors-over-time-chart>
                    </div>

                    <!-- SUMMARY -->
                    <div class="border-left-light pl-3" style="padding-top: 20px;">
                        <h5 class="mb-0 pb-3">Summary</h5>

                        <div style="width: 180px;">
                            <error-trends-summary [errorTrendsSummaryResponse]="errorTrendsSummaryResponse"></error-trends-summary>
                        </div>
                    </div>
                </div>
            </div>

            <!-- DIMENSIONS -->
            <div class="card-body d-flex align-items-baseline" *ngIf="errorFiltersResponse.applications.length > 1">
                <h5 class="mb-0 pb-0">Dimensions</h5>
                <ul class="nav nav-pills nav-pills-bordered ml-2 my-0">
                    <li class="nav-item ml-1">
                        <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : errorGroupDimension == ErrorGroupDimension.ErrorReports }" (click)="setErrorGroupDimension(ErrorGroupDimension.ErrorReports)">Error reports</a>
                    </li>

                    <li class="nav-item ml-1" *ngIf="errorFiltersResponse.applications.length > 1">
                        <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : errorGroupDimension == ErrorGroupDimension.Applications }" (click)="setErrorGroupDimension(ErrorGroupDimension.Applications)">Applications</a>
                    </li>
                </ul>
            </div>

            <!-- SEGMENTS -->

            <!-- ERROR REPORTS LIST -->
            <ng-container *ngIf="errorGroupDimension == ErrorGroupDimension.ErrorReports">
                <table class="table" *ngIf="errorReports && errorReports.length > 0">
                    <thead>
                        <tr role="row">
                            <th class="border-bottom-0 nowrap w-100">Error reports</th>
                            <th class="border-bottom-0 nowrap text-center">Date</th>
                            <th class="border-bottom-0 nowrap text-center">App version</th>
                            <th class="border-bottom-0 nowrap text-center">OS</th>
                            <th class="border-bottom-0 nowrap text-center">Device</th>
                            <th class="border-bottom-0 nowrap text-center">Locale</th>
                            <th class="border-bottom-0 nowrap text-right">Attachment</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let errorReport of errorReports" (click)="navigateToErrorReport(errorReport)">
                            <td class="d-flex">
                                <div class="mr-2">
                                    <span class="badge badge-mark border-warning-300"></span>
                                </div>
                                <div>
                                    <a class="font-weight-semibold button-muted nowrap" [routerLink]="['error-reports/', errorReport.serialNumber]">
                                        #{{errorReport.serialNumber}}
                                    </a>
                                    <div class="text-muted breakall">{{errorReport.errorMessage}}</div>
                                </div>
                            </td>
                            <td class="nowrap text-center">
                                <span class="text-muted breakall">{{dateFormatter.dateTimeShort(errorReport.errorDate)}}</span>
                            </td>
                            <td class="nowrap text-center">
                                <span class="badge badge-light badge-pill mr-1">{{errorReport.appVersion}}</span>
                            </td>
                            <td class="nowrap text-center">{{errorReport.system}}</td>
                            <td class="nowrap text-center">{{errorReport.deviceModelName}}</td>
                            <td class="nowrap text-right">{{errorReport.locale}}</td>
                            <td class="text-center" [ngClass]="{ 'text-muted' : errorReport.attachment == null }">
                                <i class="icon-attachment" *ngIf="errorReport.attachment != null"></i>
                                <span class="text-muted" *ngIf="errorReport.attachment == null">-</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>

            <!-- APPLICATION SEGMENTS -->
            <ng-container *ngIf="errorGroupDimension == ErrorGroupDimension.Applications">
                <table class="table" *ngIf="errorApplicationSegments && errorApplicationSegments.length > 0">
                    <thead>
                        <tr role="row">
                            <th class="border-bottom-0 w-100">Application</th>
                            <th class="border-bottom-0 nowrap text-right">
                                <sortable [name]="'Errors'" [ascValue]="ErrorSegmentsOrder.COUNT_ASC" [descValue]="ErrorSegmentsOrder.COUNT_DESC" [default]="ErrorSegmentsOrder.COUNT_DESC"></sortable>
                            </th>
                            <th class="border-bottom-0 nowrap text-right">
                                <sortable [name]="'Users'" [ascValue]="ErrorSegmentsOrder.AFFECTED_USERS_ASC" [descValue]="ErrorSegmentsOrder.AFFECTED_USERS_DESC"></sortable>
                            </th>
                            <th class="border-bottom-0 nowrap text-center">App versions</th>
                            <th class="border-bottom-0 nowrap text-right">First seen</th>
                            <th class="border-bottom-0 nowrap text-right">Last seen</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let errorApplicationSegment of errorApplicationSegments">
                            <td class="hover-expendable">
                                <div class="d-flex button-muted align-items-center">
                                    <app-icon class="mr-2" [application]="errorApplicationSegment.application" [enableAppearanceAnimations]="false" style="width: 40px; height: 40px;;"></app-icon>
                                    <div>{{errorApplicationSegment.application.name}} <span class="text-muted">{{platformFormatter.platformName(errorApplicationSegment.application.platform)}}</span></div>
                                    <a [routerLink]="['./']" [queryParams]="{app: errorApplicationSegment.application.id}" queryParamsHandling="merge" class="hover-target badge badge-flat badge-pill border-primary text-primary ml-2">
                                        <i class="icon-filter3 mr-1" style="font-size: 90%;"></i>Add to filter
                                    </a>
                                </div>
                            </td>
                            <td class="text-right">
                                <count [count]="errorApplicationSegment.errorsCount"></count>
                            </td>
                            <td class="text-right">
                                <count [count]="errorApplicationSegment.distinctDeviceIds"></count>
                            </td>
                            <td class="text-center" *ngIf="errorApplicationSegment.earliestErrorVersion == errorApplicationSegment.latestErrorVersion">
                                <span class="badge badge-light badge-pill">{{errorApplicationSegment.earliestErrorVersion}}</span>
                            </td>
                            <td class="text-center nowrap" *ngIf="errorApplicationSegment.earliestErrorVersion != errorApplicationSegment.latestErrorVersion">
                                <span class="badge badge-light badge-pill">{{errorApplicationSegment.earliestErrorVersion}}</span>…<span class="badge badge-light badge-pill">{{errorApplicationSegment.latestErrorVersion}}</span>
                            </td>
                            <td class="nowrap text-right">{{dateFormatter.timeAgoShort(errorApplicationSegment.earliestErrorDate)}}</td>
                            <td class="nowrap text-right">{{dateFormatter.timeAgoShort(errorApplicationSegment.latestErrorDate)}}</td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>

        </div>
    </div>
</div>

<edit-error-group-modal #editErrorGroupModal [errorGroup]="errorGroup" (errorGroupUpdated)="onErrorGroupUpdated()"></edit-error-group-modal>
