import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy, HostListener } from '@angular/core';
import { AlertService, ApplicationService, BuildService, DistributionGroupsService } from 'app/_services'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Application, Build, DistributionGroup } from 'app/_models';
import { Subscription } from 'rxjs';
import { TagModel } from 'ngx-chips/core/accessor';

@Component({
    selector: 'distribute-build-modal',
    templateUrl: 'distribute.build.modal.html'
})
export class DistributeBuildModal implements OnInit, OnDestroy {

    form: FormGroup
    application: Application
    distributionGroups: DistributionGroup[]
    distributionGroupNames: string[]

    _build: Build
    @Input() set build(build: Build) {
        this._build = build
        if (build != null) {
            this.reloadDistributionGroups()
        }
    }
    get build() { return this._build }

    private currentApplicationSubscription: Subscription

    @ViewChild('closeButton') closeButton: ElementRef

    constructor(
        private alertService: AlertService,
        private distributionGroupsService: DistributionGroupsService,
        private buildService: BuildService,
        private applicationService: ApplicationService,
        private formBuilder: FormBuilder
    ) {
    }

    ngOnInit() {
        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
        })

        this.setupForm()
    }

    ngOnDestroy() {
        this.currentApplicationSubscription?.unsubscribe()
    }

    reloadDistributionGroups() {
        if (!this.application) { return }

        this.distributionGroupsService.getApplicationDistributionGroups(this.application.id).then((response) => {
            this.distributionGroups = response.data
            this.distributionGroupNames = this.distributionGroups.map((group) => { return group.name })

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    setupForm() {
        this.form = this.formBuilder.group({
            distributionGroups: [null, Validators.required],
            notifyTesters: [true, Validators.required]
        })
    }

    submitForm() {
        let tagModels = (this.form.controls.distributionGroups.value as TagModel[])
        var selectedDistributionGroups: DistributionGroup[] = []
        for (const tagModel of tagModels) {
            let tagValue = tagModel['value']

            let distributionGroup = this.distributionGroups.filter((group) => { return group.name == tagValue }).shift()
            selectedDistributionGroups.push(distributionGroup)
        }
        let selectedDistributionGroupIds = selectedDistributionGroups.map((group) => { return group.id })

        let notifyTesters = this.form.controls.notifyTesters.value

        this.buildService.distributeBuild(this.build.id, selectedDistributionGroupIds, notifyTesters).then((response) => {
            this.alertService.success("Build successfully distributed")
            this.closeForm()
            this.form.reset()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private closeForm() {
        this.closeButton.nativeElement.click()
    }

}
