<div class="navbar desktop-only navbar-expand navbar-light d-flex justify-content-between">
    <navbar-app-feature appFeatureName="Crashes"></navbar-app-feature>
    <navbar-user-menu></navbar-user-menu>
</div>

<mobile-warning class="mobile-only"></mobile-warning>

<div class="page-content desktop-only">

    <div class="content-wrapper" id="pageContent">

    <crash-reports-list></crash-reports-list>

</div>

<quick-jump-modal></quick-jump-modal>
