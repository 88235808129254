import { Component, OnInit } from '@angular/core';
import { Workspace } from 'app/_models';
import { AuthenticationService } from 'app/_services';
import { Title } from '@angular/platform-browser';

@Component({
    templateUrl: 'workspace.errors.component.html',
})
export class WorkspaceErrorsComponent implements OnInit {

    workspace: Workspace

    constructor(
        private authenticationService: AuthenticationService,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue
        this.titleService.setTitle(`${this.workspace.name} | Errors`)
    }

}
