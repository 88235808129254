import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Application, TestFailureGroup } from 'app/_models';
import { AlertService, ApplicationService, TestFailureGroupsService } from 'app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationFormatter, DateFormatter, IssueStateFormatter } from 'app/_helpers';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment'

@Component({
    templateUrl: 'test.failure.groups.component.html',
    styleUrls: [
        'test.failure.groups.component.css'
    ]
})
export class TestFailureGroupsComponent implements OnInit, OnDestroy {

    application: Application
    testFailureGroups: TestFailureGroup[]

    isLoadingMoreRecords = false
    hasMoreRecords = false

    @ViewChild('pageContent') pageContent: ElementRef

    private oldestFailureGroupDate: Date = null
    private currentApplicationSubscription: Subscription
    private resizeListener: any

    constructor(
        public dateFormatter: DateFormatter,
        public issueStateFormatter: IssueStateFormatter,
        private testFailureGroupsService: TestFailureGroupsService,
        private route: ActivatedRoute,
        private applicationService: ApplicationService,
        private alertService: AlertService,
        private router: Router,
        private applicationFormatter: ApplicationFormatter,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
            if (this.application != null) {
                this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | Failure Groups`)
                this.reloadTestFailureGroups()
            }
        })

        this.resizeListener = this.loadMoreResultsIfAtBottom.bind(this)
        window.addEventListener("resize", this.resizeListener)
    }

    ngOnDestroy() {
        this.currentApplicationSubscription?.unsubscribe()
        window.removeEventListener("resize", this.resizeListener)
    }

    onPageContentScroll(event: any) {
        this.loadMoreResultsIfAtBottom()
    }

    reloadTestFailureGroups() {
        this.isLoadingMoreRecords = true

        return this.testFailureGroupsService.getApplicationFailureGroups(this.application.id, this.oldestFailureGroupDate).then((response) => {
            this.testFailureGroups = response.data
            this.hasMoreRecords = response.hasMoreRecords

            if (this.testFailureGroups.length > 0) {
                this.oldestFailureGroupDate = moment(this.testFailureGroups[this.testFailureGroups.length - 1].creationDate).toDate()
                this.loadMoreResultsIfAtBottom()
            }

        }).catch((error) => {
            this.alertService.handleError(error)

        }).finally(() => {
            this.isLoadingMoreRecords = false
        })
    }

    navigateToTestFailureGroup(group: TestFailureGroup) {
        this.router.navigate([group.serialNumber], { relativeTo: this.route })
    }

    private loadMoreResultsIfAtBottom() {
        if (!this.application || !this.hasMoreRecords || this.isLoadingMoreRecords) {
            return
        }

        let pageContent = this.pageContent.nativeElement
        if (pageContent.offsetHeight + pageContent.scrollTop < pageContent.scrollHeight) {
            return
        }

        this.isLoadingMoreRecords = true
        this.testFailureGroupsService.getApplicationFailureGroupsPageBefore(this.application.id, this.oldestFailureGroupDate).then((response) => {
            this.testFailureGroups = this.testFailureGroups.concat(response.data)
            this.oldestFailureGroupDate = moment(this.testFailureGroups[this.testFailureGroups.length - 1].creationDate).toDate()
            this.hasMoreRecords = response.hasMoreRecords
            this.loadMoreResultsIfAtBottom()

        }).catch((error) => {
            this.alertService.handleError(error)

        }).finally(() => {
            this.isLoadingMoreRecords = false
        })
    }

}
