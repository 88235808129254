<ng-container *ngIf="workspace && errorFiltersResponse">

    <!-- FILTERS -->
    <div class="card-body d-flex align-items-center align-items-baseline">
        <div class="d-flex align-items-baseline">
            <h5 class="mb-0 pb-0 mr-2">Filters</h5>

            <div class="form-group mb-0 d-flex mr-2" *ngIf="errorFiltersResponse.applications.length > 1">
                <select id="applicationSelect" select2 data-placeholder="App" data-allow-clear="true" class="form-control select2">
                    <option *ngFor="let application of errorFiltersResponse.applications">{{application.name}} {{platformFormatter.platformName(application.platform)}}</option>
                </select>
            </div>

            <div class="form-group mb-0 d-flex mr-2" *ngIf="errorFiltersResponse.bundleIdentifiers.length > 0">
                <select id="bundleIdentifierSelect" select2 data-placeholder="App identifier" data-allow-clear="true" class="form-control select2">
                    <option *ngFor="let bundleIdentifier of errorFiltersResponse.bundleIdentifiers">{{bundleIdentifier.value}}</option>
                </select>
            </div>

            <div class="form-group mb-0 d-flex mr-2" *ngIf="errorFiltersResponse.platforms.length > 1">
                <select id="platformSelect" select2 data-placeholder="Platform" data-allow-clear="true" class="form-control select2">
                    <option *ngFor="let platform of errorFiltersResponse.platforms">{{platformFormatter.platformName(platform)}}</option>
                </select>
            </div>

            <div class="form-group mb-0 d-flex mr-2" *ngIf="errorFiltersResponse.applicationVersions.length > 0">
                <select id="applicationVersionSelect" select2 data-placeholder="App version" data-allow-clear="true" class="form-control select2">
                    <option *ngFor="let applicationVersion of errorFiltersResponse.applicationVersions">{{applicationVersion.bundleShortVersion}}</option>
                </select>
            </div>

            <div class="form-group mb-0 d-flex mr-2" *ngIf="errorFiltersResponse.osVersions.length > 0">
                <select id="osVersionSelect" select2 data-placeholder="OS version" data-allow-clear="true" class="form-control select2">
                    <option *ngFor="let osVersion of errorFiltersResponse.osVersions">{{osVersion.version}}</option>
                </select>
            </div>

            <div class="form-group mb-0 d-flex mr-2">
                <select id="issueStateSelect" select2 data-placeholder="Status" data-allow-clear="true" class="form-control select2">
                    <option>{{issueStateFormatter.displayName(IssueState.Open)}}</option>
                    <option>{{issueStateFormatter.displayName(IssueState.Ignored)}}</option>
                    <option>{{issueStateFormatter.displayName(IssueState.Closed)}}</option>
                </select>
            </div>
        </div>

        <ul class="ml-auto pagination">
            <li class="page-item active">
                <date-range [dateRange]="this.selectedDateRange" (onDateRangeChange)="onDateRangeChanged($event)"></date-range>
            </li>
        </ul>
    </div>

    <!-- TRENDS -->
    <div class="card-body d-flex py-0 my-0">

        <!-- TRENDS LOADING -->
        <div class="justify-content-center text-muted d-flex w-100 align-items-center" style="min-height: 280px" *ngIf="!errorTrendsResponse">
            <div class="align-self-center">
                <i class="icon-spinner4 spinner mr-2"></i> Loading error trends...
            </div>
        </div>

        <!-- TRENDS LOADED -->
        <div class="w-100" style="margin-top: 20px; margin-bottom: 20px;" *ngIf="errorTrendsResponse">
            <errors-over-time-chart [datePoints]="errorTrendsResponse.datePoints" [errorCounts]="errorTrendsResponse.errorCounts" (onDateRangeChange)="onDateRangeChanged($event)"></errors-over-time-chart>
        </div>

        <!-- SUMMARY LOADING -->
        <div class="justify-content-center text-muted d-flex align-items-center pl-3" *ngIf="!errorTrendsSummaryResponse">
            <div class="align-self-center" style="min-width: 180px;">
                <i class="icon-spinner4 spinner mr-1"></i> Loading summary...
            </div>
        </div>

        <!-- SUMMARY LOADED -->
        <div class="border-left-light pl-3" style="padding-top: 20px;" *ngIf="errorTrendsSummaryResponse">
            <h5 class="mb-0 pb-3">Summary</h5>
            <div style="width: 180px;">
                <error-trends-summary [errorTrendsSummaryResponse]="errorTrendsSummaryResponse"></error-trends-summary>
            </div>
        </div>
    </div>

    <!-- DIMENSIONS -->
    <div class="card-body d-flex align-items-baseline" *ngIf="errorFiltersResponse.applications.length > 1">
        <h5 class="mb-0 pb-0">Dimensions</h5>
        <ul class="nav nav-pills nav-pills-bordered ml-2 my-0">
            <li class="nav-item ml-1">
                <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : errorDimension == ErrorDimension.ErrorGroups }" (click)="setErrorDimension(ErrorDimension.ErrorGroups)">Error groups</a>
            </li>

            <li class="nav-item ml-1" *ngIf="errorFiltersResponse.applications.length > 1">
                <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : errorDimension == ErrorDimension.Applications }" (click)="setErrorDimension(ErrorDimension.Applications)">Applications</a>
            </li>

            <li class="nav-item ml-1" *ngIf="errorFiltersResponse.platforms.length > 1">
                <a href="javascript:void(0)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : errorDimension == ErrorDimension.Platforms }" (click)="setErrorDimension(ErrorDimension.Platforms)">Platforms</a>
            </li>
        </ul>
    </div>

    <!-- SEGMENTS -->
    <ng-container *ngIf="errorDimension == ErrorDimension.ErrorGroups">
        <table class="table" *ngIf="errorGroupSegments && errorGroupSegments.length > 0">
            <thead>
                <tr role="row">
                    <th class="border-0">#</th>
                    <th class="border-bottom-0 w-100">Error group</th>
                    <th class="border-bottom-0 nowrap text-right">
                        <sortable [name]="'Errors'" [ascValue]="ErrorSegmentsOrder.COUNT_ASC" [descValue]="ErrorSegmentsOrder.COUNT_DESC" [default]="ErrorSegmentsOrder.COUNT_DESC"></sortable>
                    </th>
                    <th class="border-bottom-0 nowrap text-right">
                        <sortable [name]="'Users'" [ascValue]="ErrorSegmentsOrder.AFFECTED_USERS_ASC" [descValue]="ErrorSegmentsOrder.AFFECTED_USERS_DESC"></sortable>
                    </th>
                    <th class="border-bottom-0 nowrap text-center">App versions</th>
                    <th class="border-bottom-0 nowrap text-center">First seen</th>
                    <th class="border-bottom-0 nowrap text-center">Last seen</th>
                    <th class="border-bottom-0 nowrap text-center">Annotation</th>
                    <th class="border-bottom-0 nowrap text-right">Status</th>
                </tr>
            </thead>
            <tbody>
                <tr class="hoverable" *ngFor="let errorGroupSegment of errorGroupSegments" (click)="navigateToErrorGroup(errorGroupSegment)">
                    <td>
                        <a [routerLink]="[errorGroupSegment.serialNumber]" class="button-muted mt-2 mb-2">#{{errorGroupSegment.serialNumber}}</a>
                    </td>
                    <td>
                        <div class="d-flex breakall">
                            <div class="mr-2">
                                <span class="badge badge-mark border-warning-300"></span>
                            </div>
                            <div class="button-muted breakall">{{errorGroupSegment.errorMessage}}</div>
                        </div>
                    </td>
                    <td class="text-right" [ngClass]="{ 'text-muted' : errorGroupSegment.state == 2 }">
                        <count [count]="errorGroupSegment.errorsCount"></count>
                    </td>
                    <td class="text-right" [ngClass]="{ 'text-muted' : errorGroupSegment.state == 2 }">
                        <count [count]="errorGroupSegment.distinctDeviceIds"></count>
                    </td>
                    <td class="text-center" *ngIf="errorGroupSegment.earliestErrorVersion == errorGroupSegment.latestErrorVersion">
                        <span class="badge badge-light badge-pill">{{errorGroupSegment.earliestErrorVersion}}</span>
                    </td>
                    <td class="text-center nowrap" *ngIf="errorGroupSegment.earliestErrorVersion != errorGroupSegment.latestErrorVersion">
                        <span class="badge badge-light badge-pill">{{errorGroupSegment.earliestErrorVersion}}</span>…<span class="badge badge-light badge-pill">{{errorGroupSegment.latestErrorVersion}}</span>
                    </td>
                    <td class="nowrap text-center" [ngClass]="{ 'text-muted' : errorGroupSegment.state == 2 }">{{dateFormatter.timeAgoShort(errorGroupSegment.earliestErrorDate)}}</td>
                    <td class="nowrap text-center" [ngClass]="{ 'text-muted' : errorGroupSegment.state == 2 }">{{dateFormatter.timeAgoShort(errorGroupSegment.latestErrorDate)}}</td>
                    <td class="text-center" [ngClass]="{ 'text-muted' : errorGroupSegment.state == 2 }">
                        <i class="icon-attachment" *ngIf="errorGroupSegment.hasAnnotation"></i>
                        <span class="text-muted" *ngIf="errorGroupSegment.hasAnnotation == false">-</span>
                    </td>
                    <td class="text-right" [ngClass]="{ 'text-success-600' : errorGroupSegment.state == 1, 'text-muted' : errorGroupSegment.state == 2 }">{{issueStateFormatter.displayName(errorGroupSegment.state)}}</td>
                </tr>
            </tbody>
        </table>
    </ng-container>

    <ng-container *ngIf="errorDimension == ErrorDimension.Applications">
        <table class="table" *ngIf="errorApplicationSegments && errorApplicationSegments.length > 0">
            <thead>
                <tr role="row">
                    <th class="border-bottom-0 w-100">Application</th>
                    <th class="border-bottom-0 nowrap text-right">
                        <sortable [name]="'Errors'" [ascValue]="ErrorSegmentsOrder.COUNT_ASC" [descValue]="ErrorSegmentsOrder.COUNT_DESC" [default]="ErrorSegmentsOrder.COUNT_DESC"></sortable>
                    </th>
                    <th class="border-bottom-0 nowrap text-right">
                        <sortable [name]="'Users'" [ascValue]="ErrorSegmentsOrder.AFFECTED_USERS_ASC" [descValue]="ErrorSegmentsOrder.AFFECTED_USERS_DESC"></sortable>
                    </th>
                    <th class="border-bottom-0 nowrap text-center">App versions</th>
                    <th class="border-bottom-0 nowrap text-right">First seen</th>
                    <th class="border-bottom-0 nowrap text-right">Last seen</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let errorApplicationSegment of errorApplicationSegments">
                    <td class="hover-expendable">
                        <div class="d-flex button-muted align-items-center">
                            <app-icon class="mr-2" [application]="errorApplicationSegment.application" [enableAppearanceAnimations]="false" style="width: 40px; height: 40px;;"></app-icon>
                            <div>{{errorApplicationSegment.application.name}} <span class="text-muted">{{platformFormatter.platformName(errorApplicationSegment.application.platform)}}</span></div>
                            <a [routerLink]="['./']" [queryParams]="{app: errorApplicationSegment.application.id}" queryParamsHandling="merge" class="hover-target badge badge-flat badge-pill border-primary text-primary ml-2">
                                <i class="icon-filter3 mr-1" style="font-size: 90%;"></i>Add to filter
                            </a>
                        </div>
                    </td>
                    <td class="text-right">
                        <count [count]="errorApplicationSegment.errorsCount"></count>
                    </td>
                    <td class="text-right">
                        <count [count]="errorApplicationSegment.distinctDeviceIds"></count>
                    </td>
                    <td class="text-center" *ngIf="errorApplicationSegment.earliestErrorVersion == errorApplicationSegment.latestErrorVersion">
                        <span class="badge badge-light badge-pill">{{errorApplicationSegment.earliestErrorVersion}}</span>
                    </td>
                    <td class="text-center nowrap" *ngIf="errorApplicationSegment.earliestErrorVersion != errorApplicationSegment.latestErrorVersion">
                        <span class="badge badge-light badge-pill">{{errorApplicationSegment.earliestErrorVersion}}</span>…<span class="badge badge-light badge-pill">{{errorApplicationSegment.latestErrorVersion}}</span>
                    </td>
                    <td class="nowrap text-right">{{dateFormatter.timeAgoShort(errorApplicationSegment.earliestErrorDate)}}</td>
                    <td class="nowrap text-right">{{dateFormatter.timeAgoShort(errorApplicationSegment.latestErrorDate)}}</td>
                </tr>
            </tbody>
        </table>
    </ng-container>

    <ng-container *ngIf="errorDimension == ErrorDimension.Platforms">
        <table class="table" *ngIf="errorPlatformSegments && errorPlatformSegments.length > 0">
            <thead>
                <tr role="row">
                    <th class="border-bottom-0 w-100">Platform</th>
                    <th class="border-bottom-0 nowrap text-right">
                        <sortable [name]="'Errors'" [ascValue]="ErrorSegmentsOrder.COUNT_ASC" [descValue]="ErrorSegmentsOrder.COUNT_DESC" [default]="ErrorSegmentsOrder.COUNT_DESC"></sortable>
                    </th>
                    <th class="border-bottom-0 nowrap text-right">
                        <sortable [name]="'Users'" [ascValue]="ErrorSegmentsOrder.AFFECTED_USERS_ASC" [descValue]="ErrorSegmentsOrder.AFFECTED_USERS_DESC"></sortable>
                    </th>
                    <th class="border-bottom-0 nowrap text-center">App versions</th>
                    <th class="border-bottom-0 nowrap text-right">First seen</th>
                    <th class="border-bottom-0 nowrap text-right">Last seen</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let errorPlatformSegment of errorPlatformSegments">
                    <td class="hover-expendable">
                        <div class="d-flex button-muted align-items-center">
                            <i class="{{platformFormatter.platformIconName(errorPlatformSegment.platform)}}"></i> <span class="ml-1">{{platformFormatter.platformName(errorPlatformSegment.platform)}}</span>
                            <a [routerLink]="['./']" [queryParams]="{platform: this.platformFormatter.platformName(errorPlatformSegment.platform)}" queryParamsHandling="merge" class="hover-target badge badge-flat badge-pill border-primary text-primary ml-2">
                                <i class="icon-filter3 mr-1" style="font-size: 90%;"></i>Add to filter
                            </a>
                        </div>
                    </td>
                    <td class="text-right">
                        <count [count]="errorPlatformSegment.errorsCount"></count>
                    </td>
                    <td class="text-right">
                        <count [count]="errorPlatformSegment.distinctDeviceIds"></count>
                    </td>
                    <td class="text-center" *ngIf="errorPlatformSegment.earliestErrorVersion == errorPlatformSegment.latestErrorVersion">
                        <span class="badge badge-light badge-pill">{{errorPlatformSegment.earliestErrorVersion}}</span>
                    </td>
                    <td class="text-center nowrap" *ngIf="errorPlatformSegment.earliestErrorVersion != errorPlatformSegment.latestErrorVersion">
                        <span class="badge badge-light badge-pill">{{errorPlatformSegment.earliestErrorVersion}}</span>…<span class="badge badge-light badge-pill">{{errorPlatformSegment.latestErrorVersion}}</span>
                    </td>
                    <td class="nowrap text-right">{{dateFormatter.timeAgoShort(errorPlatformSegment.earliestErrorDate)}}</td>
                    <td class="nowrap text-right">{{dateFormatter.timeAgoShort(errorPlatformSegment.latestErrorDate)}}</td>
                </tr>
            </tbody>
        </table>
    </ng-container>

</ng-container>
