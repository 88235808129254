import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ErrorReport, Application, ErrorGroup, CallStack, Workspace } from 'app/_models';
import { ErrorsService, AlertService, ApplicationService, AuthenticationService } from 'app/_services';
import { ByteCountFormatter, PlatformFormatter, DateFormatter, ApplicationFormatter } from 'app/_helpers';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ApplicationLogsComponent } from 'app/_components';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { EditErrorGroupModal } from '../edit.error.group.modal/edit.error.group.modal';

@Component({
    templateUrl: 'error.report.component.html'
})
export class ErrorReportComponent implements OnInit, OnDestroy {

    workspace: Workspace
    application: Application
    errorGroup: ErrorGroup
    errorReport: ErrorReport
    callStack: CallStack
    attachment: string | null

    @ViewChild('applicationLogs') applicationLogs: ApplicationLogsComponent
    @ViewChild('editErrorGroupModal') editErrorGroupModal: EditErrorGroupModal

    private currentApplicationSubscription: Subscription
    private currentErrorGroupSubscription: Subscription
    private routeParamsSubscription: Subscription

    constructor(
        public dateFormatter: DateFormatter,
        public byteCountFormatter: ByteCountFormatter,
        public platformFormatter: PlatformFormatter,
        private route: ActivatedRoute,
        private http: HttpClient,
        private errorsService: ErrorsService,
        private applicationService: ApplicationService,
        private alertService: AlertService,
        private authenticationService: AuthenticationService,
        private applicationFormatter: ApplicationFormatter,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue
        this.loadErrorReport()

        this.routeParamsSubscription = this.route.params.subscribe(params => {
            this.loadErrorReport()
        })

        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
        })

        this.currentErrorGroupSubscription = this.errorsService.currentErrorGroup.subscribe((errorGroup) => {
            this.errorGroup = errorGroup
            if (this.errorGroup != null) {
                this.loadErrorReport()
            }
        })
    }

    ngOnDestroy() {
        this.currentApplicationSubscription?.unsubscribe()
        this.currentErrorGroupSubscription?.unsubscribe()
        this.routeParamsSubscription?.unsubscribe()
    }

    onAnnotateButtonClick() {
        this.editErrorGroupModal.errorGroup = this.errorGroup
    }

    onErrorGroupUpdated() {
        this.reloadErrorGroup()
    }

    private reloadErrorGroup() {
        if (!this.errorGroup) { return }

        // Here we can fetch based on error group id because it will only be called once the same group is updated
        this.errorsService.getErrorGroupById(this.errorGroup.id).then((response) => {
            this.errorsService.setCurrentErrorGroup(response.data)

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private loadErrorReport() {
        if (!this.workspace || !this.errorGroup) { return }

        let errorReportSlug = this.route.snapshot.params['errorReportSlug']
        if (errorReportSlug == null || (this.errorReport && this.errorReport.serialNumber == errorReportSlug)) {
            return
        }

        if (this.application) {
            this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | Error Report #${errorReportSlug}`)
        } else {
            this.titleService.setTitle(`${this.workspace.name} | Error Report #${errorReportSlug}`)
        }

        this.errorsService.getErrorGroupErrorReportWithSlug(this.errorGroup.id, errorReportSlug).then((errorReport) => {
            this.errorReport = errorReport.data
            this.reloadErrorReportDetails()
        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private reloadErrorReportDetails() {
        this.attachment = null
        if (!this.errorReport || !this.errorReport.attachment) { return }

        this.http.get(this.errorReport.attachment.url, { responseType: 'text' }).toPromise().then((result => {
            this.attachment = result

        })).catch(error => {
            this.alertService.handleError(error)
        })
    }

}
