import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { Application, CrashGroup, CrashReport, IssueState, CallStack, CrashTrendsResponse, CrashTrendsSummaryResponse, CrashFilter, DateRange, CrashSegmentsOrder, Workspace, CrashApplicationSegment, CrashGroupDimension, crashSegmentsOrderFromQuery, CrashDimension, CrashGroupDimensionQueryParser } from 'app/_models';
import { AlertService, CrashesService, CrashFiltersResponse, AuthenticationService } from 'app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { DateFormatter, IssueStateFormatter, PlatformFormatter } from 'app/_helpers';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { EditCrashGroupModal } from '../edit.crash.group.modal/edit.crash.group.modal';
import { Utils } from 'app/utils';

@Component({
    selector: 'crash-group',
    templateUrl: 'crash.group.component.html',
    styleUrls: [
        'crash.group.component.css'
    ]
})
export class CrashGroupComponent implements OnInit, OnDestroy {

    CrashSegmentsOrder = CrashSegmentsOrder
    CrashGroupDimension = CrashGroupDimension
    IssueState = IssueState

    @Input() application: Application

    workspace: Workspace
    crashGroup: CrashGroup
    callStack: CallStack
    crashFiltersResponse: CrashFiltersResponse
    crashTrendsResponse: CrashTrendsResponse
    crashTrendsSummaryResponse: CrashTrendsSummaryResponse
    stackTraceVisible = false
    didFinishInitialLoading = false

    crashReports: CrashReport[]
    crashApplicationSegments: CrashApplicationSegment[]

    selectedDateRange: DateRange
    crashGroupDimension: CrashGroupDimension

    @ViewChild('editCrashGroupModal') editCrashGroupModal: EditCrashGroupModal

    private queryParamsSubscription: Subscription
    private previousQueryParams: any = {}

    constructor(
        public dateFormatter: DateFormatter,
        public issueStateFormatter: IssueStateFormatter,
        public platformFormatter: PlatformFormatter,
        private crashesService: CrashesService,
        private route: ActivatedRoute,
        private router: Router,
        private alertService: AlertService,
        private authenticationService: AuthenticationService,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue
        this.refreshFilters()
        this.reloadCrashGroup()

        let query = this.route.snapshot.queryParams
        this.selectedDateRange = DateRange.fromParams(query) ?? DateRange.lastMonth()
        this.crashGroupDimension = CrashGroupDimensionQueryParser.fromString(query['dimension']) ?? CrashGroupDimension.CrashReports

        this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
            let changedParamNames = Utils.getChangedParamNames(params, this.previousQueryParams)
            this.previousQueryParams = { ...params }

            // If we don't have the filters response yet, no need to do anything as all the data will
            // get reloaded once the filters call finishes.
            if (!this.crashFiltersResponse) {
                return
            }

            this.updateSelect2SelectionFromQuery()

            // If only the dimension changed, it is enough to reload the segments
            if (changedParamNames.toString() == ['dimension'].toString()) {
                this.crashGroupDimension = CrashGroupDimensionQueryParser.fromString(params['dimension'])
                this.reloadCrashSegments()

                // If ordering changed, reload only the segments
            } else if (changedParamNames.toString() == ['orderBy'].toString()) {
                this.reloadCrashSegments()

                // If we reached this point, it means that either date range or the filters changed,
                // and then we need to reload everything.
            } else {
                this.selectedDateRange = DateRange.fromParams(params) ?? DateRange.lastMonth()
                this.refreshCrashTrends()
                this.reloadCrashSegments()
            }
        })
    }

    ngOnDestroy() {
        this.queryParamsSubscription?.unsubscribe()
    }

    private _didInitSelect2 = false
    ngAfterViewChecked() {
        if (!this.crashFiltersResponse) { return }

        if (!this._didInitSelect2 && $('.select2').length > 0) {
            this.initSelect2Components()
            this.updateSelect2SelectionFromQuery()
            this._didInitSelect2 = true
        }
    }

    refreshFilters() {
        if (!this.workspace) { return }

        this.crashFiltersResponse = null
        let filtersPromise = this.application ? this.crashesService.getApplicationCrashFilters(this.application.id) : this.crashesService.getWorkspaceCrashFilters(this.workspace.id)
        filtersPromise.then((response) => {
            this.crashFiltersResponse = response
            this.updateSelect2SelectionFromQuery()
            this.refreshCrashTrends()
            this.reloadCrashSegments()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    onDateRangeChanged(dateRange: DateRange) {
        this.router.navigate([], { queryParams: { startDate: moment(dateRange.startDate).toISOString(), endDate: moment(dateRange.endDate).toISOString() }, queryParamsHandling: 'merge' })
    }

    setCrashGroupDimension(crashDimension: CrashGroupDimension) {
        this.router.navigate([], { queryParams: { dimension: crashDimension }, queryParamsHandling: 'merge' })
    }

    onShowStackTraceClick() {
        this.stackTraceVisible = !this.stackTraceVisible
    }

    onAnnotateCrashGroupClick() {
        this.editCrashGroupModal.isClosingCrashGroup = false

        let quickJumpModal: any = $('#editCrashGroupModal')
        quickJumpModal.modal('show')
    }

    reloadCrashGroup() {
        if (!this.workspace) { return }

        let crashGroupSlug = this.route.snapshot.params['crashGroupSlug']

        if (this.application) {
            this.titleService.setTitle(`${this.application.name} | Crash Group #${crashGroupSlug}`)
        } else {
            this.titleService.setTitle(`${this.workspace.name} | Crash Group #${crashGroupSlug}`)
        }

        this.crashesService.getWorkspaceCrashGroupWithSlug(this.workspace.id, crashGroupSlug).then((response) => {
            this.crashGroup = response.data
            this.updateSelect2SelectionFromQuery()
            this.refreshCrashTrends()
            this.reloadCrashSegments()
            this.reloadCrashGroupCallStack()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    refreshCrashTrends() {
        if (!this.crashGroup || !this.crashFiltersResponse) { return }

        // Will also switch the trends chart into loading mode
        this.crashTrendsResponse = null
        this.crashTrendsSummaryResponse = null

        let crashFilter = this.makeCurrentCrashFilter()

        this.crashesService.getCrashTrends(this.workspace.id, crashFilter).then((response) => {
            this.crashTrendsResponse = response
            this.selectedDateRange = response.dateRange

            this.crashesService.getCrashTrendsSummary(this.workspace.id, crashFilter).then((response) => {
                this.crashTrendsSummaryResponse = response
            }).catch((error) => {
                this.alertService.handleError(error)
            })
        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    reloadCrashSegments() {
        if (!this.crashGroup || !this.crashFiltersResponse) { return }

        // Will also switch the trends chart into loading mode
        this.crashReports = null
        this.crashApplicationSegments = null

        let crashFilter = this.makeCurrentCrashFilter()
        let orderBy = crashSegmentsOrderFromQuery(this.route.snapshot.queryParams) ?? CrashSegmentsOrder.COUNT_DESC

        switch (this.crashGroupDimension) {
            case CrashGroupDimension.CrashReports:
                this.crashesService.getCrashGroupCrashReports(this.crashGroup.id, crashFilter).then((result) => {
                    this.crashReports = result.data
                    this.updateDidFinishInitialLoadingStatus()

                }).catch((error) => {
                    this.alertService.handleError(error)
                })
                break

            case CrashGroupDimension.Applications:
                this.crashesService.getWorkspaceCrashSegments(this.workspace.id, crashFilter, CrashDimension.Applications, orderBy).then((response) => {
                    this.crashApplicationSegments = response.data as any
                    this.updateDidFinishInitialLoadingStatus()
                }).catch((error) => {
                    this.alertService.handleError(error)
                })
                break
        }
    }

    onCrashGroupUpdated() {
        this.reloadCrashGroup()
    }

    updateIssueState(newState: IssueState) {
        if (this.crashGroup.state == newState) { return }

        if (newState != IssueState.Closed) {
            this.saveIssueState(newState, this.crashGroup.fixVersion)
            return
        }

        this.editCrashGroupModal.isClosingCrashGroup = true

        let quickJumpModal: any = $('#editCrashGroupModal')
        quickJumpModal.modal('show')
    }

    navigateToCrashReport(crashReport: CrashReport) {
        this.router.navigate(['crash-reports', crashReport.serialNumber], { relativeTo: this.route })
    }

    private reloadCrashGroupCallStack() {
        if (!this.crashGroup) { return }

        this.callStack = null
        this.crashesService.getCrashGroupLatestCallStack(this.crashGroup.id).then((result) => {
            this.callStack = result
        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private saveIssueState(newState: IssueState, fixVersion: string | null) {
        this.crashesService.updateCrashGroup(this.crashGroup.id, this.crashGroup.annotation, newState, fixVersion).then((response) => {
            this.crashGroup = response.data

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private updateDidFinishInitialLoadingStatus() {
        if (this.didFinishInitialLoading == true) { return }
        this.didFinishInitialLoading = (this.crashReports != null || this.crashApplicationSegments != null) && this.crashFiltersResponse != null
    }

    private makeCurrentCrashFilter(): CrashFilter {
        if (!this.workspace || !this.crashFiltersResponse) {
            return null
        }

        let query = this.route.snapshot.queryParams

        let crashFilter = new CrashFilter()
        crashFilter.crashGroupId = this.crashGroup.id
        crashFilter.startDate = moment(this.selectedDateRange.startDate).toDate()
        crashFilter.endDate = moment(this.selectedDateRange.endDate).toDate()

        // If we have an application, we need to filter by it, this is set only in the application errors page
        if (this.application) {
            crashFilter.applicationId = this.application.id
        }

        if (this.crashFiltersResponse) {
            // In case of workspace errors page, we need to apply the filter for the selected application.
            if (query['app']) {
                crashFilter.applicationId = query['app']
            }

            if (query['bundleIdentifier']) {
                crashFilter.bundleIdentifierId = this.crashFiltersResponse.bundleIdentifiers.find((bi) => { return bi.value == query['bundleIdentifier'] }).id
            }

            if (query['applicationVersion']) {
                crashFilter.applicationVersionId = this.crashFiltersResponse.applicationVersions.find((v) => { return v.bundleShortVersion == query['applicationVersion'] }).id
            }

            if (query['osVersion']) {
                crashFilter.osVersionId = this.crashFiltersResponse.osVersions.find((osVersion) => { return osVersion.version == query['osVersion'] }).id
            }
        }

        return crashFilter
    }

    private initSelect2Components() {
        $('#applicationSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '180px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#bundleIdentifierSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '240px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#applicationVersionSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '160px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#osVersionSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '140px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678

        // Fix for select2 being expanded after clicking on clear button
        $('.select2').on("select2:unselecting", (event) => {
            $(event.target).val(null).trigger('change')
            event.preventDefault()
        })

        // Applications
        $('#applicationSelect').on('select2:select', (event) => {
            let application = this.crashFiltersResponse.applications.find((o) => { return `${o.name} ${this.platformFormatter.platformName(o.platform)}` == event.params.data.id })
            this.router.navigate([], { queryParams: { app: application.id }, queryParamsHandling: 'merge' })
        })
        $('#applicationSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { app: null }, queryParamsHandling: 'merge' })
        })

        // Bundle identifiers
        $('#bundleIdentifierSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { bundleIdentifier: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#bundleIdentifierSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { bundleIdentifier: null }, queryParamsHandling: 'merge' })
        })

        // Application versions
        $('#applicationVersionSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { applicationVersion: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#applicationVersionSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { applicationVersion: null }, queryParamsHandling: 'merge' })
        })

        // Os versions
        $('#osVersionSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { osVersion: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#osVersionSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { osVersion: null }, queryParamsHandling: 'merge' })
        })
    }

    private updateSelect2SelectionFromQuery() {
        if (!this.workspace || !this.crashFiltersResponse) { return }

        let query = this.route.snapshot.queryParams

        $('#bundleIdentifierSelect').val(query['bundleIdentifier']).trigger('change')
        $('#applicationVersionSelect').val(query['applicationVersion']).trigger('change')
        $('#osVersionSelect').val(query['osVersion']).trigger('change')
        if (query['app']) {
            let application = this.crashFiltersResponse.applications.find((a) => { return a.id == query['app'] })
            if (application) { $('#applicationSelect').val(application.name + ' ' + this.platformFormatter.platformName(application.platform)).trigger('change') }
        } else {
            $('#applicationSelect').val(null).trigger('change')
        }
    }

}
