import { Component, OnInit } from '@angular/core';
import { Workspace } from 'app/_models';
import { AuthenticationService } from 'app/_services';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
    templateUrl: 'workspace.error.group.component.html',
    styleUrls: [
        'workspace.error.group.component.css'
    ]
})
export class WorkspaceErrorGroupComponent implements OnInit {

    workspace: Workspace

    constructor(
        public route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue
        this.titleService.setTitle(`Error Group #${this.route.snapshot.params['errorGroupSlug']}`)
    }

}
