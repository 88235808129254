import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'app/_services'
import { Workspace } from 'app/_models';

@Component({
    templateUrl: 'workspace.crash.reports.list.component.html',
})
export class WorkspaceCrashReportsListComponent implements OnInit {

    workspace: Workspace

    constructor(
        private authenticationService: AuthenticationService,
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue
    }

}
