<div class="navbar desktop-only navbar-expand navbar-light d-flex justify-content-between">
    <navbar-app-feature appFeatureName="Crashes"></navbar-app-feature>
    <navbar-user-menu></navbar-user-menu>
</div>

<mobile-warning class="mobile-only"></mobile-warning>

<div class="page-content desktop-only">

    <div class="content-wrapper" id="pageContent">

        <div class="content">
            <div class="row">
                <div class="col-md-12">
                    <alert></alert>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <crashes-overview></crashes-overview>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<quick-jump-modal></quick-jump-modal>
