import { animate, keyframes, style, transition } from "@angular/animations";

export class Animations {

    public static fadeIn = [
        transition(
            ':enter', [
                style({ opacity: 0 }),
                animate("0.2s ease-in-out", keyframes([
                    style({ opacity: 1, offset: 1 })
                ]))
            ]
        )
    ]

    public static fadeInTransition = [
        transition('false => true', [
            style({ opacity: 0 }),
            animate("0.25s ease-in-out", keyframes([
                style({ opacity: 1, offset: 1 })
            ]))
        ])
    ]

    public static fadeInOut = [
        transition(
            ':enter', [
            style({ opacity: 0 }),
            animate("0.25s {{delay}}s ease-in-out", keyframes([
                style({ opacity: 1, offset: 1 })
            ]))
        ], { params: { delay: 0 } }
        ),
        transition(
            ':leave', [
            style({ opacity: 1 }),
            animate("0.25s {{delay}}s ease-in-out", style({ opacity: 0 }))
        ], { params: { delay: 0 } }
        )
    ]

    public static bumpInOut = [
        transition('false => true', [
            style({ opacity: 0, transform: 'scale(1.02)' }),
            animate("0.3s ease-in-out", keyframes([
                style({ opacity: 0.8, transform: 'scale(0.99)', offset: 0.25 }),
                style({ opacity: 1, transform: 'scale(1)', offset: 1 })
            ]))
        ])
    ]

}
