import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationFormatter, DateFormatter, PlatformFormatter } from 'app/_helpers';
import { AppLaunchDimension, AppLaunchFilter, AppLaunchSegment, AppLaunchTrendType, AppLaunchTrendsResponse, Application, DateRange } from 'app/_models';
import { AlertService, AppLaunchFiltersResponse, AppLaunchMetricsService, ApplicationService, CollectionResponse } from 'app/_services';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: 'app.launch.component.html',
    styleUrls: [
        'app.launch.component.css'
    ]
})
export class AppLaunchComponent implements OnInit, OnDestroy, AfterViewChecked {

    AppLaunchDimension = AppLaunchDimension

    application: Application

    selectedDateRange: DateRange
    appLaunchTrendType: AppLaunchTrendType
    appLaunchDimension: AppLaunchDimension

    appLaunchFiltersResponse: AppLaunchFiltersResponse
    appLaunchTrendsResponse: AppLaunchTrendsResponse
    appLaunchSegments: AppLaunchSegment[]

    private queryParamsSubscription: Subscription
    private currentApplicationSubscription: Subscription

    constructor(
        public dateFormatter: DateFormatter,
        public platformFormatter: PlatformFormatter,
        private route: ActivatedRoute,
        private router: Router,
        private applicationService: ApplicationService,
        private alertService: AlertService,
        private applicationFormatter: ApplicationFormatter,
        private appLaunchMetricsService: AppLaunchMetricsService,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        let query = this.route.snapshot.queryParams
        this.selectedDateRange = DateRange.fromParams(query) ?? DateRange.lastMonth()
        this.appLaunchTrendType = query['trendType'] ?? AppLaunchTrendType.Appdex
        this.appLaunchDimension = query['dimension'] ?? AppLaunchDimension.ApplicationVersion
        this.updateSelect2SelectionFromQuery()

        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
            if (this.application != null) {
                this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | App Launch`)
                this.refreshFilters()
            }
        })

        this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
            this.updateSelect2SelectionFromQuery()

            // If trend type changed, it means this is the only change
            let newAppLaunchTrendType = params['trendType'] ?? AppLaunchTrendType.Appdex
            if (this.appLaunchTrendType != newAppLaunchTrendType) {
                this.appLaunchTrendType = newAppLaunchTrendType
                this.refreshAppLaunchTrendCharts()
                return
            }

            // If dimension changed, it means this is the only change
            let newAppLaunchDimension = params['dimension'] ?? AppLaunchDimension.ApplicationVersion
            if (this.appLaunchDimension != newAppLaunchDimension) {
                this.appLaunchDimension = newAppLaunchDimension
                this.refreshAppLaunchDimension()
                return
            }

            // In case time filter changed, or any other filter, refresh everything
            this.selectedDateRange = DateRange.fromParams(params) ?? DateRange.lastMonth()
            if (this.appLaunchFiltersResponse) {
                this.refreshAppLaunchTrendCharts()
                this.refreshAppLaunchDimension()
            }
        })
    }

    ngOnDestroy() {
        this.currentApplicationSubscription?.unsubscribe()
        this.queryParamsSubscription?.unsubscribe()
    }

    private _didInitSelect2 = false
    ngAfterViewChecked() {
        if (!this.appLaunchFiltersResponse) { return }

        if (!this._didInitSelect2 && $('.select2').length > 0) {
            this.initSelect2Components()
            this.updateSelect2SelectionFromQuery()
            this._didInitSelect2 = true
        }
    }

    refreshFilters() {
        this.appLaunchMetricsService.getAppLaunchFilters(this.application.id).then((response) => {
            this.appLaunchFiltersResponse = response
            this.refreshAppLaunchTrendCharts()
            this.refreshAppLaunchDimension()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    refreshAppLaunchTrendCharts() {
        if (!this.application) { return }

        // Will also switch the trends chart into loading mode
        this.appLaunchTrendsResponse = null

        let appLaunchFilter = this.makeCurrentAppLaunchFilter()
        this.appLaunchMetricsService.getAppLaunchTrends(this.application.id, this.appLaunchTrendType, appLaunchFilter).then((response) => {
            this.appLaunchTrendsResponse = response
            this.selectedDateRange = response.dateRange

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    refreshAppLaunchDimension() {
        if (!this.application) { return }

        let appLaunchFilter = this.makeCurrentAppLaunchFilter()
        this.appLaunchMetricsService.getAppLaunchInfoByDimension(this.application.id, appLaunchFilter, this.appLaunchDimension).then((response) => {
            this.appLaunchSegments = (response as CollectionResponse<AppLaunchSegment>).data

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    onDateRangeChanged(dateRange: DateRange) {
        this.router.navigate([], { queryParams: { startDate: moment(dateRange.startDate).toISOString(), endDate: moment(dateRange.endDate).toISOString() }, queryParamsHandling: 'merge' })
    }

    setAppLaunchDimension(appLaunchDimension: AppLaunchDimension) {
        this.appLaunchSegments = null
        this.router.navigate([], { queryParams: { dimension: appLaunchDimension }, queryParamsHandling: 'merge' })
    }

    onAppLaunchTrendTypeChanged(appLaunchTrendType: AppLaunchTrendType) {
        this.router.navigate([], { queryParams: { trendType: appLaunchTrendType }, queryParamsHandling: 'merge' })
    }

    private makeCurrentAppLaunchFilter(): AppLaunchFilter {
        let query = this.route.snapshot.queryParams

        let appLaunchFilter = new AppLaunchFilter()
        appLaunchFilter.applicationId = this.application.id
        appLaunchFilter.startDate = moment(this.selectedDateRange.startDate).toDate()
        appLaunchFilter.endDate = moment(this.selectedDateRange.endDate).toDate()

        if (this.appLaunchFiltersResponse) {
            if (query['applicationVersion']) {
                appLaunchFilter.applicationVersionId = this.appLaunchFiltersResponse.applicationVersions.find((v) => { return v.bundleShortVersion == query['applicationVersion'] }).id
            }

            if (query['device']) {
                appLaunchFilter.deviceId = this.appLaunchFiltersResponse.deviceModels.find((deviceModel) => { return deviceModel.name == query['device'] }).id
            }

            if (query['osVersion']) {
                appLaunchFilter.osVersionId = this.appLaunchFiltersResponse.osVersions.find((osVersion) => { return osVersion.version == query['osVersion'] }).id
            }
        }

        return appLaunchFilter
    }

    private initSelect2Components() {
        $('#applicationVersionSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '160px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#osVersionSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '140px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678
        $('#deviceSelect').select2({ minimumResultsForSearch: 5, dropdownAutoWidth: true, width: '220px' }).select2('open').select2('close') // Safari workaround https://github.com/select2/select2/issues/4678

        // Fix for select2 being expanded after clicking on clear button
        $('.select2').on("select2:unselecting", (event) => {
            $(event.target).val(null).trigger('change')
            event.preventDefault()
        })

        $('#applicationVersionSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { applicationVersion: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#applicationVersionSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { applicationVersion: null }, queryParamsHandling: 'merge' })
        })

        $('#osVersionSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { osVersion: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#osVersionSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { osVersion: null }, queryParamsHandling: 'merge' })
        })

        $('#deviceSelect').on('select2:select', (event) => {
            this.router.navigate([], { queryParams: { device: event.params.data.id }, queryParamsHandling: 'merge' })
        })
        $('#deviceSelect').on('select2:clear', (event) => {
            this.router.navigate([], { queryParams: { device: null }, queryParamsHandling: 'merge' })
        })
    }

    private updateSelect2SelectionFromQuery() {
        if (!this.application || !this.appLaunchFiltersResponse) { return }

        let query = this.route.snapshot.queryParams

        $('#applicationVersionSelect').val(query['applicationVersion']).trigger('change')
        $('#osVersionSelect').val(query['osVersion']).trigger('change')
        $('#deviceSelect').val(query['device']).trigger('change')
    }

}
